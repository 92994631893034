import { ToolTip } from '@/components/Elements/';
import { useEllipsis } from '@/hooks/useEllipsis';

type TextEllipsisProps = {
  text: string;
};

export const TextEllipsis = ({ text }: TextEllipsisProps) => {
  const [enableEllipsis, ellipsis] = useEllipsis(text);

  // エリプシス表示の有無でツールチップを表示する
  return (
    <>
      {text && (
        <div data-testid="ellipsis">
          {ellipsis ? (
            <ToolTip text={text}>
              <div
                ref={enableEllipsis}
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {text}
              </div>
            </ToolTip>
          ) : (
            <div
              ref={enableEllipsis}
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {text}
            </div>
          )}
        </div>
      )}
    </>
  );
};
