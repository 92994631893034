import './Button.css';

import { Image } from '@/components/Elements';

export type ButtonProps = {
  type?: 'button' | 'submit';
  label: string;
  icon_left?: string;
  icon_right?: string;
  disabled?: boolean;
  class_name?: string;
  onClick?: () => void;
};

export const Button = ({
  type = 'button',
  label,
  icon_left,
  icon_right,
  disabled = false,
  class_name = 'common-button',
  onClick,
}: ButtonProps) => {
  return (
    <button className={class_name} type={type} disabled={disabled} onClick={onClick}>
      <div className="button-contents">
        <div className="button-icon-left">{icon_left && <Image src={icon_left} />}</div>
        <div className="button-label">{label}</div>
        <div className="button-icon-right">{icon_right && <Image src={icon_right} />}</div>
      </div>
    </button>
  );
};
