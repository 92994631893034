import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot } from 'firebase/firestore';

import {
  SettingData,
  DstLangData,
  UploadData,
  PrevData,
  TranData,
  ProductData,
  PlanData,
  PlansLocalizationData,
  UserData,
  ContractData,
  ChargeData,
} from './entity';

/**
 * 翻訳設定（settings）コンバーター
 */
export const settingsConverter: FirestoreDataConverter<SettingData> = {
  toFirestore(doc): DocumentData {
    return {
      user_id: doc.user_id,
      src_lang: doc.src_lang,
      dst_subtitle: doc.dst_subtitle,
      dst_dub: doc.dst_dub,
      skip_preview: doc.skip_preview,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<SettingData>, options): SettingData {
    const data = snapshot.data(options);
    const entity: SettingData = {
      user_id: data.user_id,
      src_lang: data.src_lang,
      dst_subtitle: data.dst_subtitle,
      dst_dub: data.dst_dub,
      skip_preview: data.skip_preview,
    };
    return entity;
  },
};

/**
 * 翻訳先言語（dst_langs）コンバーター
 */
export const dstLangsConverter: FirestoreDataConverter<DstLangData> = {
  toFirestore(doc): DocumentData {
    return {
      dst_lang: doc.dst_lang,
      dub_voice_type: doc.dub_voice_type,
      dub_voice_name: doc.dub_voice_name,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<DstLangData>, options): DstLangData {
    const data = snapshot.data(options);
    const entity: DstLangData = {
      dst_lang: data.dst_lang,
      dub_voice_type: data.dub_voice_type,
      dub_voice_name: data.dub_voice_name,
    };
    return entity;
  },
};

/**
 * アップロードファイル（uploads）コンバーター
 */
export const uploadsConverter: FirestoreDataConverter<UploadData> = {
  toFirestore(doc): DocumentData {
    return {
      user_id: doc.user_id,
      setting_id: doc.setting_id,
      upload_status: doc.upload_status,
      upload_error: doc.upload_error,
      upload_progress: doc.upload_progress,
      upload_date: doc.upload_date,
      upload_remove_date: doc.upload_remove_date,
      upload_name: doc.upload_name,
      upload_video_path: doc.upload_video_path,
      upload_video_type: doc.upload_video_type,
      upload_video_size: doc.upload_video_size,
      upload_video_time: doc.upload_video_time,
      upload_video_height: doc.upload_video_height,
      upload_video_width: doc.upload_video_width,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<UploadData>, options): UploadData {
    const data = snapshot.data(options);
    const entity: UploadData = {
      user_id: data.user_id,
      setting_id: data.setting_id,
      upload_status: data.upload_status,
      upload_error: data.upload_error,
      upload_progress: data.upload_progress,
      upload_date: data.upload_date,
      upload_remove_date: data.upload_remove_date,
      upload_name: data.upload_name,
      upload_video_path: data.upload_video_path,
      upload_video_type: data.upload_video_type,
      upload_video_size: data.upload_video_size,
      upload_video_time: data.upload_video_time,
      upload_video_height: data.upload_video_height,
      upload_video_width: data.upload_video_width,
    };
    return entity;
  },
};

/**
 * プレビュー翻訳動画（prevs）コンバーター
 */
export const prevsConverter: FirestoreDataConverter<PrevData> = {
  toFirestore(doc): DocumentData {
    return {
      dst_lang: doc.dst_lang,
      user_id: doc.user_id,
      setting_id: doc.setting_id,
      upload_id: doc.upload_id,
      prev_status: doc.prev_status,
      prev_error: doc.prev_error,
      prev_progress: doc.prev_progress,
      prev_date: doc.prev_date,
      prev_remove_date: doc.prev_remove_date,
      prev_limit_date: doc.prev_limit_date,
      prev_name: doc.prev_name,
      prev_video_path: doc.prev_video_path,
      prev_zip_path: doc.prev_zip_path,
      prev_video_type: doc.prev_video_type,
      prev_video_size: doc.prev_video_size,
      prev_video_time: doc.prev_video_time,
      prev_video_height: doc.prev_video_height,
      prev_video_width: doc.prev_video_width,
      prev_ttt_path: doc.prev_ttt_path,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<PrevData>, options): PrevData {
    const data = snapshot.data(options);
    const entity: PrevData = {
      dst_lang: data.dst_lang,
      user_id: data.user_id,
      setting_id: data.setting_id,
      upload_id: data.upload_id,
      prev_status: data.prev_status,
      prev_error: data.prev_error,
      prev_progress: data.prev_progress,
      prev_date: data.prev_date,
      prev_remove_date: data.prev_remove_date,
      prev_limit_date: data.prev_limit_date,
      prev_name: data.prev_name,
      prev_video_path: data.prev_video_path,
      prev_zip_path: data.prev_zip_path,
      prev_video_type: data.prev_video_type,
      prev_video_size: data.prev_video_size,
      prev_video_time: data.prev_video_time,
      prev_video_height: data.prev_video_height,
      prev_video_width: data.prev_video_width,
      prev_ttt_path: data.prev_ttt_path,
    };
    return entity;
  },
};

/**
 * 翻訳動画（trans）コンバーター
 */
export const transConverter: FirestoreDataConverter<TranData> = {
  toFirestore(doc): DocumentData {
    return {
      dst_lang: doc.dst_lang,
      user_id: doc.user_id,
      setting_id: doc.setting_id,
      upload_id: doc.upload_id,
      tran_status: doc.tran_status,
      tran_error: doc.tran_error,
      tran_progress: doc.tran_progress,
      tran_recept_date: doc.tran_recept_date,
      tran_date: doc.tran_date,
      tran_remove_date: doc.tran_remove_date,
      tran_limit_date: doc.tran_limit_date,
      tran_name: doc.tran_name,
      tran_video_path: doc.tran_video_path,
      tran_zip_path: doc.tran_zip_path,
      tran_video_type: doc.tran_video_type,
      tran_video_size: doc.tran_video_size,
      tran_video_time: doc.tran_video_time,
      tran_video_height: doc.tran_video_height,
      tran_video_width: doc.tran_video_width,
      tran_ttt_path: doc.tran_ttt_path,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<TranData>, options): TranData {
    const data = snapshot.data(options);
    const entity: TranData = {
      dst_lang: data.dst_lang,
      user_id: data.user_id,
      setting_id: data.setting_id,
      upload_id: data.upload_id,
      tran_status: data.tran_status,
      tran_error: data.tran_error,
      tran_progress: data.tran_progress,
      tran_recept_date: data.tran_recept_date,
      tran_date: data.tran_date,
      tran_remove_date: data.tran_remove_date,
      tran_limit_date: data.tran_limit_date,
      tran_name: data.tran_name,
      tran_video_path: data.tran_video_path,
      tran_zip_path: data.tran_zip_path,
      tran_video_type: data.tran_video_type,
      tran_video_size: data.tran_video_size,
      tran_video_time: data.tran_video_time,
      tran_video_height: data.tran_video_height,
      tran_video_width: data.tran_video_width,
      tran_ttt_path: data.tran_ttt_path,
    };
    return entity;
  },
};

/**
 * 製品（products）コンバーター
 */
export const productsConverter: FirestoreDataConverter<ProductData> = {
  toFirestore(doc): DocumentData {
    return {
      product_code: doc.product_code,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<ProductData>, options): ProductData {
    const data = snapshot.data(options);
    const entity: ProductData = {
      product_code: data.product_code,
    };
    return entity;
  },
};

/**
 * プラン（plans）コンバーター
 */
export const plansConverter: FirestoreDataConverter<PlanData> = {
  toFirestore(doc): DocumentData {
    return {
      plan_code: doc.plan_code,
      plan_serial_head: doc.plan_serial_head,
      plan_appli_start: doc.plan_appli_start,
      plan_appli_end: doc.plan_appli_end,
      result_retention_days: doc.result_retention_days,
      ticket_expire_days: doc.ticket_expire_days,
      upload_limit_files: doc.upload_limit_files,
      upload_limit_size: doc.upload_limit_size,
      upload_limit_duration: doc.upload_limit_duration,
      upload_limit_height: doc.upload_limit_height,
      upload_limit_width: doc.upload_limit_width,
      upload_limit_type: doc.upload_limit_type,
      subscription_type: doc.subscription_type,
      auto_charge_ticket: doc.auto_charge_ticket,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<PlanData>, options): PlanData {
    const data = snapshot.data(options);
    const entity: PlanData = {
      plan_code: data.plan_code,
      plan_serial_head: data.plan_serial_head,
      plan_appli_start: data.plan_appli_start,
      plan_appli_end: data.plan_appli_end,
      result_retention_days: data.result_retention_days,
      ticket_expire_days: data.ticket_expire_days,
      upload_limit_files: data.upload_limit_files,
      upload_limit_size: data.upload_limit_size,
      upload_limit_duration: data.upload_limit_duration,
      upload_limit_height: data.upload_limit_height,
      upload_limit_width: data.upload_limit_width,
      upload_limit_type: data.upload_limit_type,
      subscription_type: data.subscription_type,
      auto_charge_ticket: data.auto_charge_ticket,
    };
    return entity;
  },
};

/**
 * プラン多言語設定（localization）コンバーター
 */
export const plansLocalizationConverter: FirestoreDataConverter<PlansLocalizationData> = {
  toFirestore(doc): DocumentData {
    return {
      lang: doc.lang,
      plan_name: doc.plan_name,
      subscription_amount: doc.subscription_amount,
      subscription_currency: doc.subscription_currency,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<PlansLocalizationData>, options): PlansLocalizationData {
    const data = snapshot.data(options);
    const entity: PlansLocalizationData = {
      lang: data.lang,
      plan_name: data.plan_name,
      subscription_amount: data.subscription_amount,
      subscription_currency: data.subscription_currency,
    };
    return entity;
  },
};

/**
 * ユーザー（users）コンバーター
 */
export const usersConverter: FirestoreDataConverter<UserData> = {
  toFirestore(doc): DocumentData {
    return {
      user_id: doc.user_id,
      default_lang: doc.default_lang,
      select_lang: doc.select_lang,
      active_setting_id: doc.active_setting_id,
      eula_version: doc.eula_version,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<UserData>, options): UserData {
    const data = snapshot.data(options);
    const entity: UserData = {
      user_id: data.user_id,
      default_lang: data.default_lang,
      select_lang: data.select_lang,
      active_setting_id: data.active_setting_id,
      eula_version: data.eula_version,
    };
    return entity;
  },
};

/**
 * 契約プラン（contracts）コンバーター
 */
export const contractsConverter: FirestoreDataConverter<ContractData> = {
  toFirestore(doc): DocumentData {
    return {
      contract_serial: doc.contract_serial,
      contract_merch_code: doc.contract_merch_code,
      contract_start_date: doc.contract_start_date,
      contract_end_date: doc.contract_end_date,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<ContractData>, options): ContractData {
    const data = snapshot.data(options);
    const entity: ContractData = {
      contract_serial: data.contract_serial,
      contract_merch_code: data.contract_merch_code,
      contract_start_date: data.contract_start_date,
      contract_end_date: data.contract_end_date,
    };
    return entity;
  },
};

/**
 * チャージ（charges）コンバーター
 */
export const chargesConverter: FirestoreDataConverter<ChargeData> = {
  toFirestore(doc): DocumentData {
    return {
      charge_ticket_code: doc.charge_ticket_code,
      charge_issue_date: doc.charge_issue_date,
      charge_appli_start: doc.charge_appli_start,
      charge_appli_end: doc.charge_appli_end,
      charge_buy: doc.charge_buy,
      charge_remain: doc.charge_remain,
      charge_type: doc.charge_type,
    };
  },

  fromFirestore(snapshot: QueryDocumentSnapshot<ChargeData>, options): ChargeData {
    const data = snapshot.data(options);
    const entity: ChargeData = {
      charge_ticket_code: data.charge_ticket_code,
      charge_issue_date: data.charge_issue_date,
      charge_appli_start: data.charge_appli_start,
      charge_appli_end: data.charge_appli_end,
      charge_buy: data.charge_buy,
      charge_remain: data.charge_remain,
      charge_type: data.charge_type,
    };
    return entity;
  },
};
