import { signInWithCustomToken, setPersistence, browserSessionPersistence } from 'firebase/auth';
import { useCallback, useState, useLayoutEffect, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { COMMON_DIALOG_TYPES } from '@/components/Form';
import { PAGE_ROUTE_PATH, EULA_HTML, URL_PARAMS_VALUE, AGREEMENT_URL, URL_PARAMS_KEY } from '@/constants';
import { useAuth } from '@/features/auth';
import { useLogout } from '@/features/logout';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useUrlParameter } from '@/hooks/useUrlParameter';
import {
  selectUser,
  insertUser,
  updateUser,
  insertContract,
  selectUploads,
  deleteUpload,
  selectSettingsByUserId,
  selectTrans,
  Users,
  Contracts,
  UserData,
  UploadData,
} from '@/services';
import { auth } from '@/services/firebase';
import { removeBeforeunloadEvent } from '@/utils/beforeunload';
import { PLAN_CODE, UPLOAD_STATUS } from '@/utils/constants';

// アクティブな翻訳設定ID
let settingId = '';
// ルーティング遷移先
let nextPage: string = PAGE_ROUTE_PATH.SELECTFILES;
// microDataから取得したEULAバージョン
let eulaMicroData: string | null = '';

// Settings画面で使用するダイアログリスト
export const EULA_DIALOG_TYPES = {
  DIALOG_ALERT_UNKNOWN: '1', // 不明なエラーアラート
  DIALOG_CONFIRM_NORMAL_AGREE: '2', // 利用規約同意確認ダイアログ
};
export type EULA_DIALOG_TYPES = (typeof EULA_DIALOG_TYPES)[keyof typeof EULA_DIALOG_TYPES];

// 共通ダイアロググローバルprops
const globalCommonDialogProps = {
  title: '',
  message: '',
  buttonLabel: '',
  dialogType: COMMON_DIALOG_TYPES.DIALOG_ALERT,
  eulaDialogType: EULA_DIALOG_TYPES.DIALOG_ALERT_UNKNOWN,
};

/**
 * 利用規約ダイアログ hooks
 *
 * @returns
 */
export const useEulaDialog = () => {
  const { t } = useTranslation();
  const { language, setLanguage } = useBrowserUserSetting();
  const navigate = useNavigate();
  // ログアウト
  const { doLogout } = useLogout({
    onSuccessFun: removeBeforeunloadEvent, // beforeunloadイベントによるwindows.alert削除
  });

  const { userId, firebaseToken } = useAuth();
  // EULAダイアログ（本体）開閉制御state
  const [eulaDialogOpen, setEulaDialogOpen] = useState(false);
  // 利用契約変更確認ダイアログ開閉制御state
  const [eulaChangeDialogOpen, setEulaChangeDialogOpen] = useState(false);
  // 共通ダイアログ開閉制御state
  const [commonDialogOpen, setCommonDialogOpen] = useState(false);
  const { findParams } = useUrlParameter();
  const lang = findParams(URL_PARAMS_KEY.lang);
  useEffect(() => {
    setLanguage(lang);
  }, [setLanguage, lang]);

  let EULA_HTML_BY_LANG: string;
  // 画面の表示言語が日本語以外の場合、EULAの参照先設定
  if (lang != 'ja') {
    EULA_HTML_BY_LANG = `${AGREEMENT_URL}/en-us` as string;
  } else {
    EULA_HTML_BY_LANG = EULA_HTML;
  }

  // 共通ダイアログオープン処理
  const openCommonDialog = (
    commonDialogTitle: string,
    commonDialogMessage: string,
    commonDialogButtonLabel: string,
    commonDialogType: COMMON_DIALOG_TYPES,
    eulaDialogType: EULA_DIALOG_TYPES
  ) => {
    // グローバル変数に各項目をセット
    globalCommonDialogProps.title = commonDialogTitle;

    globalCommonDialogProps.message = commonDialogMessage;

    globalCommonDialogProps.buttonLabel = commonDialogButtonLabel;

    globalCommonDialogProps.dialogType = commonDialogType;
    globalCommonDialogProps.eulaDialogType = eulaDialogType;
    // オープン
    setCommonDialogOpen(true);
    return true;
  };

  // 共通ダイアログクローズ時
  const handleCommonDialogClose = useCallback(
    async (isOk?: boolean) => {
      // ダイアログを閉じる
      setCommonDialogOpen(false);

      // OK押下時の処理
      if (isOk) {
        switch (globalCommonDialogProps.eulaDialogType) {
          // 不明なエラーアラートの場合
          case EULA_DIALOG_TYPES.DIALOG_ALERT_UNKNOWN:
            // ログイン情報を破棄し、ログイン画面に遷移する
            // ログアウトAPI呼び出し
            doLogout(URL_PARAMS_VALUE.LOGOUT_MODE.NORMAL);

            break;
          // 利用規約同意確認ダイアログの場合
          case EULA_DIALOG_TYPES.DIALOG_CONFIRM_NORMAL_AGREE: {
            try {
              const user = await selectUser(userId);
              if (user.exists()) {
                const userData: UserData = user.data();
                // ユーザー（users）ドキュメントを更新
                const fieldUsers: Users = {
                  eula_version: eulaMicroData,
                };
                await updateUser(userId, fieldUsers);
                // usersコレクションの選択言語を取得
                // ルーティングでの画面遷移処理を行う
                navigate(`${nextPage}?lang=${userData.select_lang}`, {
                  state: {
                    history: '/',
                  },
                });
              } else {
                // usersデータが存在しない場合
                openCommonDialog(
                  t('eula.alertUnKnownMessage'),
                  t('eula.alertUnKnownDetail'),
                  '',
                  COMMON_DIALOG_TYPES.DIALOG_ALERT,
                  EULA_DIALOG_TYPES.DIALOG_ALERT_UNKNOWN
                );
              }
            } catch (error) {
              // エラーの場合
              openCommonDialog(
                t('eula.alertUnKnownMessage'),
                t('eula.alertUnKnownDetail'),
                '',
                COMMON_DIALOG_TYPES.DIALOG_ALERT,
                EULA_DIALOG_TYPES.DIALOG_ALERT_UNKNOWN
              );
            }

            break;
          }
        }
      }
    },
    [setCommonDialogOpen, doLogout, t, navigate, userId]
  );

  // 同意するボタン押下時処理
  const clickAgreeButton = () => {
    // 利用規約同意確認ダイアログを表示
    openCommonDialog(
      t('eula.confirmNormalAgreeMessage'),
      t('eula.confirmNormalAgreeDetail'),
      t('eula.agree'),
      COMMON_DIALOG_TYPES.DIALOG_CONFIRM_NORMAL,
      EULA_DIALOG_TYPES.DIALOG_CONFIRM_NORMAL_AGREE
    );
  };

  // 同意しない・キャンセルボタン押下時処理
  const clickDisagreeButton = () => {
    // ログアウト処理
    doLogout(URL_PARAMS_VALUE.LOGOUT_MODE.NORMAL);
  };

  // 利用契約を表示ボタン押下時処理
  const clickDisplayButton = () => {
    setEulaChangeDialogOpen(false);
    setEulaDialogOpen(true);
  };

  // 初期表示処理
  const InitialDispEula = async () => {
    // 外部HTMLの取得
    await fetch(EULA_HTML)
      .then((res) => res.text())
      .then(async (text) => {
        const convertText = new DOMParser().parseFromString(text, 'text/html');
        // ページからtimeタグを取得
        const timeArr = [];
        const pageTime = convertText?.getElementsByTagName('time');
        for (let i = 0; i < pageTime.length; i++) {
          const dateTime = pageTime[i].getAttribute('datetime');
          if (dateTime) {
            timeArr.push(dateTime);
          }
        }

        const timeArrSort = timeArr.sort().reverse();
        eulaMicroData = timeArrSort[0];
        try {
          let user = await selectUser(userId);
          if (user.exists()) {
            const userData: UserData = user.data();
            // ユーザーが存在した場合
            settingId = userData.active_setting_id;
            // ルーティング制御 ここから
            // 翻訳動画情報(trans)の有無を判定
            await getDisplayTrans();
            if (settingId) {
              // アクティブな翻訳設定IDが存在した場合、アップロード待ち/アップロード中のファイルがあるかどうかチェックする
              let uploads = await selectUploads(settingId, userId);
              if (uploads.docs.length > 0) {
                // uploads以下の表示対象があった場合
                const uploadsDeleteList: string[] = [];
                await Promise.all(
                  uploads.docs.map(async (upload) => {
                    const uploadData: UploadData = upload.data();
                    if (
                      uploadData.upload_status === UPLOAD_STATUS.UPLOADING ||
                      uploadData.upload_status === UPLOAD_STATUS.UPLOAD_PENDING
                    ) {
                      uploadsDeleteList.push(upload.id);
                    }
                  })
                );
                // ステータスが「アップロード中」のデータを全削除
                if (uploadsDeleteList.length > 0) {
                  await Promise.all(
                    uploadsDeleteList.map(async (uploadsId: string) => {
                      await deleteUpload(settingId, uploadsId, userId);
                    })
                  );
                } else {
                  // 削除対象が無い場合、「ルーティング遷移先」を「Upload」に上書き
                  nextPage = PAGE_ROUTE_PATH.UPLOAD;
                }
              }
              // 削除後のuploadsデータを再取得
              uploads = await selectUploads(settingId, userId);
              if (uploads.docs.length > 0) {
                // uploadsの表示対象があった場合、「ルーティング遷移先」を「Upload」に上書き
                nextPage = PAGE_ROUTE_PATH.UPLOAD;
              } else {
                // アクティブな翻訳設定IDを破棄
                const fieldUsers: Users = {
                  active_setting_id: '',
                };
                await updateUser(userId, fieldUsers);
              }
            }
          } else {
            // ユーザーが存在しない場合、Firestoreにユーザー登録
            const fieldUsers: Users = {
              user_id: userId,
              active_setting_id: '',
              eula_version: '',
              select_lang: 'ja', // TODO issue#168の対応で、デフォルトで日本語を設定
            };
            await insertUser(userId, fieldUsers);

            // contractsサブコレクションを登録（初回登録時はショットユーザーで登録）
            const today = new Date();
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            // ショットユーザーの場合は、契約終了日を「昨日」に設定
            const fieldContracts: Contracts = {
              contract_serial: '',
              contract_merch_code: PLAN_CODE.SHOT_PLAN,
              contract_start_date: today,
              contract_end_date: yesterday,
            };
            await insertContract(userId, PLAN_CODE.SHOT_PLAN, fieldContracts);
            // 登録を反映するため、再度取得
            user = await selectUser(userId);
          }
          // EULAバージョンの取得（ユーザー登録後なので、あるはず...）
          const userData: UserData | undefined = user.data();
          const eulaVersion = userData ? userData.eula_version : '';

          // iframeとの比較
          if (!eulaVersion) {
            // バージョンがない場合はEULA表示
            setEulaDialogOpen(true);
          } else if (eulaVersion !== eulaMicroData) {
            // バージョンが古い場合は利用契約変更確認ダイアログを表示
            setEulaChangeDialogOpen(true);
          } else {
            // 最新バージョンの時はルーティング制御で画面遷移
            const saveLang = userData ? userData.select_lang : language;
            navigate(`${nextPage}?lang=${saveLang}`, {
              state: {
                history: '/',
              },
            });
          }
        } catch (error) {
          // エラーの場合
          openCommonDialog(
            t('eula.alertUnKnownMessage'),
            t('eula.alertUnKnownDetail'),
            '',
            COMMON_DIALOG_TYPES.DIALOG_ALERT,
            EULA_DIALOG_TYPES.DIALOG_ALERT_UNKNOWN
          );
        }
      })
      .catch((_) => {
        // なかった場合storageデータの取得（設計書記載）
        // TODO：現在はstorageデータのみ参照の為、コメントアウト＆エラーアラート表示中
        // エラーの場合
        openCommonDialog(
          t('eula.alertUnKnownMessage'),
          t('eula.alertUnKnownDetail'),
          '',
          COMMON_DIALOG_TYPES.DIALOG_ALERT,
          EULA_DIALOG_TYPES.DIALOG_ALERT_UNKNOWN
        );

        // const dummyMicroData = `html/iframeDoc.html`;
        // const storageRef = ref(storage, dummyMicroData);
        // getDownloadURL(storageRef).then(async (url) => {
        //   await fetch(url)
        //     .then((res) => res.text())
        //     .then((text) => {
        //       const convertText = new DOMParser().parseFromString(text, 'text/html');
        //       // ページからp要素を取得
        //       const pElem = convertText?.getElementsByTagName('p')[0];
        //       // p要素のHTMLを変数にセット
        //       eulaMicroData = pElem?.getElementsByTagName('time')[0].dateTime;
        //     });
        // });
      });
  };
  // 翻訳動画情報（trans）の有無を判定
  const getDisplayTrans = async () => {
    const settings = await selectSettingsByUserId(userId);

    if (settings.docs.length > 0) {
      // settings以下の表示対象があった場合
      await Promise.all(
        settings.docs.map(async (setting) => {
          const uploads = await selectUploads(setting.id, userId);
          if (uploads.docs.length > 0) {
            // uploads以下の表示対象があった場合
            await Promise.all(
              uploads.docs.map(async (upload) => {
                const trans = await selectTrans(setting.id, upload.id, userId);

                if (trans.docs.length > 0) {
                  // transの表示対象があった場合、Translationに遷移
                  nextPage = PAGE_ROUTE_PATH.TRANSLATION;
                  return;
                }
              })
            );
          }
        })
      );
    }
  };

  // 初期表示時処理
  useLayoutEffect(() => {
    // firebase認証
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        signInWithCustomToken(auth, firebaseToken)
          .then(() => {
            // 認証OKの場合、初期表示時処理
            InitialDispEula();
          })
          .catch((_) => {
            // 認証エラー画面へ遷移
            navigate(`${PAGE_ROUTE_PATH.AUTH_ERROR}?lang=${language}`);
          });
      })
      .catch((_) => {
        // 認証エラー画面へ遷移
        navigate(`${PAGE_ROUTE_PATH.AUTH_ERROR}?lang=${language}`);
      });

    return () => {
      // アンマウント時に「ルーティング遷移先」変数を初期化
      nextPage = PAGE_ROUTE_PATH.SELECTFILES;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    eulaDialogOpen,
    t,
    commonDialogOpen,
    globalCommonDialogProps,
    eulaChangeDialogOpen,
    clickAgreeButton,
    clickDisagreeButton,
    clickDisplayButton,
    handleCommonDialogClose,
    EULA_HTML_BY_LANG,
  };
};
