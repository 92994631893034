import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import logo from '@/assets/logo-alt.svg';
import { DropDownMenu } from '@/components/Elements/';
import { CommonDialog, COMMON_DIALOG_TYPES, AccountMenu } from '@/components/Form/';
import { useHeader } from '@/components/Header';
import { availableLanguages } from '@/config';

import './Header.css';

type HeaderViewProps = {
  t: TFunction;
};

export const HeaderView = ({ t }: HeaderViewProps): JSX.Element => {
  const {
    displayAccount,
    userMail,
    commonDialogOpen,
    language,
    changeLanguage,
    handleCommonDialogClose,
    globalCommonDialogProps,
    clickLogo,
  } = useHeader(t);
  return (
    <div className="header" data-testid="header">
      <div className="header-left">
        <button className="header-home-button" onClick={() => clickLogo()}>
          <img className="header-logo" src={logo} alt="Workflow" decoding="async" />
          <div className="product-name">{t('common.productName')}</div>
        </button>
      </div>
      <div className="header-center" />
      <div className="header-right">
        <DropDownMenu
          name="language-select"
          id="language-select"
          items={availableLanguages}
          value={language}
          onChange={(e) => {
            changeLanguage(e.currentTarget.value);
          }}
        ></DropDownMenu>
        {displayAccount() && <AccountMenu userMail={userMail} />}
      </div>
      <CommonDialog
        open={commonDialogOpen}
        onClose={handleCommonDialogClose}
        title={globalCommonDialogProps.title}
        message={globalCommonDialogProps.message}
        {...(globalCommonDialogProps.buttonLabel && { buttonLabel: globalCommonDialogProps.buttonLabel })}
        dialogType={globalCommonDialogProps.dialogType}
      />
    </div>
  );
};

export const Header = () => {
  const { t } = useTranslation();
  return <HeaderView t={t} />;
};
