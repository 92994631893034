import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON_DIALOG_TYPES } from '@/components/Form';

// Settings画面で使用するダイアログリスト
const VIDEO_PREVIEW_DIALOG_TYPES = {
  DIALOG_ALERT_FILE_FAILED: '1', // 動画情報の取得失敗アラート
};
type VIDEO_PREVIEW_DIALOG_TYPES = (typeof VIDEO_PREVIEW_DIALOG_TYPES)[keyof typeof VIDEO_PREVIEW_DIALOG_TYPES];

// 共通ダイアロググローバルprops
const globalCommonDialogProps = {
  title: '',
  message: '',
  buttonLabel: '',
  dialogType: COMMON_DIALOG_TYPES.DIALOG_ALERT,
  videoPreviewDialogType: VIDEO_PREVIEW_DIALOG_TYPES.DIALOG_ALERT_FILE_FAILED,
};

/**
 * プレビュー動画再生ダイアログ hooks
 *
 * @returns
 */
export const useVideoPreviewDialog = (onClose: (visible?: boolean) => void) => {
  const { t } = useTranslation();

  // 共通ダイアログ開閉制御state
  const [commonDialogOpen, setCommonDialogOpen] = useState(false);

  // 共通ダイアログオープン処理
  const openCommonDialog = (
    commonDialogTitle: string,
    commonDialogMessage: string,
    commonDialogButtonLabel: string,
    commonDialogType: COMMON_DIALOG_TYPES,
    videoPreviewDialogType: VIDEO_PREVIEW_DIALOG_TYPES
  ) => {
    // グローバル変数に各項目をセット
    globalCommonDialogProps.title = commonDialogTitle;

    globalCommonDialogProps.message = commonDialogMessage;

    globalCommonDialogProps.buttonLabel = commonDialogButtonLabel;

    globalCommonDialogProps.dialogType = commonDialogType;
    globalCommonDialogProps.videoPreviewDialogType = videoPreviewDialogType;
    // オープン
    setCommonDialogOpen(true);
    return true;
  };

  // ダイアログクローズ時
  const handleDialogClose = useCallback(
    (visible?: boolean) => {
      onClose?.(visible);
    },
    [onClose]
  );

  // 共通ダイアログクローズ時
  const handleCommonDialogClose = useCallback(async () => {
    // ダイアログを閉じる
    setCommonDialogOpen(false);
    handleDialogClose(false);
  }, [handleDialogClose]);

  const error = () => {
    // エラーの場合
    openCommonDialog(
      '',
      t('common.alertVideoFailedMessage'),
      '',
      COMMON_DIALOG_TYPES.DIALOG_ALERT,
      VIDEO_PREVIEW_DIALOG_TYPES.DIALOG_ALERT_FILE_FAILED
    );
  };

  return {
    t,
    commonDialogOpen,
    globalCommonDialogProps,
    handleCommonDialogClose,
    handleDialogClose,
    error,
  };
};
