import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import './LicensesDialog.css';
import { FILE_LOAD_STATUS } from '@/hooks/useLoadFile';

import { useLoadLicenseList } from './hooks/useLoadLicenseList';

export type LicensesDialogProps = {
  open: boolean;
  onClose: () => void;
};

export const LicensesDialog = React.memo(({ open, onClose }: LicensesDialogProps) => {
  const { t } = useTranslation();
  const { state, licenseList } = useLoadLicenseList();

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="license-dialog-area">
        <dialog open={open} className="license-dialog">
          <div className="license-dialog-header">
            <div className="license-dialog-title">{t('common.legalInformation')}</div>
            <button type="button" onClick={() => onClose()} className="dialog-close-button">
              <div className="dialog-close-icon" />
            </button>
          </div>
          <div className="license-list-container">
            <div className="license-list">
              {/* 読み込み失敗時 */}
              {/* 静的ファイル読み込みのため通常発生し得ないのでエラーメッセージだけ表示 */}
              {state.status === FILE_LOAD_STATUS.FAILED && (
                <div className="license-error-message">エラーが発生しました</div>
              )}

              {/* 読み込み成功時 */}
              {state.status === FILE_LOAD_STATUS.SUCCESS &&
                licenseList.length &&
                licenseList.map((e) => (
                  <div className="license-list-cell-container" key={`${e.moduleName}`}>
                    {`${e.moduleName}(${e.license})`}
                    <br />
                    {e.repository}
                  </div>
                ))}
            </div>
          </div>
        </dialog>
      </div>
    </>,
    document.body
  );
});
