import { useRef } from 'react';

import { AccountMenuDialog } from '@/components/Form/';

import './AccountMenu.css';

export type AccountMenuProps = {
  userMail: string;
};

export const AccountMenu = ({ userMail }: AccountMenuProps) => {
  /*
   * State
   */
  const detailsRef = useRef<HTMLDetailsElement | null>(null);

  /**
   * クローズ処理
   */
  const onClose = (): void => {
    detailsRef.current?.removeAttribute('open');
  };

  return (
    <>
      <AccountMenuDialog onClose={onClose} userMail={userMail} detailsRef={detailsRef} />
    </>
  );
};
