import Tooltip from '@mui/material/Tooltip';

import { useVideoInfoToolTip, TextBlock } from '@/components/Elements/';

// ツールチップProps
export type VideoInfoToolTipProps = {
  limitType: string[];
  limitSize: number;
  limitDuration: number;
  limitHeight: string;
  limitWidth: string;
  placement?: 'bottom' | 'top' | 'left';
};

export const VideoInfoToolTip = ({
  limitType,
  limitSize,
  limitDuration,
  limitHeight,
  limitWidth,
  placement = 'bottom',
}: VideoInfoToolTipProps) => {
  const { t, breakedText } = useVideoInfoToolTip(limitType, limitSize, limitDuration, limitHeight, limitWidth);

  return (
    <>
      <Tooltip title={breakedText} placement={placement}>
        <div className="support-video-type">
          <span />
          <TextBlock text={t('selectFiles.supportVideoType')} size="md"></TextBlock>
        </div>
      </Tooltip>
    </>
  );
};
