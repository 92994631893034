import { useCallback, useState } from 'react';

import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import {
  ContractData,
  PlansLocalizationData,
  selectProducts,
  selectPlansByPlanCode,
  selectLocalizationsByLang,
} from '@/services';
import { PLAN_CODE } from '@/utils/constants';

// 本日日付取得
const workDate = new Date();
const year = workDate.getFullYear();
const month = workDate.getMonth();
const day = workDate.getDate();
const currentDate = new Date(year, month, day);

let planCode: string;

const useUserPlanName = () => {
  const { language } = useBrowserUserSetting();
  const [planName, setPlanName] = useState('');
  const [subscriptionType, setSubscriptionType] = useState(0);

  const getPlanName = useCallback(
    async (contracts: any) => {
      planCode = PLAN_CODE.SHOT_PLAN;
      let nowValidPlan = '';
      let validPlan = '';
      await Promise.all(
        contracts.docChanges().map(async (contract: any) => {
          const contractData: ContractData = contract.doc.data();
          // プランの開始日
          const startDate = contractData.contract_start_date.toDate();
          if (
            // 契約開始日が過去のものがあった場合
            currentDate.getTime() >=
            new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()).getTime()
          ) {
            if (!contractData.contract_end_date) {
              // 契約終了日がnullのものがあった場合
              validPlan = contract.doc.id;
            } else if (currentDate.getTime() <= new Date(contractData.contract_end_date.toDate()).getTime()) {
              // 契約終了日が未来のものがあった場合
              nowValidPlan = contract.doc.id;
            }
          }
        })
      );

      if (nowValidPlan) {
        planCode = nowValidPlan;
      } else if (validPlan) {
        planCode = validPlan;
      }

      const products = await selectProducts();

      if (products.docs.length) {
        // products以下の表示対象があった場合
        await Promise.all(
          products.docs.map(async (product) => {
            const plans = await selectPlansByPlanCode(product.id, planCode);

            if (plans.docs.length) {
              // plans以下の表示対象があった場合
              plans.docs.map(async (plan) => {
                setSubscriptionType(plan.data().subscription_type);
                const localizations = await selectLocalizationsByLang(product.id, plan.id, language);

                if (localizations.docs.length) {
                  localizations.docs.map((localization) => {
                    const plansLocalizationData: PlansLocalizationData = localization.data();
                    setPlanName(plansLocalizationData.plan_name);
                  });
                }
              });
            }
          })
        );
      }
    },
    [language]
  );

  return {
    planName,
    planCode,
    subscriptionType,
    getPlanName,
  };
};

export default useUserPlanName;
