import clsx from 'clsx';
import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Elements/';
import { useKeyDown } from '@/hooks/useKeyDown';

import './CommonDialog.css';

export const COMMON_DIALOG_TYPES = {
  DIALOG_ALERT: '1', // 警告ダイアログ
  DIALOG_CONFIRM_NORMAL: '2', // 確認ダイアログ（通常）
  DIALOG_CONFIRM_DESTRUCTIVE: '3', // 確認ダイアログ（重要）
};
export type COMMON_DIALOG_TYPES = (typeof COMMON_DIALOG_TYPES)[keyof typeof COMMON_DIALOG_TYPES];

export type CommonDialogProps = {
  open: boolean;
  title: string;
  message: string;
  buttonLabel?: string;
  dialogType: COMMON_DIALOG_TYPES;
  onClose: (flgOk?: boolean) => void;
};

export const CommonDialog = ({ open, title, message, buttonLabel, dialogType, onClose }: CommonDialogProps) => {
  const { t } = useTranslation();

  // ダイアログクローズ時
  const handleDialogClose = useCallback(
    (isOk: boolean) => {
      onClose?.(isOk);
    },
    [onClose]
  );

  // Enterキー押下→「OK」、Escapeキー押下→「キャンセル」の処理
  useKeyDown({
    onEnterKeyDown: () => {
      if (open) {
        handleDialogClose(true);
      }
    },
    onEscapeKeyDown: () => {
      if (open) {
        handleDialogClose(false);
      }
    },
  });

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="common-dialog-area">
        <dialog open={open} className="common-dialog">
          <div className="dialog-text">
            <div className="common-dialog-title">{title}</div>
            <div className="common-dialog-message">{message}</div>
          </div>

          <div className="common-dialog-buttons">
            <span
              className={clsx(
                `cancel-button ${buttonLabel ? 'confirm-dialog-button-secondary' : 'alert-dialog-button-secondary'}`
              )}
            >
              <Button label={t('common.cancel')} onClick={() => handleDialogClose(false)} />
            </span>
            <span
              className={
                dialogType !== COMMON_DIALOG_TYPES.DIALOG_CONFIRM_DESTRUCTIVE
                  ? 'mvs-dialog-agree'
                  : 'dialog-button-destructive'
              }
            >
              <Button label={buttonLabel ? buttonLabel : 'OK'} onClick={() => handleDialogClose(true)} />
            </span>
          </div>
        </dialog>
      </div>
    </>,
    document.body
  );
};
