import { useTranslation } from 'react-i18next';

import { StepIndicator, TicketRemainButton } from '@/components/Elements';
import { POCKETALK_URL } from '@/constants';
type CommonAreaProps = {
  screenName: SCREEN_NAME_TYPES;
  remainValue: number;
  displayStepIndicator?: boolean;
};

export const SCREEN_NAME_TYPES = {
  SELECT_FILES: '1', // selectFiles
  SETTINGS: '2', // settings
  UPLOAD: '3', // upload
  TRANSLATE: '4', // mock
};
export type SCREEN_NAME_TYPES = (typeof SCREEN_NAME_TYPES)[keyof typeof SCREEN_NAME_TYPES];

export const CommonArea = ({ screenName, remainValue, displayStepIndicator = true }: CommonAreaProps) => {
  const { t } = useTranslation();
  return (
    <div className="common-area">
      <div className="common-area-left" />
      <div className="common-area-center">
        {displayStepIndicator ? (
          <StepIndicator screenName={screenName} />
        ) : (
          <>
            <span className="contact-us">{t('translation.contactLabel')}</span>
            <button className="contact-button" onClick={() => window.open(`${POCKETALK_URL}/deal_inquiry/form`)}>
              {t('translation.contactAction')}
            </button>
          </>
        )}
      </div>
      <div className="common-area-right">
        <TicketRemainButton ticketRemainValue={remainValue} />
      </div>
    </div>
  );
};
