import { useState, useCallback } from 'react';

import { useWindowSize } from './useWindowSize';

export const useEllipsis = (text?: string): [(node: HTMLDivElement) => void, boolean | undefined] => {
  const [ellipsis, setEllipsis] = useState(false);

  const [width] = useWindowSize();

  const enableEllipsis = useCallback(
    (node: HTMLDivElement) => {
      // textがundefinedの場合、エリプシスを非表示として処理終了
      if (!text) {
        setEllipsis(false);
        return;
      }
      if (!node) return;
      // scrollWidth(全文表示した際のwidth)と指定したwidthを比較
      setEllipsis(node.scrollWidth > node.offsetWidth);
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [text, width]
  );
  return [enableEllipsis, ellipsis];
};
