export type DropDownMenuItem = {
  value: string;
  label: string;
};

export type DropDownMenuProps = {
  name: string;
  id: string;
  items: DropDownMenuItem[];
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const DropDownMenu = ({ name, id, items, value, onChange }: DropDownMenuProps) => {
  // ドロップダウン項目が取得できない場合、何も表示しない
  if (items.length === 0) {
    return;
  }

  const options = items.map((i) => (
    <option key={i.label} value={i.value} className="block px-[2px] pb-[1px] pt-0 text-base text-black">
      {i.label}
    </option>
  ));
  return (
    <select
      data-testid="select"
      name={name}
      id={id}
      onChange={(e) => onChange(e)}
      value={value}
      className="rounded-[4px] border-none bg-white p-[10px] text-base text-black sp:px-0 sp:py-[6px] sp:text-sm"
    >
      {options}
    </select>
  );
};
