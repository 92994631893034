import { useState, useEffect } from 'react';

import { HANDSFREE } from '@/constants';
import { LangListTypes } from '@/types/commonTypes';

/**
 * URLパラメータ操作するためのhooks
 */
export const useLangList = (language: string, en: any, ja: any) => {
  const [langList, setLangList] = useState<LangListTypes[]>([]);
  // 翻訳言語リストを取得
  // TODO：httpsがローカルから読めないため、分岐処理を追加
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      (async () => {
        if (language === 'ja') {
          const resSttJa = await fetch(`${HANDSFREE.DOMAIN.LANGUAGE}${HANDSFREE.LANGUAGE_URL.STT_JA}`);
          const langListSttJaObject = await resSttJa.text();
          setLangList(JSON.parse(langListSttJaObject));
        } else {
          const resSttEn = await fetch(`${HANDSFREE.DOMAIN.LANGUAGE}${HANDSFREE.LANGUAGE_URL.STT_EN}`);
          const langListSttEnObject = await resSttEn.text();
          setLangList(JSON.parse(langListSttEnObject));
        }
      })();
    } else {
      // ローカルのJSONを参照
      if (language === 'ja') {
        setLangList(JSON.parse(JSON.stringify(ja)));
      } else {
        setLangList(JSON.parse(JSON.stringify(en)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return {
    langList, // URLパラメータから指定した値を取得
  };
};
