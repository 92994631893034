import { Button, Spinner } from '@/components/Elements/';
import { useEulaDialog, CommonDialog } from '@/components/Form/';

import './EulaDialog.css';

export const EulaDialog = (): JSX.Element => {
  const {
    eulaDialogOpen,
    t,
    commonDialogOpen,
    globalCommonDialogProps,
    eulaChangeDialogOpen,
    clickAgreeButton,
    clickDisagreeButton,
    clickDisplayButton,
    handleCommonDialogClose,
    EULA_HTML_BY_LANG,
  } = useEulaDialog();
  return (
    <>
      {eulaDialogOpen && (
        <div className="eula-dialog-area">
          <dialog open={eulaDialogOpen} className="eula-dialog">
            <div>
              <div className="eula-dialog-title">{t('eula.title')}</div>
            </div>

            <div className="eula-dialog-main">
              <div className="eula-dialog-message">{t('eula.message')}</div>
              <iframe id="iframeRule" src={EULA_HTML_BY_LANG} title={t('eula.title')}></iframe>
            </div>

            <div className="eula-dialog-buttons">
              <span className="eula-dialog-disagree">
                <Button label={t('eula.disagree')} onClick={() => clickDisagreeButton()} />
              </span>
              <span className="eula-dialog-agree">
                <Button label={t('eula.agree')} onClick={() => clickAgreeButton()} />
              </span>
            </div>
          </dialog>
        </div>
      )}

      {!eulaDialogOpen && !commonDialogOpen && !eulaChangeDialogOpen && (
        <div className="flex h-screen w-screen items-center justify-center">
          <Spinner size="lg" />
        </div>
      )}

      {eulaChangeDialogOpen && (
        <div className="common-dialog-area">
          <dialog open={eulaChangeDialogOpen} className="common-dialog">
            <div className="dialog-text">
              <div className="common-dialog-title">{t('eula.changeTitle')}</div>
              <div className="common-dialog-message">{t('eula.changeMessage')}</div>
            </div>

            <div className="common-dialog-buttons">
              <span className="cancel-button">
                <Button label={t('eula.cancel')} onClick={() => clickDisagreeButton()} />
              </span>
              <span className="eula-dialog-display">
                <Button label={t('eula.display')} onClick={() => clickDisplayButton()} />
              </span>
            </div>
          </dialog>
        </div>
      )}

      <CommonDialog
        open={commonDialogOpen}
        onClose={handleCommonDialogClose}
        title={globalCommonDialogProps.title}
        message={globalCommonDialogProps.message}
        {...(globalCommonDialogProps.buttonLabel && { buttonLabel: globalCommonDialogProps.buttonLabel })}
        dialogType={globalCommonDialogProps.dialogType}
      />
    </>
  );
};
