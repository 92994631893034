import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { Button, Spinner } from '@/components/Elements';
import { i18n } from '@/config';

const ErrorFallback = () => {
  const { t } = useTranslation();
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center text-red-500" role="alert">
      <h2 className="pb-4 text-base">{t('common.unknownError')}</h2>
      <Button
        label={t('common.reload')}
        onClick={() =>
          window.location.assign(window.location.origin + window.location.pathname + window.location.search)
        }
      />
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          <Spinner size="lg" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
