/**
 * ポケトークエンジンサーバ
 */
export const HANDSFREE = {
  DOMAIN: {
    // language
    LANGUAGE: process.env.REACT_APP_HANDSFREE_LANGUAGE_URL as string,
  },
  LANGUAGE_URL: {
    // STT（日本語）
    STT_JA: '/stt.ja.json',
    // STT（英語）
    STT_EN: '/stt.en.json',
    // TTT（日本語）
    TTT_JA: '/ttt.ja.json',
    // TTT（英語）
    TTT_EN: '/ttt.en.json',
    // TTS（日本語）
    TTS_JA: '/tts.ja.json',
    // TTS（英語）
    TTS_EN: '/tts.en.json',
    // TTS Detail
    TTS_DETAIL: '/tts.detail.json',
  },
} as const;

/**
 * ムービー翻訳サーバ
 */
export const VIDEO_TRANSLATOR = {
  DOMAIN: process.env.REACT_APP_VIDEO_TRANSLATOR_API_URL as string,
  API_URL: {
    // ログアウトAPI(リダイレクト用)
    LOGOUT_REDIRECT: '/private/redirect/token/delete',
    // PTID認証API
    PTID: '/pub/ptid/auth',
  },
} as const;

/**
 * ポケトーク認証サーバ
 */
export const POCKETALK_AUTH = {
  DOMAIN: process.env.REACT_APP_AUTH_API_URL as string,
  API_URL: {
    // ログイン認証
    AUTHORIZE: '/authorize',
  },
  // ポケトーク認証用:アプリケーション登録申請後に発行されたclient_id
  CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
} as const;

/**
 * PAYサーバ
 */
export const PAY = {
  DOMAIN: process.env.REACT_APP_PAY_URL as string,
  API_URL: {
    // プラン詳細エンドポイント
    PLM_0100: '/plmweb/plmweb/s/plm0100',
    // ポケトークセンターエンドポイント
    PYT_0130: '/pytweb/pytweb/s/pyt0130',
    // チケット購入エンドポイント
    PYT_0300: '/pytweb/pytweb/s/pyt0300',
  },
} as const;

/**
 * API呼び出し状況
 */
export const API_STATUS = {
  // 呼び出し前
  IDLE: 'idle',
  // 呼び出し中
  LOADING: 'loading',
  // 失敗
  FAILED: 'failed',
  // 成功
  SUCCESS: 'success',
} as const;
export type ApiStatus = (typeof API_STATUS)[keyof typeof API_STATUS];
