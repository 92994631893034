// 字幕設定ラジオボタンリスト
export const SUBTITLE = {
  NO_SUBTITLES: '0', // 字幕なし
  NO_BACKGROUND: '1', // 背景なし
  BACKGROUND: '2', // 背景あり
};

// 吹替有無ラジオボタンリスト
export const DUB = {
  DUBBED: '1', // 吹き替えあり
  NO_DUBBED: '2', // 吹き替えなし
};

/**
 * アップロードステータス
 */
export const UPLOAD_STATUS = {
  // アップロード待ち
  UPLOAD_PENDING: '0',
  // アップロード中
  UPLOADING: '1',
  // アップロード済み
  UPLOADED: '2',
} as const;

/**
 * プレビュー生成ステータス
 */
export const PREVS_STATUS = {
  // プレビュー生成待ち
  QUEING: '0',
  // プレビュー生成中
  PREVIEWING: '1',
  // プレビュー生成済み
  PREVIEWED: '2',
  // 削除
  DELETED: '9',
} as const;

/**
 * アップロード処理エラーステータス
 */
export const UPLOAD_ERROR_STATUS = {
  // エラー無し
  NO_ERROR: '',
  // エラー
  UPLOAD_ERROR: '1',
  // ネットワークエラー
  NETWORK_ERROR: '2',
} as const;

/**
 * 翻訳処理ステータス
 */
export const TRANS_STATUS = {
  // 変換待ち
  WAITING: '0',
  // 変換中
  ENCODING: '1',
  // 変換済み
  ENCODED: '2',
  // 削除
  DELETED: '9',
} as const;

/**
 * プレビュー生成、翻訳処理エラーステータス
 */
export const PREV_TRAN_ERROR_STATUS = {
  // エラー無し
  NO_ERROR: '',
  // バリデーションエラー
  VALID_ERROR: '1',
  // FFMPEGエラー
  FFMPEG_ERROR: '2',
  // その他のエラー
  OTHER_ERROR: '3',
  // Whisperエラー
  WHISPER_ERROR: '4',
  // 翻訳エンジンエラー
  ENGINE_ERROR: '5',
  // チケット戻しエラー
  TICKET_RETURN_ERROR: '6',
} as const;

/**
 * 商品コード
 */
export const PRODUCT_CODE = {
  // ポケトークムービー翻訳
  POCKETALK_VIDEO_TRANSLATOR: '0000337560',
} as const;

/**
 * プランコード
 */
export const PLAN_CODE = {
  // ショットユーザー
  SHOT_PLAN: '000000',
} as const;

export const SUBSCRIPTION_TYPE = {
  // ショットユーザー
  SHOT_PLAN: 0,
} as const;

/**
 * 翻訳先言語選択数の上限
 */

export const SELECT_LANG_LIMIT = 5;

/**
 * GCSディレクトリパス
 */
export const COMMON_DIR = 'videofile';

// ファイルバリデーションリスト
export const VALIDATION_TYPE = {
  // 拡張子エラー
  TYPE_ERROR: 0,
  // サイズ超過エラー
  SIZE_ERROR: 1,
  // 長さ超過エラー
  LENGTH_ERROR: 2,
  // 解像度エラー
  NO_RESOLUTION_ERROR: 3,
  // 解像度超過エラー
  RESOLUTION_ERROR: 4,
} as const;

// ファイル名置換対象文字
export const FILE_NAME_REGEX = /(,|;|'|\[|\]|\/|\\)/g;

// ファイル名最大バイト数
export const MAX_FILE_NAME_BYTES = 224;

/**
 * コレクション名
 */
export const COLLECTION = {
  SETTINGS: 'settings',
  DST_LANGS: 'dst_langs',
  UPLOADS: 'uploads',
  PREVS: 'prevs',
  TRANS: 'trans',
  PRODUCTS: 'products',
  PLANS: 'plans',
  LOCALIZATION: 'localization',
  TICKETS: 'tickets',
  USERS: 'users',
  CONTRACTS: 'contracts',
  CHARGES: 'charges',
} as const;
