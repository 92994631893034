import { useCallback, useState } from 'react';

type useOpenValue = {
  // 表示中かどうか
  isOpen: boolean;
  // 表示中の値を変更
  changeIsOpen: (value: boolean) => void;
  // isOpenをfalseに変更する
  onDialogClose: () => void;
  // isOpenをtrueに変更する
  onDialogOpen: () => void;
};

/**
 * 表示/非表示を管理するhooks
 */
const useOpen = (defaultIsOpen = false): useOpenValue => {
  // 表示されるかどうか
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen);

  /**
   * isOpenの値を変更する
   * @param value
   */
  const changeIsOpen = useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  /**
   * isOpenの値をfalseにする
   */
  const onClose = useCallback(() => {
    changeIsOpen(false);
  }, [changeIsOpen]);

  /**
   * isOpenの値をtrueにする
   */
  const onOpen = useCallback(() => {
    changeIsOpen(true);
  }, [changeIsOpen]);

  return {
    isOpen,
    changeIsOpen,
    onDialogClose: onClose,
    onDialogOpen: onOpen,
  };
};

export default useOpen;
