import { formatAcceptTypesByPicker } from '@/utils/formatUtil';
export type AddFileButtonProps = {
  label: string;
  acceptType: string[];
  disabled?: boolean;
  commonButtonFlg?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const AddFileButton = ({
  label,
  acceptType,
  disabled = false,
  commonButtonFlg = true,
  onChange,
}: AddFileButtonProps) => {
  return (
    <div className={commonButtonFlg ? 'common-button' : 'add-files-button'}>
      <label data-testid="addFileButton">
        <input
          type="file"
          onChange={(e) => {
            onChange(e);
            e.target.value = '';
          }}
          accept={formatAcceptTypesByPicker(acceptType)}
          multiple
          disabled={disabled}
        />
        <div className="mvs-addfile">
          <div className="button-label">{label}</div>
        </div>
      </label>
    </div>
  );
};
