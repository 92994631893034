import { useState, useCallback } from 'react';

export const useTranslateToRow = () => {
  // 共通ダイアログ開閉制御state
  const [commonDialogOpen, setCommonDialogOpen] = useState(false);

  // 動画プレビューダイアログ開閉制御state
  const [videoPreviewDialogOpen, setVideoPreviewDialogOpen] = useState(false);

  // 共通ダイアログクローズ時
  const handleCommonDialogClose = useCallback(async () => {
    // ダイアログを閉じる
    setCommonDialogOpen(false);
  }, [setCommonDialogOpen]);
  // ダイアログオープン処理
  const openVideoPreviewDialog = () => {
    // TODO：エラーダイアログ、エラー制御
    // const xhr: XMLHttpRequest = new XMLHttpRequest();
    // xhr.open('HEAD', videoUrl, false);
    // xhr.send(null);
    // if (xhr.status !== 200) {
    //   setCommonDialogOpen(true);
    // } else {
    setVideoPreviewDialogOpen(true);
    // }
  };

  // 動画プレビューダイアログクローズ時
  const handleVideoPreviewDialogClose = useCallback(() => {
    // ダイアログを閉じる
    setVideoPreviewDialogOpen(false);
  }, [setVideoPreviewDialogOpen]);

  return {
    videoPreviewDialogOpen,
    commonDialogOpen,
    openVideoPreviewDialog,
    handleCommonDialogClose,
    handleVideoPreviewDialogClose,
  };
};
