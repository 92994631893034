import { useTranslation } from 'react-i18next';

import { LangMenuButton, TextBlock } from '@/components/Elements/';
import { TranslateToInfoTypes } from '@/components/Form/VoiceTypeSettingsDialog';
import sttEn from '@/config/stt/stt.en.json';
import sttJa from '@/config/stt/stt.ja.json';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useLangList } from '@/hooks/useLangList';
import { formatlangLabel } from '@/utils/formatUtil';

import './SettingsArea.css';

// アップロード設定エリアProps
type SettingsProps = {
  translateFrom: string; //翻訳元
  translateTo: TranslateToInfoTypes[]; //翻訳先
  subtitleStyle: string; //字幕スタイル
};

export const SettingsArea = ({ translateFrom, translateTo, subtitleStyle }: SettingsProps) => {
  const { t } = useTranslation();
  const { language } = useBrowserUserSetting();
  const { langList } = useLangList(language, sttEn, sttJa);

  return (
    <>
      <div className="settings-area-main">
        <div className="settings-area-row mod-translate-from">
          <div className="settings-area-title mod-align-center">
            <TextBlock text={t('upload.translateFrom')} align="left" />
          </div>
          {translateFrom && (
            <div className="settings-area-block" data-testid="translate-from">
              <label className="settings-lang-label">{formatlangLabel(translateFrom, langList)}</label>
            </div>
          )}
        </div>
        <div className="settings-area-row mod-translate-to">
          <div className="settings-area-title">
            <TextBlock text={t('upload.translateTo')} align="left" />
          </div>
          {translateTo.length > 0 && (
            <div className="settings-area-lang" data-testid="translate-to">
              <LangMenuButton translateToValue={translateTo} />
            </div>
          )}
        </div>
        <div className="settings-area-row mod-subtitle">
          <div className="settings-area-title">
            <TextBlock text={t('upload.subtitleStyle')} align="left" />
          </div>
          <div className="settings-area-value" data-testid="subtitle-style">
            <TextBlock text={subtitleStyle} align="left" />
          </div>
        </div>
      </div>
    </>
  );
};
