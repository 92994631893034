import { LANG_SETTING_API_URL } from '@/config';
import { axios } from '@/lib/axios';

/** 表示言語設定API リクエストパラメータ */
export type LangSettingApiRequest = {
  user_id: string;
  used_lang: string;
};

/**
 * 表示言語設定API  結果コード定数
 */
export const LANG_SETTING_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // ポケトークサーバのクライアント認証エラー
  WARN_EXTERNAL_AUTH: 'WARN_EXTERNAL_AUTH',
  // 利用言語更新APIのエラー、対象のユーザが存在しない
  INFO_NOT_ACCOUNT: 'INFO_NOT_ACCOUNT',
  // 利用言語更新APIのエラー、存在しない利用言語
  INFO_NOT_EXIST_LANGUAGE: 'INFO_NOT_EXIST_LANGUAGE',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
export type LangSettingApiResultCode = (typeof LANG_SETTING_API_RESULT_CODE)[keyof typeof LANG_SETTING_API_RESULT_CODE];

/** 表示言語設定API レスポンス */
export type LangSettingApiResponse = {
  resultCode: LangSettingApiResultCode;
};

export const langSettingApi = (request: LangSettingApiRequest): Promise<LangSettingApiResponse> => {
  const jsonParams = {
    userId: request.user_id,
    usedLang: request.used_lang,
  };

  const response = axios
    .post<LangSettingApiResponse>(LANG_SETTING_API_URL, jsonParams, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};
