import { useCallback, useEffect, useRef } from 'react';

import { useLogoutRedirectApi } from '@/features/api';

/**
 * ログアウト成功時に実行したい関数の型
 */
export type SuccessFunction = () => void;

/**
 * プロパティ
 */
export type Props = {
  // ログアウト成功時に実行したい処理
  onSuccessFun?: SuccessFunction;
};

/**
 * ログアウト処理 カスタムフック
 *
 * @returns
 */
export const useLogout = ({ onSuccessFun }: Props) => {
  const { logoutRedirectState, doLogoutRedirect } = useLogoutRedirectApi();

  const onSuccessFuncRef = useRef<SuccessFunction>();
  useEffect(() => {
    onSuccessFuncRef.current = onSuccessFun;
  }, [onSuccessFun]);

  /**
   * ログアウトを実行する
   */
  const doLogout = useCallback(
    (mode: string) => {
      // プロパティに指定された関数実行
      onSuccessFuncRef.current?.();
      // ログアウトAPI(リダイレクト用)にGETアクセス
      doLogoutRedirect(mode);
    },
    [doLogoutRedirect]
  );

  return {
    doLogout,
    logoutRedirectStatus: logoutRedirectState.status,
  };
};
