import { LangListTypes } from '@/types/commonTypes';
import { DUB, SUBTITLE } from '@/utils/constants';

/**
 * ファイル名から拡張子を取得する
 * @param name ファイル名
 * @returns 拡張子
 */
export const getExtension = (name: string): string => {
  const pos = name.lastIndexOf('.');
  if (pos === -1) {
    return '';
  }

  return name.slice(pos + 1);
};

/**
 * ファイル名から拡張子を除いた文字列を取得する
 * @param name ファイル名
 * @returns 拡張子を除いたファイル名
 */
export const getFileName = (name: string): string => {
  const pos = name.lastIndexOf('.');
  if (pos === -1) {
    return name;
  }

  return name.slice(0, pos);
};

/**
 * バイトをギガバイトに変換する
 * @param bytes
 * @returns ギガバイト変換後の値(文字列)
 */
export const byteToGigaByte = (bytes: number): string => {
  const giga = 1073741824; // 1024*1024*1024

  return (bytes / giga).toFixed();
};

/**
 * 秒を「hh:mm:ss」形式にフォーマットした文字列を返す
 * @param value 秒
 * @returns hh:mm:ss
 */
export const formatSec = (value: number): string => {
  // マイナスは0で返す
  if (value < 0) {
    return '00:00:00';
  }

  const hour = Math.floor(value / 3600);
  const min = Math.floor((value % 3600) / 60);
  const sec = Math.floor(value % 60);

  return `${hour.toString().length > 2 ? hour : ('00' + hour).slice(-2)}:${('00' + min).slice(-2)}:${('00' + sec).slice(
    -2
  )}`;
};

/**
 * 秒を「hh:mm:ss.sss」形式にフォーマットした文字列を返す
 * @param value 秒
 * @returns hh:mm:ss
 */
export const formatSecDecimal = (value: number): string => {
  // 1000時間を超える場合は、「999:59:59.999」をリターン
  if (value >= 60 * 60 * 10) {
    return '999:59:59.999';
  }

  const format = formatSec(value);

  const zeroPadding = (Math.floor(value * 1000) / 1000).toFixed(3);
  const decimal = zeroPadding.split('.')[1];

  return `${format}.${decimal}`;
};

/**
 * 「hh:mm:ss」または「hh:mm:ss.sss」形式を秒に返す
 * @param value hh:mm:ss
 * @returns 秒
 */
export const timeToSec = (value: string) => {
  const time = value.split('.');
  const [hours, minutes, seconds] = time[0].split(':');

  // ミリ秒がある場合、小数点の値に変換
  if (time[1]) {
    let millisecond = Number(time[1]);
    for (let i = 0; i < time[1].length; i++) {
      millisecond = millisecond * 0.1;
    }
    return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds) + millisecond;
  }
  return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
};

/**
 * 秒を分にフォーマットした文字列を返す
 * @param value 秒
 * @returns 分
 */
export const secToMin = (value: number): string => {
  return Math.floor(value / 60).toString();
};

/**
 * 日時を「yy/MM/dd」形式にフォーマットした文字列を返す
 * @param value 日時
 * @returns yy/MM/dd
 */
export const formatDate = (value: Date): string => {
  if (!value) {
    return '';
  }
  // 正しい日付にフォーマット
  const formatDate = new Date(value);
  return formatDate.toLocaleDateString('ja-JP');
};

/**
 * カンマ区切り文字列を配列とする
 * @param value
 * @returns 配列データ
 */
export const valueToArray = (value: string): string[] => {
  if (!value) {
    return [];
  }
  return value.split(',');
};

/**
 * ファイルタイプの配列を画面表示用にフォーマット
 * @param value
 * @returns 画面表示用データ
 */
export const formatFileTypes = (fileTypes: string[]): string => {
  if (fileTypes.length === 0) {
    return '';
  }
  let displayFileTypes = '';
  fileTypes.map((item: string) => {
    displayFileTypes += item.trim();
    displayFileTypes += ' / ';
  });
  return displayFileTypes.slice(0, -3);
};

/**
 * Auth0IDの整形
 * @param userId Auth0ID
 * @returns フォーマットされたuserId
 */
export const formatUserId = (userId: string | undefined): string => {
  if (!userId || userId?.length === 0) {
    return '';
  }
  // pipeが最後に出現するindexを取得
  const pipeIndex = userId.lastIndexOf('|');
  // pipe以降の文字列を切り取りして返却
  const formatUserId = userId.substring(pipeIndex + 1);
  return formatUserId;
};

export const formatlangLabel = (langCode: string, langList: LangListTypes[]): string | undefined => {
  let langName = '';
  //言語コードがzhの場合、置換
  if (langCode === 'zh') {
    langCode = 'zh-CN';
  }

  langList.map((lang: LangListTypes) => {
    //言語リストのキーと、選択された言語のキーが一致した時、言語リストの言語名を取得する
    if (Object.keys(lang)[0] === langCode) {
      langName = Object.values(lang)[0].toString();
      return;
    }
  });
  return langName;
};

// ファイルタイプの配列をバリデーション用にフォーマット（react-dropzone）
export const formatAcceptTypesByDrop = (acceptType: string[]): string[] => {
  const formatAcceptTypes: string[] = [];
  // 受け入れる動画タイプの配列
  acceptType.map((type) => {
    // .を付与して再push
    formatAcceptTypes.push('.' + type.trim());
  });
  return formatAcceptTypes;
};

/**
 * ファイルタイプの配列をバリデーション用にフォーマット（ファイルピッカー）
 * @param value
 * @returns バリデーション用データ
 */
export const formatAcceptTypesByPicker = (fileTypes: string[]): string => {
  if (fileTypes.length === 0) {
    return '';
  }
  let displayFileTypes = '';
  fileTypes.map((item: string) => {
    displayFileTypes += '.';
    displayFileTypes += item.trim();
    displayFileTypes += ' , ';
  });
  return displayFileTypes.slice(0, -3);
};

// 残り時間→進捗文字列変換処理
export const formatRemainTime = (elapsedTime: number, uploadRate: number) => {
  // 進捗率が100%の場合、空文字
  if (uploadRate === 100) {
    return '';
  }
  if (!elapsedTime) {
    return '';
  }
  const remainSec = calcRemainSec(elapsedTime, uploadRate);
  // 残り時間に応じて、一覧行に表示する文言も変更する
  switch (true) {
    case remainSec < 60:
      return 'common.aFewSecRemaining';
    case remainSec < 3600 && remainSec >= 60:
      return 'common.minRemaining';
    case remainSec < 86400 && remainSec >= 3600:
      return 'common.hourRemaining';
    case remainSec >= 86400:
      return 'common.dayRemaining';
    default:
      return '';
  }
};

// 残り時間（秒）算出処理
export const calcRemainSec = (elapsedTime: number, uploadRate: number): number => {
  if (!uploadRate) {
    return 0;
  }
  if (!elapsedTime) {
    return 0;
  }
  return Math.floor(elapsedTime * (100 / uploadRate - 1));
};

export type RemainDhmProps = {
  day: string;
  hour: string;
  min: string;
};

// 残り時間（分、日、時間）算出処理
export const calcRemainDhm = (remainSec: number): RemainDhmProps => {
  if (!remainSec) {
    return { day: '0', hour: '0', min: '0' };
  }

  const day = Math.floor(remainSec / 86400).toString();
  const hour = Math.floor((remainSec % 86400) / 3600).toString();
  const min = Math.floor((remainSec % 3600) / 60).toString();
  return { day: day, hour: hour, min: min };
};

// 字幕の画面表示用フォーマット処理
export const formatDisplaySubtitle = (subtitle: string): string => {
  switch (subtitle) {
    case SUBTITLE.NO_BACKGROUND:
      return 'common.noBackground';
    case SUBTITLE.BACKGROUND:
      return 'common.background';
    case SUBTITLE.NO_SUBTITLES:
      return 'common.noSubtitles';
    default:
      return '';
  }
};

// 吹き替えの画面表示用フォーマット処理
export const formatDisplayDub = (dub: string): string => {
  switch (dub) {
    case DUB.DUBBED:
      return 'common.dubbed';
    case DUB.NO_DUBBED:
      return 'common.noDubbed';
    default:
      return '';
  }
};
