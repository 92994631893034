import { Image } from '@/components/Elements/';
import { CommonDialog, useVideoPreviewDialog } from '@/components/Form';
import closeDialog from '@/img/close_dialog.svg';
import thumbnail from '@/img/place-holder.jpg';

import './VideoPreviewDialog.css';

type VideoPreviewDialogProps = {
  open: boolean;
  videoUrl: string;
  onClose: (visible?: boolean) => void;
};

export const VideoPreviewDialog = ({ open, videoUrl, onClose }: VideoPreviewDialogProps): JSX.Element => {
  const { globalCommonDialogProps, commonDialogOpen, error, handleDialogClose, handleCommonDialogClose } =
    useVideoPreviewDialog(onClose);

  return (
    <>
      {open && (
        <div className="video-preview-dialog-wrapper">
          <dialog open={open} className="video-preview-dialog">
            <div className="close-button">
              <button onClick={() => handleDialogClose(false)}>
                <Image src={closeDialog as string} />
              </button>
            </div>

            <div className="video-preview-container">
              <video autoPlay muted controls src={videoUrl} poster={thumbnail} onError={error}></video>
            </div>
          </dialog>
          <CommonDialog
            open={commonDialogOpen}
            onClose={handleCommonDialogClose}
            title={globalCommonDialogProps.title}
            message={globalCommonDialogProps.message}
            {...(globalCommonDialogProps.buttonLabel && { buttonLabel: globalCommonDialogProps.buttonLabel })}
            dialogType={globalCommonDialogProps.dialogType}
          />
        </div>
      )}
    </>
  );
};
