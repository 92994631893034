import { useTranslation } from 'react-i18next';

import { TicketRemainBar, ToolTip } from '@/components/Elements/';
import { formatSec } from '@/utils/formatUtil';

import './TicketUsageArea.css';

// チケット管理エリアProps
type TicketUsageProps = {
  usageValue: number; // 動画合計時間
  remainValue: number; // チケット残り時間
};

export const TicketUsageArea = ({ usageValue, remainValue }: TicketUsageProps) => {
  const { t } = useTranslation();

  return (
    <div data-testid="usage" className="ticket-usage-area-main">
      <div className="flex-col">
        <div className="usage">
          <span className="usage-title">{t('upload.usage')}</span>
          <span className="usage-value">{formatSec(usageValue)}</span>
        </div>
        <div className={remainValue >= usageValue ? 'remain-value-black' : 'remain-value-red'}>
          {t('upload.remain')}:&emsp;
          {remainValue >= usageValue ? formatSec(remainValue - usageValue) : '-' + formatSec(usageValue - remainValue)}
        </div>
      </div>
      <TicketRemainBar usageValue={usageValue} remainValue={remainValue} />
      <ToolTip
        data-testid="tooltip"
        open={remainValue < usageValue}
        text={t('upload.chargeAlert')}
        placement="bottom"
        defaultMode={false}
      >
        <div></div>
      </ToolTip>
    </div>
  );
};
