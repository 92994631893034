import {
  doc,
  collection,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  where,
  DocumentSnapshot,
  DocumentReference,
  CollectionReference,
  Query,
  QuerySnapshot,
  DocumentData,
} from 'firebase/firestore';

import { db } from '@/services/firebase';
import { COLLECTION } from '@/utils/constants';

import {
  settingsConverter,
  dstLangsConverter,
  uploadsConverter,
  prevsConverter,
  transConverter,
  productsConverter,
  plansConverter,
  plansLocalizationConverter,
  usersConverter,
  contractsConverter,
  chargesConverter,
} from './converter';
import {
  Settings,
  DstLangs,
  Uploads,
  Prevs,
  Trans,
  Products,
  Plans,
  PlansLocalization,
  Users,
  Contracts,
  Charges,
  SettingData,
  DstLangData,
  UploadData,
  PrevData,
  TranData,
  ProductData,
  PlanData,
  PlansLocalizationData,
  UserData,
  ContractData,
  ChargeData,
} from './entity';

/**
 * 翻訳設定（settings）ドキュメント（参照）
 */
export const docSetting = (settingId: string): DocumentReference<Settings, DocumentData> => {
  return doc(db, COLLECTION.SETTINGS, settingId);
};

/**
 * 翻訳設定（settings）ドキュメントを取得
 */
export const selectSetting = (settingId: string): Promise<DocumentSnapshot<SettingData, DocumentData>> => {
  const docSettingSnap: Promise<DocumentSnapshot<SettingData, DocumentData>> = getDoc(
    docSetting(settingId).withConverter(settingsConverter)
  );
  return docSettingSnap;
};

/**
 * 翻訳設定（settings）ドキュメントを登録（ID指定無し）
 */
export const insertSetting = async (settingData: Settings): Promise<DocumentReference> => {
  const docSettingRef = await addDoc(colSettings(), settingData);
  return docSettingRef;
};

/**
 * 翻訳設定（settings）ドキュメントを更新
 */
export const updateSetting = async (settingId: string, settingData: Settings) => {
  await updateDoc(docSetting(settingId), settingData);
};

/**
 * 翻訳設定（settings）コレクション（参照）
 */
export const colSettings = (): CollectionReference<Settings, DocumentData> => {
  return collection(db, COLLECTION.SETTINGS);
};

/**
 * 翻訳設定（settings）クエリ（参照）
 */
const querySettings = (userId: string): Query<SettingData> => {
  return query(colSettings().withConverter(settingsConverter), where('user_id', '==', userId));
};

/**
 * 翻訳設定（settings）コレクションをuserId指定で取得
 */
export const selectSettingsByUserId = (userId: string): Promise<QuerySnapshot<SettingData, DocumentData>> => {
  const colSettingsSnap = getDocs(querySettings(userId));
  return colSettingsSnap;
};

/**
 * 翻訳先言語（dst_langs）ドキュメント（参照）
 */
const docDstLang = (settingId: string, dstLang: string): DocumentReference<DstLangs, DocumentData> => {
  return doc(db, COLLECTION.SETTINGS, settingId, COLLECTION.DST_LANGS, dstLang);
};

/**
 * 翻訳先言語（dst_langs）ドキュメントを登録（ID指定）
 */
export const insertDstLang = async (settingId: string, dstLang: string, dstLangData: DstLangs) => {
  await setDoc(docDstLang(settingId, dstLang), dstLangData);
};

/**
 * 翻訳先言語（dst_langs）ドキュメントを削除
 */
export const deleteDstLang = async (settingId: string, dstLang: string) => {
  await deleteDoc(docDstLang(settingId, dstLang));
};

/**
 * 翻訳先言語（dst_langs）コレクション（参照）
 */
const colDstLangs = (settingId: string): CollectionReference<DstLangs, DocumentData> => {
  return collection(db, COLLECTION.SETTINGS, settingId, COLLECTION.DST_LANGS);
};

/**
 * 翻訳先言語（dst_langs）コレクションを取得
 */
export const selectDstLangs = (settingId: string): Promise<QuerySnapshot<DstLangData, DocumentData>> => {
  const colDstLangsSnap = getDocs(colDstLangs(settingId).withConverter(dstLangsConverter));
  return colDstLangsSnap;
};

/**
 * アップロードファイル（uploads）ドキュメント（参照）
 */
const docUpload = (settingId: string, uploadId: string): DocumentReference<Uploads, DocumentData> => {
  return doc(db, COLLECTION.SETTINGS, settingId, COLLECTION.UPLOADS, uploadId);
};

/**
 * アップロードファイル（uploads）ドキュメントを取得
 */
export const selectUpload = (
  settingId: string,
  uploadId: string
): Promise<DocumentSnapshot<UploadData, DocumentData>> => {
  const docUploadSnap = getDoc(docUpload(settingId, uploadId).withConverter(uploadsConverter));
  return docUploadSnap;
};

/**
 * アップロードファイル（uploads）ドキュメントを登録（ID指定無し）
 */
export const insertUpload = async (settingId: string, uploadData: Uploads): Promise<DocumentReference> => {
  const docUploadRef = await addDoc(colUploads(settingId), uploadData);
  return docUploadRef;
};

/**
 * アップロードファイル（uploads）ドキュメントを更新
 */
export const updateUpload = async (settingId: string, uploadId: string, uploadData: Uploads) => {
  await updateDoc(docUpload(settingId, uploadId), uploadData);
};

/**
 * アップロードファイル（uploads）ドキュメントを削除
 */
export const deleteUpload = async (settingId: string, uploadId: string, userId: string) => {
  const uploadQuery = getDocs(
    query(collection(db, COLLECTION.SETTINGS, settingId, COLLECTION.UPLOADS), where('user_id', '==', userId))
  );
  (await uploadQuery).forEach((doc) => {
    if (doc.id === uploadId) {
      deleteDoc(doc.ref);
    }
  });
};

/**
 * アップロードファイル（uploads）コレクション（参照）
 */
export const colUploads = (settingId: string): CollectionReference<Uploads, DocumentData> => {
  return collection(db, COLLECTION.SETTINGS, settingId, COLLECTION.UPLOADS);
};

/**
 * アップロードファイル（uploads）クエリ（参照）
 */
export const queryUploads = (settingId: string, userId: string): Query<UploadData, DocumentData> => {
  return query(
    colUploads(settingId).withConverter(uploadsConverter),
    where('user_id', '==', userId),
    orderBy('upload_name')
  );
};

/**
 * アップロードファイル（uploads）コレクションを取得
 */
export const selectUploads = (settingId: string, userId: string): Promise<QuerySnapshot<UploadData, DocumentData>> => {
  // 削除済でないもの（upload_remove_dateがnullのもの）のみ取得する
  const colUploadsSnap = getDocs(
    query(colUploads(settingId), where('upload_remove_date', '==', null), where('user_id', '==', userId)).withConverter(
      uploadsConverter
    )
  );
  return colUploadsSnap;
};

/**
 * プレビュー翻訳動画（prevs）ドキュメント（参照）
 */
export const docPrev = (
  settingId: string,
  uploadId: string,
  prevId: string
): DocumentReference<Prevs, DocumentData> => {
  return doc(db, COLLECTION.SETTINGS, settingId, COLLECTION.UPLOADS, uploadId, COLLECTION.PREVS, prevId);
};

/**
 * プレビュー翻訳動画（prevs）ドキュメントを登録（ID指定）
 */
export const insertPrev = async (settingId: string, uploadId: string, prevId: string, prevData: Prevs) => {
  await setDoc(docPrev(settingId, uploadId, prevId), prevData);
};

/**
 * プレビュー翻訳動画（prevs）ドキュメントを更新
 */
export const updatePrev = async (settingId: string, uploadId: string, prevId: string, prevData: Prevs) => {
  await updateDoc(docPrev(settingId, uploadId, prevId), prevData);
};

/**
 * プレビュー翻訳動画（prevs）ドキュメントを削除
 */
export const deletePrev = async (settingId: string, uploadId: string, prevId: string) => {
  await deleteDoc(docPrev(settingId, uploadId, prevId));
};

/**
 * プレビュー翻訳動画（prevs）コレクション（参照）
 */
const colPrevs = (settingId: string, uploadId: string): CollectionReference<Prevs, DocumentData> => {
  return collection(db, COLLECTION.SETTINGS, settingId, COLLECTION.UPLOADS, uploadId, COLLECTION.PREVS);
};

/**
 * プレビュー翻訳動画（prevs）クエリ（参照）
 */
export const queryPrevs = (settingId: string, uploadId: string, userId: string): Query<PrevData> => {
  return query(
    colPrevs(settingId, uploadId).withConverter(prevsConverter),
    where('prev_remove_date', '==', null),
    where('user_id', '==', userId)
  );
};

/**
 * プレビュー翻訳動画（prevs）コレクションを取得
 */
export const selectPrevs = (
  settingId: string,
  uploadId: string,
  userId: string
): Promise<QuerySnapshot<PrevData, DocumentData>> => {
  const colPrevsSnap = getDocs(queryPrevs(settingId, uploadId, userId));
  return colPrevsSnap;
};

/**
 * 翻訳動画（trans）ドキュメント（参照）
 */
export const docTran = (
  settingId: string,
  uploadId: string,
  tranId: string
): DocumentReference<Trans, DocumentData> => {
  return doc(db, COLLECTION.SETTINGS, settingId, COLLECTION.UPLOADS, uploadId, COLLECTION.TRANS, tranId);
};

/**
 * 翻訳動画（trans）ドキュメントを取得
 */
export const selectTran = (
  settingId: string,
  uploadId: string,
  tranId: string
): Promise<DocumentSnapshot<TranData, DocumentData>> => {
  const docTranSnap: Promise<DocumentSnapshot<TranData, DocumentData>> = getDoc(
    docTran(settingId, uploadId, tranId).withConverter(transConverter)
  );
  return docTranSnap;
};

/**
 * 翻訳動画（trans）ドキュメントを登録（ID指定）
 */
export const insertTran = async (settingId: string, uploadId: string, tranId: string, tranData: Trans) => {
  await setDoc(docTran(settingId, uploadId, tranId), tranData);
};

/**
 * 翻訳動画（trans）ドキュメントを更新
 */
export const updateTran = async (settingId: string, uploadId: string, tranId: string, tranData: Trans) => {
  await updateDoc(docTran(settingId, uploadId, tranId), tranData);
};

/**
 * 翻訳動画（trans）ドキュメントを削除
 */
export const deleteTrans = async (settingId: string, uploadId: string, tranId: string) => {
  await deleteDoc(docTran(settingId, uploadId, tranId));
};

/**
 * 翻訳動画（trans）コレクション（参照）
 */
const colTrans = (settingId: string, uploadId: string): CollectionReference<Trans, DocumentData> => {
  return collection(db, COLLECTION.SETTINGS, settingId, COLLECTION.UPLOADS, uploadId, COLLECTION.TRANS);
};

/**
 * 翻訳動画（trans）クエリ（参照）
 */
export const queryTrans = (settingId: string, uploadId: string, userId: string): Query<Trans> => {
  return query(colTrans(settingId, uploadId), where('user_id', '==', userId), orderBy('tran_name'));
};

/**
 * 翻訳動画（trans）コレクションを取得
 */
export const selectTrans = (
  settingId: string,
  uploadId: string,
  userId: string
): Promise<QuerySnapshot<TranData, DocumentData>> => {
  // 削除済でないもの（tran_remove_dateがnullのもの）のみ取得する
  const colTransSnap = getDocs(
    query(
      colTrans(settingId, uploadId),
      where('tran_remove_date', '==', null),
      where('user_id', '==', userId)
    ).withConverter(transConverter)
  );
  return colTransSnap;
};

/**
 * 製品（products）コレクション（参照）
 */
export const colProducts = (): CollectionReference<Products, DocumentData> => {
  return collection(db, COLLECTION.PRODUCTS);
};

/**
 * 製品（products）コレクションを取得
 */
export const selectProducts = (): Promise<QuerySnapshot<ProductData, DocumentData>> => {
  const colProductsSnap = getDocs(colProducts().withConverter(productsConverter));
  return colProductsSnap;
};

/**
 * プラン（plans）ドキュメント（参照）
 */
export const docPlan = (productId: string, planId: string): DocumentReference<Plans, DocumentData> => {
  return doc(db, COLLECTION.PRODUCTS, productId, COLLECTION.PLANS, planId);
};

/**
 * プラン（plans）ドキュメントを取得
 */
export const selectPlan = (productId: string, planId: string): Promise<DocumentSnapshot<PlanData, DocumentData>> => {
  const docPlanSnap = getDoc(docPlan(productId, planId).withConverter(plansConverter));
  return docPlanSnap;
};

/**
 * プラン（plans）コレクション（参照）
 */
export const colPlans = (productId: string): CollectionReference<Plans, DocumentData> => {
  return collection(db, COLLECTION.PRODUCTS, productId, COLLECTION.PLANS);
};
/**
 * プラン（plans）コレクションをplanCode指定で取得
 */
export const selectPlansByPlanCode = (
  productId: string,
  planCode: string | undefined
): Promise<QuerySnapshot<PlanData, DocumentData>> => {
  const colPlansSnap = getDocs(
    query(colPlans(productId).withConverter(plansConverter), where('plan_code', '==', planCode))
  );
  return colPlansSnap;
};

/**
 * プラン多言語設定（localization）コレクション（参照）
 */
export const colLocalzations = (
  productId: string,
  planId: string
): CollectionReference<PlansLocalization, DocumentData> => {
  return collection(db, COLLECTION.PRODUCTS, productId, COLLECTION.PLANS, planId, COLLECTION.LOCALIZATION);
};

/**
 * プラン多言語設定（localization）コレクションをlang指定で取得
 */
export const selectLocalizationsByLang = (
  productId: string,
  planId: string,
  lang: string
): Promise<QuerySnapshot<PlansLocalizationData, DocumentData>> => {
  const colLocalzationsSnap = getDocs(
    query(colLocalzations(productId, planId).withConverter(plansLocalizationConverter), where('lang', '==', lang))
  );
  return colLocalzationsSnap;
};

/**
 * ユーザー（users）ドキュメント（参照）
 */
const docUser = (userId: string): DocumentReference<Users, DocumentData> => {
  return doc(db, COLLECTION.USERS, userId);
};

/**
 * ユーザー（users）ドキュメント（参照）
 */
export const colUser = (userId: string): CollectionReference<DocumentData, DocumentData> => {
  return collection(db, COLLECTION.USERS, userId);
};

/**
 * ユーザー（users）ドキュメントを取得
 */
export const selectUser = (userId: string): Promise<DocumentSnapshot<UserData, DocumentData>> => {
  return getDoc(docUser(userId).withConverter(usersConverter));
};

/**
 * ユーザー（users）ドキュメントを登録（ID指定）
 */
export const insertUser = async (userId: string, userData: Users) => {
  await setDoc(docUser(userId), userData);
};

/**
 * ユーザー（users）ドキュメントを更新
 */
export const updateUser = async (userId: string, userData: Users) => {
  await updateDoc(docUser(userId), userData);
};

/**
 * ユーザー（users）コレクション（参照）
 */
export const colUsers = (): CollectionReference<Users, DocumentData> => {
  return collection(db, COLLECTION.USERS);
};

/**
 * 契約プラン（contracts）ドキュメント（参照）
 */
export const docContract = (userId: string, contractId: string): DocumentReference<Contracts, DocumentData> => {
  return doc(db, COLLECTION.USERS, userId, COLLECTION.CONTRACTS, contractId);
};

/**
 * 契約プラン（contracts）ドキュメントを登録（ID指定）
 */
export const insertContract = async (userId: string, contractId: string, contractData: Contracts) => {
  await setDoc(docContract(userId, contractId), contractData);
};

/**
 * 契約プラン（contracts）コレクション（参照）
 */
const colContracts = (userId: string): CollectionReference<Contracts, DocumentData> => {
  return collection(db, COLLECTION.USERS, userId, COLLECTION.CONTRACTS);
};

/**
 * 契約プラン（contracts）クエリ（参照）
 */
export const queryContracts = (userId: string): Query<ContractData> => {
  return query(colContracts(userId).withConverter(contractsConverter), where('user_id', '==', userId));
};

/**
 * チャージ（charges）コレクション（参照）
 */
export const colCharges = (userId: string): CollectionReference<Charges, DocumentData> => {
  return collection(db, COLLECTION.USERS, userId, COLLECTION.CHARGES);
};

/**
 * チャージ（charges）クエリ（参照）
 */
export const queryCharges = (userId: string): Query<ChargeData> => {
  return query(colCharges(userId).withConverter(chargesConverter), where('charge_remain', '>', 0));
};
