import './RadioButton.css';
import { Image } from '@/components/Elements/';

export type RadioButtonItem = {
  id: string;
  name: string;
  image?: string;
  class_name?: string;
};

export type RadioButtonProps = {
  name: string;
  items: RadioButtonItem[];
  checked: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  class_name?: string;
};

export const RadioButton = ({ name, items, onChange, checked, class_name }: RadioButtonProps) =>
  items.map((item: RadioButtonItem) => {
    return (
      <label key={item.id} className={item.class_name} data-testid="radio-value">
        <div className="label-wrap">
          <input type="radio" value={item.id} name={name} onChange={(e) => onChange(e)} checked={checked === item.id} />
          <span className="radio-label">{item.name}</span>
        </div>
        <span className="radio-img">{item.image && <Image src={item.image} />}</span>
      </label>
    );
  });
