import './TicketRemainButton.css';
import { useTranslation } from 'react-i18next';

import { ConvertLangCodeToLocale } from '@/config';
import { PAY } from '@/constants';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { PRODUCT_CODE } from '@/utils/constants';
import { formatSec } from '@/utils/formatUtil';

// import { formatDate, formatSec } from '@/utils/formatUtil';

// チケット残り時間表示ボタンProps
type TicketRemainButtonProps = {
  ticketRemainValue: number; // チケット残り時間
  // TODO：以下次バージョンで対応する
  // recentTicketRemainDate: Date; //直近のチケット有効期限
  // recentTicketRemainValue: number; //直近のチケット残り時間
};

export const TicketRemainButton = ({
  ticketRemainValue,
}: // recentTicketRemainDate,
// recentTicketRemainValue,
TicketRemainButtonProps) => {
  const { t } = useTranslation();
  const { language } = useBrowserUserSetting();

  /**
   * チケット購入エンドポイント
   */
  const PYT_0300_URL = `${PAY.DOMAIN}${PAY.API_URL.PYT_0300}` as string;

  return (
    <div className="ticket-remain-button">
      <a
        href={`${PYT_0300_URL}?productCode=${
          PRODUCT_CODE.POCKETALK_VIDEO_TRANSLATOR
        }&language=${ConvertLangCodeToLocale(language)}`}
        target="_blank"
        className="mvs-buy3"
        rel="noreferrer"
      >
        <div className="mvs-buy2">
          <div className="ticket-info">
            <div className="mvs-buy1">
              <p>{t('common.purchasedTimeRemaining', { remain: formatSec(ticketRemainValue) })}</p>
              {/* <p>
              {t('common.untilValid', {
                date: formatDate(recentTicketRemainDate),
                remain: formatSec(recentTicketRemainValue),
              })}
            </p> */}
            </div>
            {/* <div className="expiration-date">tbd_UntilvalidtoLocaleDateStringhh:mm:ss</div> */}
          </div>
        </div>
      </a>
    </div>
  );
};
