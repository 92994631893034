import { Button, useSubtitlesRow, Image } from '@/components/Elements/';
import { Subtitle } from '@/features/editor';
import './SubtitlesRow.css';
import clock from '@/img/clock.svg';

// 字幕情報の行Props
export type SubtitlesRowProps = {
  index: number;
  translateFrom: string;
  disableTranslateButton: boolean;
  translateTo: string[];
  startTime: number[];
  endTime: number[];
  disabledTranslateFrom: boolean;
  readonlyClickApply: boolean;
  judgeData: () => void;
  changeData: (e: any) => void;
  translateData: (index: number) => Promise<Subtitle | undefined>;
};

export const SubtitlesRow = ({
  index,
  translateFrom,
  disableTranslateButton,
  translateTo,
  startTime,
  endTime,
  disabledTranslateFrom,
  readonlyClickApply,
  judgeData,
  changeData,
  translateData,
}: SubtitlesRowProps) => {
  const {
    t,
    translateFromText,
    translateToText,
    startText,
    endText,
    disableTranslate,
    readonlyClickTranslate,
    onChange,
    onBlur,
    onBlurTranslateFrom,
    clickTranslateButton,
  } = useSubtitlesRow(
    index,
    translateFrom,
    translateTo,
    startTime,
    endTime,
    disableTranslateButton,
    judgeData,
    changeData,
    translateData
  );
  return (
    <>
      <div data-testid="subtitlesRow" className="subtitle-row">
        <div className="subtitle-row-group">
          <div className="subtitle-row-col1-group">
            <div className="subtitle-row-col1">
              <textarea
                data-testid="textarea"
                className="translateFrom"
                id={String('translateFrom_' + index)}
                onChange={onChange}
                onBlur={onBlurTranslateFrom}
                value={translateFromText}
                readOnly={readonlyClickApply || readonlyClickTranslate}
                disabled={disabledTranslateFrom}
              ></textarea>
            </div>
            <div className="editor-translation-button">
              {!disabledTranslateFrom && (
                <Button
                  label={t('editor.translate')}
                  disabled={disableTranslate}
                  onClick={clickTranslateButton}
                  class_name="translate"
                />
              )}
            </div>
          </div>
          <div className="subtitle-row-col2-group">
            {translateToText.map((item, itemIndex) => {
              return (
                <div className="subtitle-row-detail" key={itemIndex}>
                  <div className="subtitle-row1-col">
                    <textarea
                      data-testid="textarea"
                      id={String('translateTo_' + index + '_' + itemIndex)}
                      onChange={onChange}
                      onBlur={() => onBlur(index, itemIndex)}
                      value={item}
                      readOnly={readonlyClickApply || readonlyClickTranslate}
                    ></textarea>
                  </div>
                  <div className="subtitle-row2">
                    <div className="subtitle-row2-col">
                      <Image src={clock as string} />
                    </div>
                    <div className="subtitle-row2-col">
                      <input
                        data-testid="startTime"
                        id={String('startTime_' + index + '_' + itemIndex)}
                        type="text"
                        value={startText[itemIndex]}
                        onBlur={() => onBlur(index, itemIndex)}
                        onChange={onChange}
                        readOnly={readonlyClickApply || readonlyClickTranslate}
                        pattern="[0-9:\.]*$"
                        maxLength={13}
                      />
                    </div>
                    <div className="subtitle-row2-col-mid">ー</div>
                    <div className="subtitle-row2-col">
                      <input
                        data-testid="endTime"
                        id={String('endTime_' + index + '_' + itemIndex)}
                        type="text"
                        value={endText[itemIndex]}
                        onBlur={() => onBlur(index, itemIndex)}
                        onChange={onChange}
                        readOnly={readonlyClickApply || readonlyClickTranslate}
                        pattern="[0-9:\.]*$"
                        maxLength={13}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
