import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { browserUserSettingSlice } from './slices/browserUserSettingSlice';

const persistConfig = {
  // storeに保存されるkey名
  key: 'root',
  // local storageに保存
  storage,
  // storageに保存するリスト
  whitelist: [browserUserSettingSlice.name],
  // マイグレーション用
  version: 1,
  debug: true,
};

const rootReducer = combineReducers({
  [browserUserSettingSlice.name]: browserUserSettingSlice.reducer,
});

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, rootReducer);

// https://github.com/rt2zz/redux-persist/issues/988
// こちらを参考に「persist」キーの「serializable」のチェックを無視
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
