import { useTranslation } from 'react-i18next';

import { SCREEN_NAME_TYPES } from '@/features/commonArea';

import './StepIndicator.css';

type StepIndicatorProps = {
  screenName: SCREEN_NAME_TYPES;
};

export const StepIndicator = ({ screenName }: StepIndicatorProps) => {
  const { t } = useTranslation();
  return (
    <ul data-testid="stepIndicator" className="step-indicator">
      <li data-testid="selectFiles" className={screenName === SCREEN_NAME_TYPES.SELECT_FILES ? 'active' : 'complete'}>
        {t('common.select')}
      </li>
      <li
        data-testid="settings"
        className={
          screenName === SCREEN_NAME_TYPES.SELECT_FILES
            ? ''
            : screenName === SCREEN_NAME_TYPES.SETTINGS
            ? 'active'
            : 'complete'
        }
      >
        {t('common.settings')}
      </li>
      <li data-testid="upload" className={screenName === SCREEN_NAME_TYPES.UPLOAD ? 'active' : ''}>
        {t('common.upload')}
      </li>
      <li data-testid="translate">{t('common.translation')}</li>
    </ul>
  );
};
