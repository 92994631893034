import { useMemo } from 'react';

import { calcRemainSec, calcRemainDhm, RemainDhmProps } from '@/utils/formatUtil';

export const useTranslateFromRow = (prevProgress: number, elapsedTime: number, uploadProgress: number) => {
  const uploadRemainDhm = useMemo((): RemainDhmProps => {
    return calcRemainDhm(calcRemainSec(elapsedTime, uploadProgress));
  }, [elapsedTime, uploadProgress]);
  const prevRemainDhm = useMemo((): RemainDhmProps => {
    return calcRemainDhm(calcRemainSec(elapsedTime, prevProgress));
  }, [elapsedTime, prevProgress]);

  return {
    uploadRemainDhm,
    prevRemainDhm,
  };
};
