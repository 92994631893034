import { GENERATE_TRANSLATION_ZIP_API_URL } from '@/config';
import { axios } from '@/lib/axios';

/** 翻訳結果zip生成API リクエストパラメータ */
export type GenerateTranslationZipApiRequest = {
  prev_flg: boolean;
  user_id: string;
  upload_id: string;
  dest_lang?: string;
  setting_id: string;
};

/**
 * 翻訳結果zip生成API  結果コード定数
 */
export const GENERATE_TRANSLATION_ZIP_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // Firestoreから翻訳動画情報が取得できない
  WARN_NO_DATA: 'WARN_NO_DATA',
  // 指定されたファイルが見つからない
  WARN_NO_FILE: 'WARN_NO_FILE',
  // zipファイル生成エラー
  WARN_CREATE_FILE: 'WARN_CREATE_FILE',
  // Cloud Storageへのファイルアップロードエラー
  WARN_UPLOAD_FILE: 'WARN_UPLOAD_FILE',
  // Firestoreデータ更新エラー
  WARN_UPDATE_DATA: 'WARN_UPDATE_DATA',
  // 署名付きURL発行エラー
  WARN_CREATE_SIGNED_URL: 'WARN_CREATE_SIGNED_URL',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
export type GenerateTranslationZipApiResultCode =
  (typeof GENERATE_TRANSLATION_ZIP_API_RESULT_CODE)[keyof typeof GENERATE_TRANSLATION_ZIP_API_RESULT_CODE];

/** 翻訳結果zip生成API レスポンス */
export type GenerateTranslationZipApiResponse = {
  resultCode: GenerateTranslationZipApiResultCode;
  zipUrl: string;
};

export const generateTranslationZipApi = (
  request: GenerateTranslationZipApiRequest
): Promise<GenerateTranslationZipApiResponse> => {
  let jsonParams;
  if (request.prev_flg) {
    jsonParams = {
      prevFlg: request.prev_flg,
      userId: request.user_id,
      uploadId: request.upload_id,
      settingId: request.setting_id,
    };
  } else {
    jsonParams = {
      prevFlg: request.prev_flg,
      userId: request.user_id,
      uploadId: request.upload_id,
      destLang: request.dest_lang,
      settingId: request.setting_id,
    };
  }
  const response = axios
    .post<GenerateTranslationZipApiResponse>(GENERATE_TRANSLATION_ZIP_API_URL, jsonParams, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      timeout: 600000,
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};
