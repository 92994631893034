import { useCallback, useEffect } from 'react';

type Props = {
  // Enterキーが押下された際の処理
  onEnterKeyDown: () => void;
  // Escキーが押下された際の処理
  onEscapeKeyDown: () => void;
};

// 検知したいイベントキー名
const EVENT_KEY_NAME = 'keydown';

/**
 * キー押下を検知 カスタムフック
 *
 * @returns
 */
export const useKeyDown = ({ onEnterKeyDown, onEscapeKeyDown }: Props) => {
  /**
   * キー押下を検知した際の処理
   *
   * @param event
   */
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      // Enterキーが押下された
      if (event.key === 'Enter') {
        onEnterKeyDown();
      }
      // Escキーが押下された
      if (event.key === 'Escape') {
        onEscapeKeyDown();
      }
    },
    [onEnterKeyDown, onEscapeKeyDown]
  );

  useEffect(() => {
    // リスナー登録
    document.addEventListener(EVENT_KEY_NAME, handleKeyDown, false);

    // クリーンアップ関数で、リスナー削除
    return () => {
      document.removeEventListener(EVENT_KEY_NAME, handleKeyDown);
    };
  }, [handleKeyDown]);
};
