import { useTranslation } from 'react-i18next';

import { formatFileTypes, byteToGigaByte, secToMin } from '@/utils/formatUtil';

export const useVideoInfoToolTip = (
  limitType: string[],
  limitSize: number,
  limitDuration: number,
  limitHeight: string,
  limitWidth: string
) => {
  const { t } = useTranslation();

  const text = t('common.uploadConditions', {
    limitType: formatFileTypes(limitType),
    limitSize: byteToGigaByte(limitSize),
    limitDuration: secToMin(limitDuration),
    limitHeight: limitHeight,
    limitWidth: limitWidth,
  });

  const breakedText = text.split('\n').map((line, key) => (
    <span key={key}>
      {line}
      <br />
    </span>
  ));

  return {
    t,
    breakedText,
  };
};
