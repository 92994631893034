import './LangMenuButton.css';
import { useLangMenuButton, Image } from '@/components/Elements/';
import { TranslateToInfoTypes } from '@/components/Form/VoiceTypeSettingsDialog';
import tttEn from '@/config/ttt/ttt.en.json';
import tttJa from '@/config/ttt/ttt.ja.json';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useLangList } from '@/hooks/useLangList';
import expandMore from '@/img/expand_more.svg';
import { formatlangLabel } from '@/utils/formatUtil';

export type LangMenuButtonProps = {
  translateToValue: TranslateToInfoTypes[];
};

export const LangMenuButton = ({ translateToValue }: LangMenuButtonProps) => {
  const { language } = useBrowserUserSetting();
  const { langList } = useLangList(language, tttEn, tttJa);
  const { onClickMenu, menuLabel, openMenu } = useLangMenuButton(translateToValue);
  return (
    <>
      {translateToValue.length > 0 && (
        <label data-testid="langMenuButton" className="settings-lang-label-block">
          {formatlangLabel(translateToValue[0]?.langCode, langList)}
        </label>
      )}
      {translateToValue.length > 0 && (
        <button
          className={translateToValue.length > 2 ? 'settings-lang-button-block' : 'settings-lang-none'}
          type="button"
          onClick={() => onClickMenu()}
        >
          <div className="settings-lang-button-lebel">{menuLabel}</div>
          {openMenu ? (
            <div className="settings-lang-button-close">
              <Image src={expandMore as string} />
            </div>
          ) : (
            <div className="settings-lang-button-open">
              <Image src={expandMore as string} />
            </div>
          )}
        </button>
      )}
      {translateToValue.length > 0 && (
        <div className="settings-lang-row">
          {translateToValue?.map((item: TranslateToInfoTypes, index) => {
            if (index !== 0) {
              return (
                <label
                  data-testid="langMenuButton"
                  key={index}
                  className={
                    openMenu || translateToValue.length === 2 ? 'settings-lang-label-block' : 'settings-lang-none'
                  }
                >
                  {formatlangLabel(item.langCode, langList)}
                </label>
              );
            }
          })}
        </div>
      )}
    </>
  );
};
