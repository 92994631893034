import * as React from 'react';

import { FlashMessage } from '@/components/Elements/';

/** FlashMessageコンテキストオブジェクトの型 */
export type FlashMessageContextType = {
  message: string;
  hiddenDuration: number;
  showFlashMessage: (message: string, hiddenDuration?: number) => void;
};

/** フラッシュメッセージの表示状態を管理するコンテキストオブジェクト */
export const FlashMessageContext = React.createContext<FlashMessageContextType>({
  message: '', // デフォルト値
  hiddenDuration: 0,
  showFlashMessage: (_message: string, _hiddenDuration?: number) => {
    _message;
    _hiddenDuration;
  }, // ダミー関数
});

/**
 * コンテキストオブジェクトを提供するコンポーネント
 *
 * このコンポーネント以下に配置した子コンポーネントで
 * useFlashMessageを呼び出すことができる
 */
export const FlashMessageProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const context: FlashMessageContextType = React.useContext(FlashMessageContext);
  const [message, setMessage] = React.useState(context.message);
  const [hiddenDuration, setHiddenDuration] = React.useState(6000);

  // コンテクストオブジェクトに自分自身の値を変更する関数をセットする
  const newContext: FlashMessageContextType = React.useMemo(
    () => ({
      message,
      hiddenDuration,
      showFlashMessage: (message: string, hiddenDuration?: number) => {
        setMessage(message);

        if (hiddenDuration) {
          setHiddenDuration(hiddenDuration);
        } else setHiddenDuration(6000);
      },
    }),
    [message, setMessage, hiddenDuration]
  );

  // FlashMessageクローズ用のハンドラー関数
  const handleClose = React.useCallback(() => {
    setMessage('');
  }, [setMessage]);

  return (
    <FlashMessageContext.Provider value={newContext}>
      {children}
      <FlashMessage
        open={newContext.message !== ''}
        message={newContext.message}
        hiddenDuration={newContext.hiddenDuration}
        onClose={handleClose}
      />
    </FlashMessageContext.Provider>
  );
};

/** FlashMessageContextのユーティリティ関数 */
export function useFlashMessage(): FlashMessageContextType {
  return React.useContext(FlashMessageContext);
}
