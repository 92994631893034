import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddFileButton, Image, TextBlock } from '@/components/Elements';
// import movie from '@/img/movie.svg';
import movie from '@/img/movie.svg';
import { formatAcceptTypesByPicker } from '@/utils/formatUtil';

import './DragDropAreaSafari.css';

// TODO背景色定義
const backgroundColors = {
  default: 'bg-white',
  dragging: 'bg-purple-400',
  uploadError: 'bg-red-200',
};

// Drag&DropエリアProps
export type DragDropAreaSafariProps = {
  cntFiles: number;
  acceptTypes: string[];
  backgroundColor?: keyof typeof backgroundColors;
  buttonDisabled: boolean;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const DragDropAreaSafari = ({
  cntFiles,
  acceptTypes,
  backgroundColor = 'default',
  buttonDisabled,
  onSelect,
}: DragDropAreaSafariProps) => {
  const { t } = useTranslation();
  // アップロード制限チェックフラグ(true:チェックOK、false:チェックNG)
  const [canUpload, setCanUpload] = useState(false);
  const [isDragActive, setDragActive] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
  const dragEnter = () => {
    setDragActive(true);
  };

  const dragLeave = () => {
    setDragActive(false);
  };

  useEffect(() => {
    if (isDragActive) {
      // Drag&Dropエリアにドラッグ中の場合
      document.getElementsByClassName('drag-drop-zone-safari')[0].classList.add('drag');
    } else {
      // ドラッグ後、Drag&Dropエリア外に出た背景色を元に戻すためクラス名を削除
      document.getElementsByClassName('drag-drop-zone-safari')[0].classList.remove('drag', 'caution');

      if (acceptedFiles.length > cntFiles) {
        // Drag&Dropエリアにドロップしたファイルが制限を超える場合
        document.getElementsByClassName('drag-drop-zone-safari')[0].classList.add('caution');
        setCanUpload(false);
      } else {
        // Drag&Dropエリアにドロップしたファイルが制限を超えない場合
        setCanUpload(true);
      }
    }
  }, [acceptedFiles, acceptedFiles.length, backgroundColor, cntFiles, isDragActive]);

  return (
    <div
      className={clsx('drag-drop-zone-safari', backgroundColors[backgroundColor])}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={dragLeave}
    >
      <div className="drag-drop-cont-wrap">
        <input
          data-testid="get-input-safari"
          type="file"
          onChange={(e) => {
            onSelect(e);
            if (e.currentTarget.files) {
              const saveFiles: File[] = [];
              for (const file of e.currentTarget.files) {
                saveFiles.push(file);
              }
              setAcceptedFiles(saveFiles);
            }
          }}
          onClick={(e) => e.preventDefault()}
          accept={formatAcceptTypesByPicker(acceptTypes)}
          multiple
        />
        <div className="vertical-10">
          <div className="drag-drop-image">
            <Image src={movie as string} />
          </div>
          <div>
            <div className="inner-label">{t('selectFiles.uploadTitle')}</div>
          </div>
          <div>
            <div className="inner-label-sub">{t('selectFiles.uploadTitleOr')}</div>
          </div>
        </div>

        <AddFileButton
          label={t('selectFiles.addFile')}
          acceptType={acceptTypes}
          disabled={buttonDisabled}
          onChange={(e) => onSelect(e)}
        />
        {canUpload ? (
          <div className="inner-label-info">
            <TextBlock
              text={t('selectFiles.uploadDetail').replace('${cntFiles}', cntFiles as unknown as string)}
              size="md"
            />
          </div>
        ) : (
          <div className={clsx('mt-5')}>
            <TextBlock
              text={t('selectFiles.uploadErrorDetail').replace('${cntFiles}', cntFiles as unknown as string)}
              size="md"
              variant="alert"
            />
            <TextBlock text={t('selectFiles.uploadErrorMessage')} size="md" variant="alert" />
          </div>
        )}
      </div>
    </div>
  );
};
