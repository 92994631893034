import { FileWithPath } from 'react-dropzone';

export type VideoProps = {
  file: fileInfo;
  hidden?: false | true;
  chromiumBrowser?: false | true;
  handleLoadedData: (e: any) => void;
};

// 動画に表示するファイル型
type fileInfo = {
  file?: FileWithPath;
  url?: string;
  duration?: number;
  videoHeight?: number;
  videoWidth?: number;
};

export const Video = ({ file, hidden = false, chromiumBrowser = true, handleLoadedData }: VideoProps) => {
  if (chromiumBrowser) {
    // Chromiumの場合
    return (
      <video data-testid="video" muted src={file.url} controls onLoadedData={handleLoadedData} hidden={hidden}></video>
    );
  }
  // Chromium以外の場合
  return (
    <video
      data-testid="video"
      muted
      src={file.url}
      controls
      playsInline
      onLoadedData={handleLoadedData}
      hidden={hidden}
    ></video>
  );
};
