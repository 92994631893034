import { CALL_WORKFLOWS_API_URL } from '@/config';
import { axios } from '@/lib/axios';

/** Workflows実行API リクエストパラメータ */
export type CallWorkflowsApiRequest = {
  uploadFileId: string;
  destLangs: string[];
  userId: string;
  settingId: string;
  dstSubtitle: string;
  previewFlg: boolean;
  mode: string;
};

/**
 * Workflows実行API  結果コード定数
 */
export const CALL_WORKFLOWS_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
export type CallWorkflowsApiResultCode =
  (typeof CALL_WORKFLOWS_API_RESULT_CODE)[keyof typeof CALL_WORKFLOWS_API_RESULT_CODE];

/** Workflows実行API レスポンス */
export type CallWorkflowsApiResponse = {
  resultCode: CallWorkflowsApiResultCode;
};

export const callWorkflowsApi = (request: CallWorkflowsApiRequest): Promise<CallWorkflowsApiResponse> => {
  const jsonParams = {
    uploadFileId: request.uploadFileId,
    destLangs: request.destLangs,
    userId: request.userId,
    settingId: request.settingId,
    dstSubtitle: request.dstSubtitle,
    previewFlg: request.previewFlg,
    mode: request.mode,
  };

  const response = axios
    .post<CallWorkflowsApiResponse>(CALL_WORKFLOWS_API_URL, jsonParams, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};
