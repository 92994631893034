import { useEffect } from 'react';

export const useTicketRemainBar = (usageValue: number, remainValue: number) => {
  useEffect(() => {
    // ファイル合計時間が変わるたび計算
    calculateRatio(usageValue, remainValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usageValue, remainValue]);

  // チケット残り時間割合計算処理
  const calculateRatio = (usage: number, remain: number): void => {
    // 初期値
    let remainWidth = 100;

    // チケット残り時間0、使用時間0の場合
    if (remain === 0 && usage === 0) {
      remainWidth = remain;
    }

    // チケット残り時間が正の値である場合
    if (remain >= usage && remain > 0) {
      remainWidth = remainWidth - (usage / remain) * 100;
    }

    // チケット残り時間が負の値である場合
    if (remain - usageValue < 0) {
      remainWidth = 0;
    }

    // styleに設定
    document.documentElement.style.setProperty('--remain-width', `${remainWidth}%`);
  };

  return {
    usageValue,
    remainValue,
  };
};
