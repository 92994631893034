import { VIDEO_TRANSLATOR } from '@/constants';

/**
 * ログアウトAPI(リダイレクト用) リクエスト
 *
 * @returns
 */
export const logoutRedirectApi = (mode: string) => {
  // GETでアクセス(ブラウザアクセスと一緒)
  window.open(`${VIDEO_TRANSLATOR.DOMAIN}${VIDEO_TRANSLATOR.API_URL.LOGOUT_REDIRECT}?mode=${mode}`, '_self');
};
