import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * URLパラメータ操作するためのhooks
 */
export const useUrlParameter = () => {
  const { search } = useLocation();

  /**
   * URLパラメータから指定した値を取得
   *
   * @param key 取得したい値のキー名
   *
   * @returns URLパラメータから取得した値
   */
  const findParams = useCallback(
    (key: string): string => {
      const query = new URLSearchParams(search);
      if (query) {
        const value = query.get(key);
        if (!value) {
          return '';
        }

        return decodeURI(value);
      }

      return '';
    },
    [search]
  );

  return {
    findParams, // URLパラメータから指定した値を取得
  };
};
