export * from './api';
export * from './cookie';

/**
 * APIリクエストのタイムアウト時間
 */
export const DEFAULT_API_TIMEOUT = 30000 as const;

/**
 * 入力元/出力元デバイスの初期値
 */
export const DEFAULT_DEVICE = 'default' as string;

/**
 * 入力制限byte数
 */
export const limitByte = 600;

/**
 * ページURL
 */
export const PAGE_ROUTE_PATH = {
  // ログイン画面
  WELCOME: '/login',
  // ようこそ画面(サインアップ用)
  SIGNUP: '/signup',
  // 利用規約画面
  EULA: '/root',
  // 動画アップロード画面
  SELECTFILES: '/selectFiles',
  // 翻訳設定画面
  SETTINGS: '/settings',
  // アップロードファイル一覧画面
  UPLOAD: '/upload',
  // 翻訳ファイル一覧画面
  TRANSLATION: '/translation',
  // 字幕編集画面
  EDITOR: '/editor',
  // 認証エラー画面
  AUTH_ERROR: '/auth/error',
  // その他（404画面）
  NOTFOUND: '*',
} as const;

/**
 * URLパラメータに指定されるKey
 */
export const URL_PARAMS_KEY = {
  // ログイン画面
  WELCOME: {
    path: 'path', // 画面パス
  },
  // 全画面
  lang: 'lang', // 利用言語
} as const;

/**
 * URLパラメータのValue
 */
export const URL_PARAMS_VALUE = {
  // ログアウト時のモード
  LOGOUT_MODE: {
    NORMAL: '0', // 通常ログアウト
    ERROR: '1', // AuthError画面からのログアウト
  },
} as const;

/**
 * ポケトークムービー翻訳製品ページ
 */
export const POCKETALK_URL = 'https://pocketalk.jp/forbusiness/videotranslation' as string;

/**
 * ポケトークムービー翻訳ヘルプページ
 */
export const FAQ_URL = `${POCKETALK_URL}#faq` as string;

/**
 * ポケトークムービー翻訳利用規約ページ
 */
export const AGREEMENT_URL = 'https://pocketalk.co.jp/rule/videotranslation' as string;

/**
 * ポケトークムービーフィードバックページ
 */
export const FEEDBACK_URL = `https://docs.google.com/forms/${process.env.REACT_APP_FEEDBACK}` as string;

/**
 * ポケトークプライバシーポリシーページ
 */
export const PRIVACY_POLICY_URL = 'https://pocketalk.co.jp/privacypolicy' as string;

/**
 * 利用規約取得元ページ
 */
export const EULA_HTML = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_EULA}/eula.html` as string;

/**
 * サンプル動画ダウンロードURL
 */
export const SAMPLE_DOWNLOAD_URL = `${process.env.REACT_APP_SAMPLE_DOWNLOAD_URL}` as string;
