import { Image, TextBlock } from '@/components/Elements/';
import flimstrip from '@/img/flimstrip.svg';
import { formatSec } from '@/utils/formatUtil';
import './SubtitlesInfo.css';

// 動画情報Props
export type SubtitlesInfoProps = {
  thumbnail: string;
  length: number;
};

export const SubtitlesInfo = ({ thumbnail, length }: SubtitlesInfoProps) => {
  return (
    <>
      <div className="subtitlesInfo-area">
        <div className="subtitlesInfo-area-upper">
          <Image src={thumbnail} />
          <div className="subtitlesInfo-movie-length">
            <Image src={flimstrip as string} />
            <TextBlock text={length > 0 ? formatSec(length) : ''} align="left" />
          </div>
        </div>
      </div>
    </>
  );
};
