import { useTranslation } from 'react-i18next';

import { TextBlock } from '@/components/Elements/';
import { formatSec } from '@/utils/formatUtil';

import './TranslationInfoArea.css';

// 翻訳情報エリアProps
type TranslationInfoAreaProps = {
  fileTotalSec: number; //「ファイルの長さ」値
  langCnt: number; //「言語数」値
};

export const TranslationInfoArea = ({ fileTotalSec, langCnt }: TranslationInfoAreaProps) => {
  const { t } = useTranslation();
  return (
    <div data-testid="translationInfoArea" className="translationInfoArea-main _top-border">
      <div data-testid="filesTotalLength" className="translationInfoArea-row">
        <div className="translationInfoArea-left">
          <TextBlock text={t('settings.filesTotalLength')} align="left" />
        </div>
        <div className="translationInfoArea-right">
          <TextBlock text={formatSec(fileTotalSec)} align="right" />
        </div>
      </div>
      <div data-testid="languages" className="translationInfoArea-row">
        <div className="translationInfoArea-left">
          <TextBlock text={t('settings.languages')} align="left" />
        </div>
        <div className="translationInfoArea-right">
          <TextBlock text={langCnt + ' ' + t('settings.languagesWithNumber')} align="right" />
        </div>
      </div>
      <div data-testid="totalDescription" className="translationInfoArea-row">
        <div className="translationInfoArea-left mod-desc">
          <TextBlock text={t('settings.total')} align="left" />
          <TextBlock text={t('settings.totalDescription')} align="left" />
        </div>
        <div className="translationInfoArea-right">
          <TextBlock text={formatSec(langCnt * fileTotalSec)} align="right" />
        </div>
      </div>
    </div>
  );
};
