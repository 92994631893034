import {
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  listAll,
  UploadTask,
  StorageReference,
  ListResult,
} from 'firebase/storage';
import { FileWithPath } from 'react-dropzone';

import { storage } from '@/services/firebase';

/**
 * アップロードタスク
 */
export const uploadFileObject = (path: string, fileObject: FileWithPath | Blob): UploadTask => {
  return uploadBytesResumable(getStorage(path), fileObject);
};

/**
 * 参照パスの取得
 */
const getStorage = (path: string): StorageReference => {
  return ref(storage, path);
};

/**
 * ファイルURLの取得
 */
export const getUrl = (path: string): Promise<string> => {
  return getDownloadURL(getStorage(path));
};

/**
 * 対象パス下のファイルを全て取得
 */
export const getAllFiles = (path: string): Promise<ListResult> => {
  return listAll(getStorage(path));
};

/**
 * ファイルの削除
 */
export const deleteFileObject = (reference: StorageReference): Promise<void> => {
  return deleteObject(reference);
};
