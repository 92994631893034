/**
 * JWT形式の文字列をパースしてペイロードを取得
 *
 * @param token
 * @returns
 */
export const parseJwt = (token: string) => {
  if (!token) {
    return '';
  }
  const tokenArray = token.split('.');
  if (tokenArray.length < 2) {
    return '';
  }
  const base64Url = tokenArray[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
};
