import { useTranslateToRow, Image, TextEllipsis } from '@/components/Elements/';
import { CommonDialog, COMMON_DIALOG_TYPES, VideoPreviewDialog } from '@/components/Form';
import tttEn from '@/config/ttt/ttt.en.json';
import tttJa from '@/config/ttt/ttt.ja.json';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useLangList } from '@/hooks/useLangList';
import playCircle from '@/img/play_circle.svg';
import { formatlangLabel } from '@/utils/formatUtil';

import './TranslateToRow.css';

// 翻訳先ファイルの行Props
export type TranslateToRowProps = {
  fileId: string;
  langCode: string;
  prevThumbnail: string;
  subtitle: string;
  videoUrl: string;
};

export const TranslateToRow = ({ langCode, prevThumbnail, subtitle, videoUrl }: TranslateToRowProps) => {
  const { language } = useBrowserUserSetting();
  const { langList } = useLangList(language, tttEn, tttJa);
  const {
    videoPreviewDialogOpen,
    commonDialogOpen,
    openVideoPreviewDialog,
    handleCommonDialogClose,
    handleVideoPreviewDialogClose,
  } = useTranslateToRow();
  return (
    <>
      <div data-testid="translateToRow" className="translate-to-row">
        <div className="translate-to-row-col1 ">
          <Image src={prevThumbnail} />
          <div className="play-button">
            <button onClick={() => openVideoPreviewDialog()}>
              <Image src={playCircle as string} />
            </button>
          </div>
        </div>
        <div className="translate-to-row-col2">
          <label className="upload-lang-label">{formatlangLabel(langCode, langList)}</label>
        </div>
        <div className="translate-to-row-col3">
          <TextEllipsis text={subtitle} />
        </div>
      </div>
      <CommonDialog
        open={commonDialogOpen}
        onClose={handleCommonDialogClose}
        title=""
        message=""
        dialogType={COMMON_DIALOG_TYPES.DIALOG_ALERT}
      />
      <VideoPreviewDialog open={videoPreviewDialogOpen} videoUrl={videoUrl} onClose={handleVideoPreviewDialogClose} />
    </>
  );
};
