import { useEffect, useLayoutEffect, useState } from 'react';

import useLoadFile, { FILE_LOAD_STATUS } from '@/hooks/useLoadFile';

type License = {
  // モジュール名
  moduleName: string;
  // ライセンス名
  license: string;
  // リポジトリーのURL
  repository: string;
};

/**
 * ライセンス一覧を読み込んで表示 hooks
 *
 * @returns
 */
export const useLoadLicenseList = () => {
  const { state, loadFile } = useLoadFile();
  const [licenseList, setLicenseList] = useState<License[]>([]);

  // レンダリング前に読み込む
  useLayoutEffect(() => {
    loadFile(`${process.env.PUBLIC_URL}/license.csv`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 読み込んだファイルのテキストデータを監視
  useEffect(() => {
    if (state.status === FILE_LOAD_STATUS.SUCCESS && state.text) {
      const newLicenseList = state.text
        .split('\n')
        .map((row) => {
          const dataList = row.split(',').map((data) => data.replaceAll('"', ''));

          return {
            moduleName: dataList[0],
            license: dataList[1],
            repository: dataList[2],
          } as License;
        })
        .splice(1);

      setLicenseList(newLicenseList);
    }
  }, [state]);

  return { state, licenseList };
};
