import clsx from 'clsx';
import { Timestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

import { TextBlock, TextEllipsis, ProgressBar, Image, Button } from '@/components/Elements';
import tttEn from '@/config/ttt/ttt.en.json';
import tttJa from '@/config/ttt/ttt.ja.json';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useLangList } from '@/hooks/useLangList';
import chevronRightColor from '@/img/chevron_right_color.svg';
import chevronRight from '@/img/chevron_right_disabled.svg';
import deleteIcon from '@/img/delete.svg';
import deleteColorIcon from '@/img/delete_color.svg';
import processing from '@/img/progress-indetaminate.svg';
import warning from '@/img/warning.svg';
import { TRANS_STATUS, PREV_TRAN_ERROR_STATUS } from '@/utils/constants';
import { formatSec, formatlangLabel } from '@/utils/formatUtil';

import './TranslationRow.css';

// 翻訳ファイルの行Props
type rowProps = {
  tranStatus: string;
  errorStatus: string;
  fileName: string;
  fileProgress: number;
  selectedRow: boolean;
  disabledDownloadButton: boolean;
  elapsedTime: number;
  onClick?: () => void;
  onClickDownload?: () => void; // ダウンロードボタン押下時処理
  onClickRemove?: () => void; // 削除ボタン押下時処理
  onClickEdit?: () => void; // 字幕編集処理
};
// 翻訳済みの行Props
type translatedRowProps = {
  thumbnail: string;
  translateFromLang: string;
  translateToLang: string;
  length: number;
  tranDate: Date;
  expirationDate: number;
  expirationDateTime: Timestamp | null;
  subtitles: string;
};

// 翻訳中、エラー発生の行Props
type translatingRowProps = { informativeText?: string; messageText?: string };

export type TranslationRowProps = rowProps & translatedRowProps & translatingRowProps;

// TODO 行の背景色定義
const backgroundColors = {
  default: 'bg-white',
  error: 'bg-red-200',
  highlight: 'bg-gray-200',
};

const progressStyle = {
  margin: '-4px 0 0 0',
  // padding: '0 0 0 20px',
  with: 'calc(100% - 20px)',
};

export const TranslationHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <thead className="translation-thead">
      <tr className="translation-table-header-row">
        <th className="tbl-header-file-name">{t('translation.headerFileName')}</th>
        <th className="tbl-target-lang">{t('translation.headerTargetLang')}</th>
        <th className="tbl-exp-date">{t('translation.headerExpDate')}</th>
        <th className="tbl-span" colSpan={3}></th>
      </tr>
    </thead>
  );
};

export const TranslationRow = ({
  tranStatus,
  errorStatus,
  fileName,
  fileProgress,
  thumbnail = '',
  translateToLang,
  length,
  expirationDate,
  expirationDateTime,
  selectedRow = false,
  onClick,
  onClickDownload,
  onClickRemove,
  onClickEdit,
  subtitles,
}: TranslationRowProps) => {
  const { t } = useTranslation();
  const { language } = useBrowserUserSetting();
  const { langList } = useLangList(language, tttEn, tttJa);

  return (
    <>
      {tranStatus === TRANS_STATUS.ENCODED && errorStatus === PREV_TRAN_ERROR_STATUS.NO_ERROR && (
        // ファイルステータスが変換済みの場合
        <tr
          className={clsx('outside-row', 'translation-row', backgroundColors[selectedRow ? 'highlight' : 'default'])}
          onClick={onClick}
          data-testid="encoded"
        >
          <td className="tbl-file-name">
            <div className="row-container">
              <div className="thumbnail-container">
                <div className="thumbnail-base">
                  <Image src={thumbnail} />
                </div>
                <div className="video-duration">
                  <TextBlock text={formatSec(length)} align="center" />
                </div>
              </div>
              <div className="file-container">
                <span>
                  <TextEllipsis text={fileName} />
                </span>
                <span className="flex">
                  <p className="subtitle">{subtitles}</p>
                </span>
              </div>
            </div>
          </td>
          <td className="tbl-target-lang">{formatlangLabel(translateToLang, langList)}</td>

          <td className="tbl-exp-date">
            {expirationDateTime &&
              (expirationDate >= 0 ? (
                <label data-testid="expiration">{t('translation.expirationDate', { value: expirationDate })}</label>
              ) : (
                <label data-testid="expiration">{t('translation.expirationDate', { value: 0 })}</label>
              ))}
          </td>

          <td className="tbl-del-btn">
            <button className="btn_icon" onClick={onClickRemove}>
              <Image src={deleteColorIcon as string} />
            </button>
          </td>

          <td className="tbl-edit-subtitle-btn">
            <Button
              label={t('translation.editSubtitles')}
              onClick={onClickEdit}
              class_name="btn-subtitle-edit"
              icon_right={chevronRightColor}
            />
          </td>

          <td className="tbl-download-btn">
            <Button label={t('translation.download')} onClick={onClickDownload} class_name="btn-download" />
          </td>
        </tr>
      )}
      {(tranStatus === TRANS_STATUS.WAITING || tranStatus === TRANS_STATUS.ENCODING) &&
        errorStatus === PREV_TRAN_ERROR_STATUS.NO_ERROR && (
          // ファイルステータスが変換中または変換待ちの場合
          <>
            <tr
              className={clsx('outside-row', backgroundColors[selectedRow ? 'highlight' : 'default'])}
              data-testid="waiting"
            >
              <td className="tbl-file-name">
                <div className="row-container">
                  <div className="thumbnail-container">
                    <div className="thumbnail-base">
                      <Image src={processing as string} />
                    </div>
                  </div>

                  <div className="file-container">
                    <TextEllipsis text={fileName} />
                    <p>{t('translation.encoding')} </p>
                  </div>
                </div>
              </td>

              <td className="tbl-target-lang">{formatlangLabel(translateToLang, langList)}</td>

              <td className="tbl-exp-date">
                {expirationDateTime &&
                  (expirationDate >= 0 ? (
                    <label data-testid="expiration">{t('translation.expirationDate', { value: expirationDate })}</label>
                  ) : (
                    <label data-testid="expiration">{t('translation.expirationDate', { value: 0 })}</label>
                  ))}
              </td>

              <td className="tbl-del-btn">
                <button className="btn_icon disabled" onClick={onClickRemove}>
                  <Image src={deleteIcon as string} />
                </button>
              </td>

              <td className="tbl-edit-subtitle-btn">
                <Button
                  label={t('translation.editSubtitles')}
                  onClick={onClickEdit}
                  class_name="btn-subtitle-edit"
                  disabled
                  icon_right={chevronRight}
                />
              </td>

              <td className="tbl-download-btn">
                <Button
                  label={t('translation.download')}
                  onClick={onClickDownload}
                  class_name="btn-download"
                  disabled
                />
              </td>
            </tr>
            <tr>
              {fileProgress !== 100 && errorStatus === PREV_TRAN_ERROR_STATUS.NO_ERROR && (
                // <tr className="progress-row">
                <td colSpan={6} className="progress-col">
                  <div className="progress" style={progressStyle}>
                    <ProgressBar progress={fileProgress} />
                  </div>
                </td>
                // </tr>
              )}
            </tr>
          </>
        )}
      {errorStatus !== PREV_TRAN_ERROR_STATUS.NO_ERROR && (
        // ファイルステータスがエラーの場合
        <tr
          className={clsx('outside-row', 'translation-row', backgroundColors[selectedRow ? 'highlight' : 'error'])}
          onClick={onClick}
          data-testid="error"
        >
          <td className="tbl-file-name">
            <td>
              <div className="thumbnail-container">
                <div className="thumbnail-base">
                  <Image src={warning as string} />
                </div>
              </div>
            </td>
            <td>
              <div className="file-container">
                <span>
                  <TextEllipsis text={fileName} />
                </span>
                <span className="flex">
                  <p>{t('translation.otherErrorInformative')}</p>
                  &emsp;
                  <p>{t('translation.otherErrorMessage')}</p>
                </span>
              </div>
            </td>
          </td>
          <td className="tbl-target-lang" />
          <td className="tbl-exp-date" />
          <td colSpan={3} className="tbl-del-btn">
            <button className="btn_icon" onClick={onClickRemove}>
              <Image src={deleteColorIcon as string} />
            </button>
          </td>
        </tr>
      )}
    </>
  );
};
