import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslateToInfoTypes } from '@/components/Form/VoiceTypeSettingsDialog';

export const useLangMenuButton = (translateToValue: TranslateToInfoTypes[]) => {
  const { t } = useTranslation();
  // 翻訳先言語メニューの押下時処理
  const onClickMenu = () => {
    if (openMenu === false) {
      setOpenMenu(true);
    } else {
      setOpenMenu(false);
    }
  };

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  // 翻訳先言語メニューのラベル表示用言語数
  const displayLangCount = (translateToValue.length - 1).toString();

  // 翻訳先言語メニューのラベル表示用変数
  const menuLabel = openMenu
    ? t('upload.lessLanguages', { number: displayLangCount })
    : t('upload.moreLanguages', { number: displayLangCount });

  return {
    onClickMenu,
    menuLabel,
    openMenu,
  };
};
