import './TicketRemainBar.css';
import { useTicketRemainBar } from '@/components/Elements/';

// チケット残り時間表示バーProps
type TicketRemainBarProps = {
  usageValue: number; // 動画合計時間
  remainValue: number; //チケット残り時間
};

export const TicketRemainBar = ({ usageValue, remainValue }: TicketRemainBarProps) => {
  useTicketRemainBar(usageValue, remainValue);
  return (
    <div data-testid="ticketRemainBar" className="ticketRemainBar-tr">
      <div className="ticketRemainBar-remain-cell" />
    </div>
  );
};
