import clsx from 'clsx';

import { Image } from '@/components/Elements/';
import './TextButton.css';

// ラベル表示、ボタンのレイアウト定義
const variants = {
  black: 'text-black border-none shadow-none font-bold',
  red: 'text-red border-none shadow-none font-bold',
  blue: 'text-blue border-none shadow-none font-bold',
};

// TextButtonのProps
export type TextButtonProps = {
  label: string;
  variant?: keyof typeof variants;
  icon_left?: string;
  icon_right?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const TextButton = ({
  label,
  variant = 'black',
  icon_left,
  icon_right,
  disabled = false,
  onClick,
}: TextButtonProps) => {
  // labelの値が存在しない場合はreturn
  if (!label) {
    return;
  }

  // labelの改行
  const editesLabel = label.split('¥n').map((line, key) => (
    <span key={key}>
      {line}
      <br />
    </span>
  ));

  return (
    <button
      type="button"
      className={clsx('text-center text-base', variants[variant])}
      disabled={disabled}
      onClick={onClick}
    >
      <div className="flex justify-center">
        <div className="flex items-center ">
          {icon_left && <Image src={icon_left} />}
          <div className="text-button-label">{editesLabel}</div>
          {icon_right && <Image src={icon_right} />}
        </div>
      </div>
    </button>
  );
};
