import { SIGNEDURL_UPLOAD_API_URL } from '@/config';
import { axios } from '@/lib/axios';

/** 署名付きURL発行（PUT）API リクエストパラメータ */
export type GenerateSignedUrlUploadApiRequest = {
  user_id: string;
  file_id: string;
  file_name: string;
};

/**
 * 署名付きURL発行（PUT）API  結果コード定数
 */
export const SIGNEDURL_UPLOAD_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
export type GenerateSignedUrlUploadApiResultCode =
  (typeof SIGNEDURL_UPLOAD_API_RESULT_CODE)[keyof typeof SIGNEDURL_UPLOAD_API_RESULT_CODE];

/** 署名付きURL発行（PUT）API レスポンス */
export type GenerateSignedUrlUploadApiResponse = {
  resultCode: GenerateSignedUrlUploadApiResultCode;
  uploadUrl: string;
};

export const generateSignedUrlUploadApi = (
  request: GenerateSignedUrlUploadApiRequest
): Promise<GenerateSignedUrlUploadApiResponse> => {
  const jsonParams = {
    userId: request.user_id,
    fileId: request.file_id,
    fileName: request.file_name,
  };

  const response = axios
    .post<GenerateSignedUrlUploadApiResponse>(SIGNEDURL_UPLOAD_API_URL, jsonParams, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};
