import { POCKETALK_AUTH } from '@/constants';
import { convertObjectToSneakUrlParams } from '@/utils';

/**
 * 必ずcodeを指定する
 */
const RESPONSE_TYPE = 'code' as string;
/**
 * 必ずopenid[半角スペース]emailを指定する
 */
const SCOPE = 'openid email' as string;

/**
 * サインアップ画面を直接表示する際に指定する値
 */
export const SCREEN_HINT_SIGNUP = 'signup' as string;

/**
 * リクエストパラメータ(ユーザが指定可能なパラメータ)
 */
export type AuthorizeApiRequest = {
  // ログイン後にリダイレクトするURL
  redirectUri: string;
  // CSRFを防ぐための英数字のみの値
  state: string;
  // サインアップ画面を表示するための値
  screenHint?: string;
};

/**
 * リクエストパラメータ(内部的なパラメータ)
 */
type AuthorizeRequestParameter = {
  responseType: string;
  scope: string;
  // アプリケーション登録申請後に発行されたclient_id
  clientId: string;
} & AuthorizeApiRequest;

/**
 * ポケトーク ログイン認証(Auth0)
 * ※内部的な処理
 *
 * @param request
 * @param needsKeepHistory このAPIを呼んだ画面の履歴を残すかどうか(true=残す, false=残さない)
 */
const authorizeLogin = (request: AuthorizeRequestParameter, needsKeepHistory: boolean) => {
  // 指定されたリダイレクトURLをURLエンコードする(認証サーバの仕様でURLエンコード必須のため)
  // stateも念のためURLエンコードする
  const requestEncodeParam: AuthorizeRequestParameter = {
    clientId: request.clientId,
    redirectUri: encodeURIComponent(request.redirectUri),
    responseType: encodeURIComponent(request.responseType),
    scope: encodeURIComponent(request.scope),
    state: encodeURIComponent(request.state),
  };

  if (request.screenHint) {
    requestEncodeParam.screenHint = encodeURIComponent(request.screenHint);
  }

  // パラメータをスネークケースに変換
  const sneakParams = convertObjectToSneakUrlParams(requestEncodeParam);
  // リクエストURL作成
  const url = `${POCKETALK_AUTH.DOMAIN}${POCKETALK_AUTH.API_URL.AUTHORIZE}?${sneakParams}`;

  // GETでアクセス(ブラウザアクセスと一緒)
  if (needsKeepHistory) {
    window.open(url, '_self');
  } else {
    // このAPIを呼んだ画面の履歴を残さない
    window.location.replace(url);
  }
};

/**
 * ポケトーク ログイン認証(Auth0)
 *
 * @param request
 * @param needsKeepHistory このAPIを呼んだ画面の履歴を残すかどうか(true=残す, false=残さない)
 */
export const authorize = (request: AuthorizeApiRequest, needsKeepHistory: boolean) => {
  authorizeLogin(
    {
      clientId: POCKETALK_AUTH.CLIENT_ID,
      responseType: RESPONSE_TYPE,
      scope: SCOPE,
      redirectUri: request.redirectUri,
      state: request.state,
      screenHint: request.screenHint,
    },
    needsKeepHistory
  );
};
