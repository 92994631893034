import { ChangeEvent } from 'react';

// フラッシュメッセージ型定義
type SearchLangProps = {
  inputValue: string;
  placeholder: string;
  handleSearchChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchLang = ({ inputValue, placeholder, handleSearchChange }: SearchLangProps) => {
  return (
    <div className="searchLang">
      <input
        className="textbox"
        type="text"
        value={inputValue}
        placeholder={placeholder}
        onChange={handleSearchChange}
      ></input>
    </div>
  );
};
