import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from 'notistack';

import { Image } from '@/components/Elements';
import closeMessage from '@/img/close_message.svg';
import { MessageTypes } from '@/types/commonTypes';

export const MessageProvider = () => {
  return (
    <SnackbarProvider
      action={(snackbarId) => (
        <button onClick={() => closeSnackbar(snackbarId)}>
          <Image src={closeMessage as string} />
        </button>
      )}
      maxSnack={5}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      style={{ whiteSpace: 'pre-line' }}
      autoHideDuration={4000}
    />
  );
};

/**
 * メッセージを積み上げる
 * @param message メッセージ内容
 * @param type メッセージタイプ
 */
export const showMessage = (message: string, type: MessageTypes, autoClose = true): void => {
  if (autoClose) {
    enqueueSnackbar(message, {
      variant: type,
      preventDuplicate: true,
      autoHideDuration: 4000,
    });
  } else {
    enqueueSnackbar(message, {
      variant: type,
      preventDuplicate: true,
      autoHideDuration: null,
    });
  }
};
