import { useCallback, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslateToInfoTypes, VOICE_NAMES } from '@/components/Form';
import { LangListTypes } from '@/types/commonTypes';

// 翻訳先情報存在判定型定義
export type GetLangTypes = {
  langChecked: boolean;
  voiceName: VOICE_NAMES;
};

const saveLangList: LangListTypes[] = [];

/**
 * 翻訳先言語選択ダイアログ hooks
 *
 * @returns
 */
export const useVoiceTypeSettingsDialog = (
  open: boolean,
  onClose: (translation?: TranslateToInfoTypes[]) => void,
  translateToInfoListParam: TranslateToInfoTypes[],
  langListParam: LangListTypes[]
) => {
  const { t } = useTranslation();

  // ダイアログを閉じた時
  const handleDialogClose = useCallback(
    (translation?: TranslateToInfoTypes[]) => {
      onClose?.(translation);
    },
    [onClose]
  );

  const [langList, setLangList] = useState<LangListTypes[]>([]);

  /*
   * State
   */

  // 翻訳先情報（言語、音声）state
  const [translateToInfoList, setTranslateToInfoList] = useState<TranslateToInfoTypes[]>([]);

  // 翻訳元言語ドロップダウンstate
  const [inputValue, setInputValue] = useState('');

  /*
   * Function
   */

  // 翻訳先行へのチェック時処理
  const onLangChecked = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      const saveTranslateToInfoList = translateToInfoList.filter(
        (value) => value.langCode !== Object.keys(langListParam[Number(event.target.id)].lang)[0]
      );
      setTranslateToInfoList(saveTranslateToInfoList);

      return;
    }

    // チェック時に配列に翻訳先言語、翻訳先音声のデフォルト値を追加
    for (let i = 0; i < langList.length; i++) {
      if (Number(event.target.id) === langList[i].index) {
        setTranslateToInfoList([
          ...translateToInfoList,

          {
            langCode: Object.keys(langListParam[Number(event.target.id)].lang)[0],
            voiceName: VOICE_NAMES.VOICE_NONE,
            voiceType: '',
          },
        ]);
        setLangList(langList);
      }
    }
  };

  // 翻訳先音声ラジオボタンの値変化時処理
  // TODO：Ver1.0の対象外（音声選択）のため一旦コメントアウト中
  // const onVoiceChange = (event: ChangeEvent<HTMLInputElement>, langName: string) => {
  //   // 対象言語の情報を更新
  //   setTranslateToInfoList((prevState) =>
  //     prevState.map((value) =>
  //       value.langName === langName ? { langName: langName, voiceType: event.target.value as VOICE_TYPES } : value
  //     )
  //   );
  // };

  // 翻訳先情報の存在チェック処理 get
  const getLang = (value: string): GetLangTypes => {
    // デフォルト値の設定
    let langChecked = false;
    let voiceName = VOICE_NAMES.VOICE_NONE; //設定無し

    // 画面から受け取った翻訳先情報とAPI取得の翻訳先言語リストを言語名で照合
    // あった場合はfindフラグをtrue, voice_typeに取得した音声を設定する
    translateToInfoList.forEach((object) => {
      if (object.langCode === value) {
        langChecked = true;
        voiceName = object.voiceName;
      }
    });
    return { langChecked, voiceName };
  };

  // 画面監視用hooks
  useEffect(() => {
    // ダイアログ開閉時に翻訳先情報リストを更新
    setTranslateToInfoList(translateToInfoListParam);
    setInputValue('');

    // 翻訳先言語一覧を初期化
    saveLangList.length = 0;
    for (let i = 0; i < langListParam.length; i++) {
      saveLangList.push(langListParam[i]);
    }
    setLangList([...saveLangList]);
  }, [open, translateToInfoListParam, langListParam]);

  // 字幕設定ラジオボタン値変化時処理
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    const resultLangList = saveLangList.filter(
      (langList) =>
        Object.values(langList.lang).filter(
          (item) =>
            item !== undefined && item !== null && item.toUpperCase().indexOf(event.target.value.toUpperCase()) !== -1
        ).length > 0
    );
    setLangList(resultLangList);
  };

  return {
    t,
    translateToInfoList,
    inputValue,
    langList,
    handleDialogClose,
    getLang,
    onLangChecked,
    handleSearchChange,
  };
};
