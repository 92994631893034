import { Button, TextBlock, Image, SearchLang } from '@/components/Elements/';
import { useVoiceTypeSettingsDialog, VoiceTypeSettingsRow, VOICE_NAMES } from '@/components/Form/';
import closeDialog from '@/img/close_dialog.svg';
import { LangListTypes } from '@/types/commonTypes';
import { SELECT_LANG_LIMIT } from '@/utils/constants';

import './VoiceTypeSettingsDialog.css';

// 音声設定ダイアログprops
export type VoiceTypeSettingsDialogProps = {
  open: boolean;
  onClose: (translation?: TranslateToInfoTypes[]) => void;
  translateToInfoListParam: TranslateToInfoTypes[];
  langListParam: LangListTypes[];
};

// Firestoreに登録する翻訳先情報型定義（言語、音声）
export type TranslateToInfoTypes = {
  langCode: string;
  voiceType: string;
  voiceName: VOICE_NAMES;
};

export const VoiceTypeSettingsDialog = ({
  open,
  onClose,
  translateToInfoListParam,
  langListParam,
}: VoiceTypeSettingsDialogProps): JSX.Element => {
  const {
    t,
    translateToInfoList,
    inputValue,
    langList,
    handleDialogClose,
    getLang,
    onLangChecked,
    handleSearchChange,
  } = useVoiceTypeSettingsDialog(open, onClose, translateToInfoListParam, langListParam);

  return (
    <>
      {open && (
        <div className="voice-type-settings-dialog-area">
          <dialog open={open} onClose={() => handleDialogClose()} className="voice-type-settings">
            <div className="voice-type-settings-header-container">
              <div className="voice-type-settings-header">
                <div className="title">
                  <TextBlock align="left" text={t('settings.voiceTypeSettingsDialogTitle')} />
                </div>
                <div className="voice-type-settings-close">
                  <button
                    onClick={() => {
                      handleDialogClose();
                    }}
                  >
                    <Image src={closeDialog as string} />
                  </button>
                </div>
              </div>
              <div className="description-wrap">
                <TextBlock text={t('settings.description')} />
                <div className="description-secondary">
                  <TextBlock text={t('settings.descriptionSecondary1')} />
                  <TextBlock text={t('settings.descriptionSecondary2')} />
                </div>
                <SearchLang
                  inputValue={inputValue}
                  placeholder={t('settings.search')}
                  handleSearchChange={handleSearchChange}
                />
              </div>
            </div>

            <div className="setting-dialog">
              <div className="voiceTypeSettingsDialog-main">
                {(() => {
                  const list = [];
                  for (let i = 0; i < langList.length; i += 2) {
                    list.push(
                      <div className="voice-type-row" key={i}>
                        <div className="voice-type-left">
                          <VoiceTypeSettingsRow
                            langCheck={getLang(Object.keys(langList[i].lang)[0]).langChecked}
                            langKey={langList[i].index.toString()}
                            langValue={Object.values(langList[i].lang)[0]}
                            // voiceType={getLang(prevItem.langName).voiceType}
                            // defaultVoiceType={prevItem.defaultVoiceType}
                            // enableMale={prevItem.enableMale}
                            // enableFemale={prevItem.enableFemale}
                            onLangChecked={(e) => onLangChecked(e)}
                            // onVoiceChange={(e) => onVoiceChange(e, prevItem.langName)}
                          />
                        </div>
                        {i + 1 < langList.length && (
                          <div className="voice-type-right">
                            <VoiceTypeSettingsRow
                              langCheck={getLang(Object.keys(langList[i + 1].lang)[0]).langChecked}
                              langKey={langList[i + 1].index.toString()}
                              langValue={Object.values(langList[i + 1].lang)[0]}
                              // voiceType={getLang(item.langName).voiceType}
                              // defaultVoiceType={item.defaultVoiceType}
                              // enableMale={item.enableMale}
                              // enableFemale={item.enableFemale}
                              onLangChecked={(e) => onLangChecked(e)}
                              // onVoiceChange={(e) => onVoiceChange(e, item.langName)}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                  return list;
                })()}
              </div>
              <div className="setting-button">
                {translateToInfoList.length <= SELECT_LANG_LIMIT ? (
                  <div className="select-lang-label-info">
                    <TextBlock
                      text={t('settings.languagesSelected', {
                        number: translateToInfoList.length,
                        limit: SELECT_LANG_LIMIT,
                      })}
                    />
                  </div>
                ) : (
                  <div className="select-lang-label-alert">
                    <TextBlock
                      text={t('settings.alertLanguagesSelected', {
                        limit: SELECT_LANG_LIMIT,
                      })}
                    />
                  </div>
                )}
                <Button
                  label={t('settings.confirm')}
                  onClick={() => handleDialogClose(translateToInfoList)}
                  disabled={translateToInfoList.length > SELECT_LANG_LIMIT}
                />
              </div>
            </div>
          </dialog>
        </div>
      )}
    </>
  );
};
