import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DropDownMenuItem } from '@/components/Elements';
import { DEFAULT_UI_LANG, UILanguageList } from '@/constants/ui';
import { browserUserSettingSlice, BrowserUserSettingState } from '@/states/slices/browserUserSettingSlice';
import { RootState } from '@/states/store';

/**
 * [設定ダイアログ]の設定内容をローカルストレージとReduxに保存 hooks
 *
 * @returns
 */
export const useBrowserUserSetting = () => {
  const dispatch = useDispatch();

  const { setLanguage, clearUserSetting } = browserUserSettingSlice.actions;

  const { language } = useSelector<RootState, BrowserUserSettingState>((state: any) => state.browserUserSetting);

  /**
   * UI言語リストに対象の言語が含まれているかどうか
   *
   * @param lang
   * @returns true:含まれている、false:含まれていない
   */
  const isIncludedUILanguage = (lang: string): boolean => {
    const targetItem = UILanguageList.find((item: DropDownMenuItem) => item.value === lang);

    if (targetItem) {
      return true;
    }

    return false;
  };

  /**
   * UI言語
   */
  const userSettingLanguage = useMemo(() => {
    if (language) {
      return language;
    }

    // ブラウザ言語を取得
    const browserLang = navigator.language;

    if (browserLang) {
      if (isIncludedUILanguage(browserLang)) {
        return browserLang;
      }

      // 'en-US'などの表記になっている場合があるので'-'前の文字列で比較
      const preBrowserLang = browserLang.slice(0, browserLang.indexOf('-'));

      if (isIncludedUILanguage(preBrowserLang)) {
        return preBrowserLang;
      }
    }

    return DEFAULT_UI_LANG;
  }, [language]);

  /**
   * UI言語を更新
   */
  const changeLanguage = useCallback(
    (value: string) => {
      dispatch(setLanguage(value));
    },
    [dispatch, setLanguage]
  );

  /**
   * [設定ダイアログ]の設定内容に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearUserSetting());
  }, [clearUserSetting, dispatch]);

  return {
    language: userSettingLanguage,
    setLanguage: changeLanguage,
    resetState,
  };
};
