import clsx from 'clsx';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useTranslateFromRow,
  Image,
  ProgressBar,
  TextBlock,
  TextButton,
  TextEllipsis,
  TranslateToRow,
  TranslateToRowProps,
} from '@/components/Elements/';
import deleteIcon from '@/img/delete.svg';
import expandMore from '@/img/expand_more.svg';
import fileDownload from '@/img/file_download.svg';
import processing from '@/img/progress-indetaminate.svg';
import retry from '@/img/retry.svg';
import warning from '@/img/warning.svg';
import { UploadFileList } from '@/types/commonTypes';
import { UPLOAD_STATUS, UPLOAD_ERROR_STATUS, PREVS_STATUS, PREV_TRAN_ERROR_STATUS } from '@/utils/constants';

import './TranslateFromRow.css';

// 翻訳元ファイルの行Props
export type TranslateFromRowProps = {
  uploadStatus: string;
  prevStatus: string;
  uploadErrorStatus: string;
  prevErrorStatus: string;
  fileObject?: UploadFileList;
  fileId: string;
  fileName: string;
  fileSec: number;
  uploadProgress: number;
  prevProgress: number;
  thumbnail: string;
  uploadInformativeText?: string;
  prevInformativeText?: string;
  uploadMessageText?: string;
  prevMessageText?: string;
  elapsedTime: number;
  removeDate?: Timestamp | null;
  disabledPreviewButton: boolean;
  translateToFiles?: TranslateToRowProps[];
  disabledRomoveButton?: boolean;
  skipPrev?: boolean;
  onClickPreview?: () => void; // プレビューダウンロードボタン押下時処理
  onClickRetry?: () => void; // リトライボタン押下時処理
  onClickRemoveDelete?: () => void; // 削除ボタン（ファイル削除）押下時処理
  onClickRemoveStop?: () => void; // 削除ボタン（アップロード中止）押下時処理
  onClickStopPrev?: () => void; // 削除ボタン（プレビュー生成中止）押下時処理
};

// TODO 行の背景色定義
const backgroundColors = {
  default: 'bg-white',
  error: 'bg-red-200',
};

export const TranslateFromRow = ({
  uploadStatus,
  prevStatus,
  uploadErrorStatus,
  prevErrorStatus,
  fileId,
  fileName,
  uploadProgress,
  prevProgress,
  thumbnail = '',
  uploadInformativeText = '',
  prevInformativeText = '',
  uploadMessageText = '',
  prevMessageText = '',
  elapsedTime,
  translateToFiles = [] as TranslateToRowProps[],
  disabledRomoveButton = false,
  skipPrev = false,
  onClickPreview,
  onClickRetry,
  onClickRemoveDelete,
  onClickRemoveStop,
  onClickStopPrev,
  disabledPreviewButton,
}: TranslateFromRowProps) => {
  const { t } = useTranslation();
  const { uploadRemainDhm, prevRemainDhm } = useTranslateFromRow(prevProgress, elapsedTime, uploadProgress);

  const [displayTranslateTo, setDisplayTranslateTo] = useState(true);

  return (
    <>
      {skipPrev ? (
        // プレビュースキップの場合
        <>
          {uploadStatus !== UPLOAD_STATUS.UPLOADED && (
            //ファイルステータスがアップロード中の場合
            <>
              <div data-testid="notPreviewed" className={clsx('translate-from-row', backgroundColors['default'])}>
                <div className="translate-from-row-col1">
                  <div className="thumbnail-base">
                    <Image src={processing as string} />
                  </div>
                </div>
                <div className="translate-from-row-col2">
                  <span>
                    <TextEllipsis text={fileName} />
                  </span>
                  <span className="flex">
                    <TextBlock text={t(prevInformativeText ? prevInformativeText : uploadInformativeText)} size="md" />
                    &emsp;
                    <TextBlock
                      text={t(uploadMessageText, {
                        days: uploadRemainDhm.day,
                        hours: uploadRemainDhm.hour,
                        minutes: uploadRemainDhm.min,
                      })}
                      size="md"
                    />
                  </span>
                </div>
                <div className="translate-from-row-col3" />
                <div className="translate-from-row-col4">
                  <TextButton
                    label={t('upload.remove')}
                    icon_left={deleteIcon as string}
                    variant="red"
                    onClick={onClickRemoveStop}
                  />
                </div>
                <div className="translate-from-row-col5" />
              </div>
              {!(
                uploadStatus === UPLOAD_STATUS.UPLOAD_PENDING ||
                prevStatus === PREVS_STATUS.QUEING ||
                (uploadProgress === 100 && prevProgress === 100) ||
                (uploadProgress === 100 && prevProgress === 0)
              ) && <ProgressBar progress={uploadProgress === 100 ? prevProgress : uploadProgress} />}
            </>
          )}
          {uploadStatus === UPLOAD_STATUS.UPLOADED && (
            // プレビュースキップファイルのアップロード済みの場合
            <>
              <div data-testid="previewed" className={clsx('translate-from-row', backgroundColors['default'])}>
                <div className="translate-from-row-col1">
                  <div className="thumbnail-base">
                    <Image src={thumbnail} />
                  </div>
                </div>
                <div className="translate-from-row-col2">
                  <span>
                    <TextEllipsis text={fileName} />
                  </span>
                </div>
                <div className="translate-from-row-col3" />
                <div className="translate-from-row-col4">
                  <TextButton
                    label={t('upload.remove')}
                    icon_left={deleteIcon as string}
                    variant="red"
                    onClick={onClickRemoveDelete}
                    disabled={disabledRomoveButton}
                  />
                </div>
                <div className="translate-from-row-col5" />
              </div>
            </>
          )}
        </>
      ) : (
        // プレビュースキップではないの場合
        <>
          {uploadErrorStatus === UPLOAD_ERROR_STATUS.NO_ERROR &&
            prevErrorStatus === PREV_TRAN_ERROR_STATUS.NO_ERROR &&
            prevStatus === PREVS_STATUS.PREVIEWED && (
              // ファイルステータスがプレビュー生成済の場合
              <>
                <div data-testid="previewed" className={clsx('translate-from-row', backgroundColors['default'])}>
                  <div className="translate-from-row-col1">
                    <button
                      onClick={() => {
                        setDisplayTranslateTo(!displayTranslateTo);
                      }}
                    >
                      <Image src={thumbnail} />
                    </button>
                  </div>
                  <div className="translate-from-row-col2">
                    <span>
                      <TextEllipsis text={fileName} />
                    </span>
                  </div>
                  <div className="translate-from-row-col3">
                    <div className="upload-download-button">
                      <TextButton
                        label={t('upload.previewDownload')}
                        icon_left={fileDownload as string}
                        disabled={disabledPreviewButton}
                        onClick={onClickPreview}
                      />
                      {disabledPreviewButton && (
                        <div className="upload-downloading">
                          <Image src={processing as string} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="translate-from-row-col4">
                    <TextButton
                      label={t('upload.remove')}
                      icon_left={deleteIcon as string}
                      variant="red"
                      onClick={onClickRemoveDelete}
                      disabled={disabledRomoveButton}
                    />
                  </div>
                  {displayTranslateTo ? (
                    <div className="translate-from-row-col5">
                      <button onClick={() => setDisplayTranslateTo(false)}>
                        <div className="translate-from-row-menu-close">
                          <Image src={expandMore as string} />
                        </div>
                      </button>
                    </div>
                  ) : (
                    <div className="translate-from-row-col5">
                      <button onClick={() => setDisplayTranslateTo(true)}>
                        <div className="translate-from-row-menu-open">
                          <Image src={expandMore as string} />
                        </div>
                      </button>
                    </div>
                  )}
                </div>

                {displayTranslateTo &&
                  translateToFiles.map((file, index) => {
                    if (file.fileId === fileId) {
                      return (
                        <TranslateToRow
                          key={index}
                          fileId={file.fileId}
                          langCode={file.langCode}
                          prevThumbnail={file.prevThumbnail}
                          subtitle={file.subtitle}
                          videoUrl={file.videoUrl}
                        />
                      );
                    }
                  })}
              </>
            )}
          {uploadErrorStatus === UPLOAD_ERROR_STATUS.NO_ERROR &&
            prevErrorStatus === PREV_TRAN_ERROR_STATUS.NO_ERROR &&
            prevStatus !== PREVS_STATUS.PREVIEWED && (
              // ファイルステータスがアップロード中、プレビュー生成待ち、プレビュー生成中の場合
              <>
                <div data-testid="notPreviewed" className={clsx('translate-from-row', backgroundColors['default'])}>
                  <div className="translate-from-row-col1">
                    <div className="thumbnail-base">
                      <Image src={processing as string} />
                    </div>
                  </div>
                  <div className="translate-from-row-col2">
                    <span>
                      <TextEllipsis text={fileName} />
                    </span>
                    <span className="flex">
                      <TextBlock
                        text={t(prevInformativeText ? prevInformativeText : uploadInformativeText)}
                        size="md"
                      />
                      &emsp;
                      <TextBlock
                        text={
                          prevMessageText
                            ? t(prevMessageText, {
                                days: prevRemainDhm.day,
                                hours: prevRemainDhm.hour,
                                minutes: prevRemainDhm.min,
                              })
                            : t(uploadMessageText, {
                                days: uploadRemainDhm.day,
                                hours: uploadRemainDhm.hour,
                                minutes: uploadRemainDhm.min,
                              })
                        }
                        size="md"
                      />
                    </span>
                  </div>
                  <div className="translate-from-row-col3" />
                  <div className="translate-from-row-col4">
                    {uploadStatus !== UPLOAD_STATUS.UPLOADED ? (
                      <TextButton
                        label={t('upload.remove')}
                        icon_left={deleteIcon as string}
                        variant="red"
                        onClick={onClickRemoveStop}
                      />
                    ) : (
                      <TextButton
                        label={t('upload.remove')}
                        icon_left={deleteIcon as string}
                        variant="red"
                        onClick={onClickStopPrev}
                      />
                    )}
                  </div>
                  <div className="translate-from-row-col5" />
                </div>
                {!(
                  uploadStatus === UPLOAD_STATUS.UPLOAD_PENDING ||
                  prevStatus === PREVS_STATUS.QUEING ||
                  (uploadProgress === 100 && prevProgress === 100) ||
                  (uploadProgress === 100 && prevProgress === 0)
                ) && <ProgressBar progress={uploadProgress === 100 ? prevProgress : uploadProgress} />}
              </>
            )}
          {(uploadErrorStatus !== UPLOAD_ERROR_STATUS.NO_ERROR ||
            prevErrorStatus !== PREV_TRAN_ERROR_STATUS.NO_ERROR) && (
            // ファイルステータスがエラーまたはネットワークエラーの場合
            <>
              <div data-testid="error" className={clsx('translate-from-row', backgroundColors['error'])}>
                <div className="translate-from-row-col1">
                  <div className="thumbnail-base mod-error">
                    <Image src={warning as string} />
                  </div>
                </div>
                <div className="translate-from-row-col2">
                  <span id="fileName">
                    <TextEllipsis text={fileName} />
                  </span>
                  <span className="flex">
                    <TextBlock
                      text={t(uploadInformativeText ? uploadInformativeText : prevInformativeText)}
                      size="md"
                    />
                    &emsp;
                    <TextBlock text={t(uploadMessageText ? uploadMessageText : prevMessageText)} size="md" />
                  </span>
                </div>
                <div className="translate-from-row-col3">
                  {uploadErrorStatus === UPLOAD_ERROR_STATUS.NETWORK_ERROR && (
                    <TextButton label={t('upload.retry')} icon_left={retry as string} onClick={onClickRetry} />
                  )}
                </div>
                <div className="translate-from-row-col4">
                  <TextButton
                    label={t('upload.remove')}
                    icon_left={deleteIcon as string}
                    variant="red"
                    onClick={onClickRemoveDelete}
                  />
                </div>
                <div className="translate-from-row-col5" />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
