import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Button, Image, TextBlock } from '@/components/Elements/';
import movie from '@/img/movie.svg';
import { formatAcceptTypesByDrop } from '@/utils/formatUtil';

import './DragDropArea.css';

// TODO背景色定義
const backgroundColors = {
  default: 'bg-white',
  dragging: 'bg-purple-400',
  uploadError: 'bg-red-200',
};

// Drag&DropエリアProps
export type DragDropAreaProps = {
  cntFiles: number;
  acceptTypes: string[];
  backgroundColor?: keyof typeof backgroundColors;
  buttonDisabled: boolean;
  onDrop: (acceptedFiles: File[]) => void;
};

export const DragDropArea = ({
  cntFiles,
  acceptTypes,
  backgroundColor = 'default',
  buttonDisabled,
  onDrop,
}: DragDropAreaProps) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    onDrop,
    accept: {
      'video/mp4': formatAcceptTypesByDrop(acceptTypes),
    },
  });
  const { t } = useTranslation();
  // アップロード制限チェックフラグ(true:チェックOK、false:チェックNG)
  const [canUpload, setCanUpload] = useState(false);
  useEffect(() => {
    if (isDragActive) {
      // Drag&Dropエリアにドラッグ中の場合
      document.getElementsByClassName('drag-drop-zone')[0].classList.add('drag');
    } else {
      // ドラッグ後、Drag&Dropエリア外に出た背景色を元に戻すためクラス名を削除
      document.getElementsByClassName('drag-drop-zone')[0].classList.remove('drag', 'caution');

      if (acceptedFiles.length > cntFiles) {
        // Drag&Dropエリアにドロップしたファイルが制限を超える場合
        document.getElementsByClassName('drag-drop-zone')[0].classList.add('caution');
        setCanUpload(false);
      } else {
        // Drag&Dropエリアにドロップしたファイルが制限を超えない場合
        setCanUpload(true);
      }
    }
  }, [acceptedFiles.length, backgroundColor, cntFiles, isDragActive]);

  return (
    <>
      <div
        data-testid="drag-drop"
        {...getRootProps({ onClick: (event) => event.stopPropagation() })}
        className={clsx('drag-drop-zone', backgroundColors[backgroundColor])}
      >
        <div className="drag-drop-cont-wrap">
          <input data-testid="get-input" {...getInputProps()} />
          <div className="vertical-10">
            <div className="drag-drop-image">
              <Image src={movie as string} />
            </div>
            <div>
              <div className="inner-label">{t('selectFiles.uploadTitle')}</div>
            </div>
            <div>
              <div className="inner-label-sub">{t('selectFiles.uploadTitleOr')}</div>
            </div>
          </div>
          <div className="vertical-10">
            <div className={clsx('flex justify-center')}>
              <div {...getRootProps()}>
                <Button label={t('selectFiles.addFile')} disabled={buttonDisabled} class_name="mvs-upload" />
              </div>
            </div>
            {canUpload ? (
              <div className="inner-label-info">
                <TextBlock
                  text={t('selectFiles.uploadDetail').replace('${cntFiles}', cntFiles as unknown as string)}
                  size="md"
                />
              </div>
            ) : (
              <div className={clsx('mt-5')}>
                <TextBlock
                  text={t('selectFiles.uploadErrorDetail').replace('${cntFiles}', cntFiles as unknown as string)}
                  size="md"
                  variant="alert"
                />
                <TextBlock text={t('selectFiles.uploadErrorMessage')} size="md" variant="alert" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
