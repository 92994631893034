import { Snackbar } from '@mui/material';

// フラッシュメッセージ型定義
type FlashMessageProps = {
  open: boolean;
  message: string;
  hiddenDuration: number;
  onClose?: () => void;
};

export const FlashMessage = ({ open, message, hiddenDuration, onClose }: FlashMessageProps) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={hiddenDuration}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={message}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      sx={{ zIndex: 2000 }}
    />
  );
};
