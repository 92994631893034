import { Image, TextEllipsis } from '@/components/Elements/';
import deleteIcon from '@/img/delete.svg';
import { formatSec } from '@/utils/formatUtil';

import './VideoInfoRow.css';
// 動画情報行Props
export type VideoInfoRowProps = {
  fileName: string; // ファイル名
  fileSec: number; // 動画の長さ
  deletable?: boolean; // 行削除可/不可フラグ
  onClick?: () => void; // 削除ボタン押下時処理
};

export const VideoInfoRow = ({ fileName, fileSec, deletable = true, onClick }: VideoInfoRowProps) => {
  return (
    <>
      <div data-testid="videoInfoRow" className="videoInfoRow-main">
        <div className="video-title">
          <TextEllipsis text={fileName}></TextEllipsis>
        </div>
        <div className="wrap-col">
          <div className="video-time">{formatSec(fileSec)}</div>
          <div className="delete-icon">
            {deletable && (
              <button onClick={onClick} className="bt-delete">
                <Image src={deleteIcon as string} />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
