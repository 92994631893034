import { useMemo } from 'react';

import { COOKIE_KEY_NAME } from '@/constants';
import { findCookieValue } from '@/utils/cookie';
import { parseJwt } from '@/utils/license';
/**
 * 認証関連 カスタムフック
 *
 * @param param0
 */
export const useAuth = () => {
  // Cookieからトークンを取得
  const token = findCookieValue(COOKIE_KEY_NAME.PTBR_TOKEN);
  const firebaseToken = findCookieValue(COOKIE_KEY_NAME.GCP_TOKEN);
  /**
   * ユーザーID取得
   */
  const userId = useMemo(() => {
    if (!parseJwt(token).sub || parseJwt(token).sub?.length === 0) {
      return '';
    }
    return parseJwt(token).sub;
  }, [token]);

  /**
   * ユーザーメールアドレス取得
   */
  const userMail = useMemo(() => {
    return parseJwt(token).email;
  }, [token]);
  return {
    userId,
    userMail,
    firebaseToken,
  };
};
