import { TRNASLATE_API_URL } from '@/config';
import { axios } from '@/lib/axios';
import { Ttt } from '@/types/commonTypes';

/** 翻訳API リクエストパラメータ */
export type TranslateApiRequest = {
  settingId: string;
  uploadId: string;
  srcLang: string;
  destLang: string;
  id: string;
  text: string;
  start: string;
  end: string;
};

/**
 * 翻訳API  結果コード定数
 */
export const TRANSLATE_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // Firestoreから翻訳動画情報が取得できない
  WARN_NO_DATA: 'WARN_NO_DATA',
  // TTT処理、WebSocketにアクセスできない
  WARN_WEBSOCKET_ACCESS: 'WARN_WEBSOCKET_ACCESS',
  // 字幕分割処理の失敗
  WARN_SUBTITLE_SPLIT: 'WARN_SUBTITLE_SPLIT',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
export type TranslateApiResultCode = (typeof TRANSLATE_API_RESULT_CODE)[keyof typeof TRANSLATE_API_RESULT_CODE];

/** 翻訳API レスポンス */
export type TranslateApiResponse = {
  resultCode: TranslateApiResultCode;
  tttModel: Ttt;
};

export const translateApi = (request: TranslateApiRequest): Promise<TranslateApiResponse> => {
  const jsonParams = {
    settingId: request.settingId,
    uploadFileId: request.uploadId,
    srcLang: request.srcLang,
    destLang: request.destLang,
    id: request.id,
    text: request.text,
    start: request.start,
    end: request.end,
  };

  const response = axios
    .post<TranslateApiResponse>(TRNASLATE_API_URL, jsonParams, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};
