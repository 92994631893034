import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { limitByte } from '@/constants';
import { Subtitle } from '@/features/editor';
import { useFlashMessage } from '@/providers/flashMessage';
import { showMessage } from '@/providers/message';
import { timeToSec, formatSecDecimal } from '@/utils/formatUtil';

export const useSubtitlesRow = (
  index: number,
  translateFrom: string,
  translateTo: string[],
  startTime: number[],
  endTime: number[],
  disableTranslateButton: boolean,
  judgeData: () => void,
  changeData: (e: any) => void,
  translateData: (index: number) => Promise<Subtitle | undefined>
) => {
  const { t } = useTranslation();
  const [translateFromText, setTranslateFromText] = useState('');
  const [translateToText, setTranslateToText] = useState<string[]>([]);
  const [startText, setStartText] = useState<string[]>([]);
  const [endText, setEndText] = useState<string[]>([]);

  // 入力項目制御フラグ(字真の表示時間、翻訳字幕)
  const [readonlyClickTranslate, setReadonlyClickTranslate] = useState(false);

  const [disableTranslate, setDisableTranslate] = useState(true);

  const { showFlashMessage } = useFlashMessage();

  useEffect(() => {
    // 翻訳前テキストを初期化
    const subtitleSaveTranslateFrom = translateFrom;
    setTranslateFromText(subtitleSaveTranslateFrom);

    // 翻訳後テキストを初期化
    const subtitleSaveTranslateTo: string[] = [];
    translateTo.forEach((translate) => {
      subtitleSaveTranslateTo.push(translate);
    });
    setTranslateToText(subtitleSaveTranslateTo);

    // 字幕の開始時間を初期化
    const startTimeSave: number[] = [];
    const startTextSave: string[] = [];
    startTime.forEach((start) => {
      startTimeSave.push(start);
      startTextSave.push(formatSecDecimal(start));
    });
    setStartText(startTextSave);

    // 字幕の終了時間を初期化
    const endTimeSave: number[] = [];
    const endTextSave: string[] = [];
    endTime.forEach((end) => {
      endTimeSave.push(end);
      endTextSave.push(formatSecDecimal(end));
    });
    setEndText(endTextSave);

    // 翻訳ボタンの初期化
    setDisableTranslate(disableTranslateButton);
  }, [translateFrom, translateTo, startTime, endTime, disableTranslateButton]);

  // 値が変更されたとき
  const onChange = (event: any) => {
    // 数字、コロン、ピリオド以外の文字が入力された場合、値を更新しない
    if (!event.target.validity.valid) {
      return;
    }

    const id = event.currentTarget.id.substring(event.currentTarget.id.lastIndexOf('_') + 1);
    if (event.currentTarget.id.includes('startTime')) {
      // 字幕の開始時間の値が変更された場合
      const newStartText = [...startText];
      newStartText[id] = event.target.value;
      setStartText(newStartText);
    } else if (event.currentTarget.id.includes('endTime')) {
      // 字幕の終了時間の値が変更された場合
      const newEndText = [...endText];
      newEndText[id] = event.target.value;
      setEndText(newEndText);
    } else if (event.currentTarget.id.includes('translateFrom')) {
      const newTranslateFromText = event.target.value;
      setTranslateFromText(newTranslateFromText);
    } else {
      // 翻訳後テキストの値が変更された時
      const newTranslateToText = [...translateToText];
      newTranslateToText[id] = event.target.value;
      setTranslateToText(newTranslateToText);
    }
    changeData({ index: index, id: event.currentTarget.id, value: event.target.value });
  };

  // 翻訳元テキストにフォーカスアウトされたとき
  const onBlurTranslateFrom = () => {
    judgeData();
  };

  // フォーカスアウトされたとき
  const onBlur = (index: number, itemIndex: number) => {
    let startTypeError = false;
    let endTypeError = false;

    const start = timeToSec(startText[itemIndex]);
    // 開始時間が空白ではない場合はその値を、空白の場合は0をセットし変換
    const saveStartTime = formatSecDecimal(startText[itemIndex] ? start : 0);
    const end = timeToSec(endText[itemIndex]);
    // 終了時間が空白ではない場合はその値を、空白の場合は0をセットし変換
    const saveEndTime = formatSecDecimal(endText[itemIndex] ? end : 0);

    // 開始時間のフォーマットチェック
    if (isNaN(start) && startText[itemIndex]) {
      // フォーマットエラーの場合、errorクラスを追加
      startTypeError = true;
      document.getElementById('startTime_' + index + '_' + itemIndex)?.classList.add('error');

      const newStartText = [...startText];
      newStartText[itemIndex] = startText[itemIndex];
      setStartText(newStartText);
    } else {
      if (startText[itemIndex] !== saveStartTime) {
        const newStartText = [...startText];
        newStartText[itemIndex] = saveStartTime;
        setStartText(newStartText);
      }

      // errorクラスを削除
      startTypeError = false;
      document.getElementById('startTime_' + index + '_' + itemIndex)?.classList.remove('error');
    }
    // 終了時間のフォーマットチェック
    if (isNaN(end) && endText[itemIndex]) {
      // フォーマットエラーの場合、errorクラスを追加
      endTypeError = true;
      document.getElementById('endTime_' + index + '_' + itemIndex)?.classList.add('error');

      const newEndText = [...endText];
      newEndText[itemIndex] = endText[itemIndex];
      setEndText(newEndText);
    } else {
      if (endText[itemIndex] !== saveEndTime) {
        const newEndText = [...endText];
        newEndText[itemIndex] = saveEndTime;
        setEndText(newEndText);
      }

      // errorクラスを削除
      endTypeError = false;
      document.getElementById('endTime_' + index + '_' + itemIndex)?.classList.remove('error');
    }

    // 表示時間のフォーマットチェックに引っかからない場合
    if (!startTypeError && !endTypeError) {
      // 字幕表示時間の大小チェック
      if (timeToSec(saveStartTime) > timeToSec(saveEndTime)) {
        // 開始時間が終了時間より大きい場合、errorクラスを追加
        document.getElementById('startTime_' + index + '_' + itemIndex)?.classList.add('error');
        document.getElementById('endTime_' + index + '_' + itemIndex)?.classList.add('error');
        showMessage(t('editor.timeWarnMessage'), 'warning');
      } else {
        // errorクラスを削除
        document.getElementById('startTime_' + index + '_' + itemIndex)?.classList.remove('error');
        document.getElementById('endTime_' + index + '_' + itemIndex)?.classList.remove('error');
      }
    }

    if (checkByte(translateToText[itemIndex])) {
      // byte制限数を超えた場合
      document.getElementById('translateTo_' + index + '_' + itemIndex)?.classList.add('error');
    } else {
      // byte制限数を超えてない場合
      document.getElementById('translateTo_' + index + '_' + itemIndex)?.classList.remove('error');
    }

    const newTranslateToText = [...translateToText];
    newTranslateToText[itemIndex] = translateToText[itemIndex];
    setTranslateToText(newTranslateToText);

    judgeData();
  };

  // 翻訳ボタン処理
  const clickTranslateButton = async () => {
    setReadonlyClickTranslate(true);
    if (checkByte(translateFromText)) {
      // byte制限数を超えた場合
      document.getElementById('translateFrom_' + index)?.classList.add('error');
      setReadonlyClickTranslate(false);
      return;
    } else {
      // byte制限数を超えてない場合
      document.getElementById('translateFrom_' + index)?.classList.remove('error');
    }

    setDisableTranslate(true);
    // 翻訳API実行
    const translateResult = await translateData(index);
    if (translateResult && translateResult.translateTo) {
      // 翻訳結果がある場合、 翻訳結果を設定
      const saveStart: string[] = [];
      const saveEnd: string[] = [];
      const saveText: string[] = [];
      translateResult.translateTo.forEach((element) => {
        saveText.push(element);
      });
      // 字幕表示時間を取得
      translateResult.startTime.forEach((element) => {
        saveStart.push(formatSecDecimal(element));
      });
      translateResult.endTime.forEach((element) => {
        saveEnd.push(formatSecDecimal(element));
      });

      setTranslateToText(saveText);
      setStartText(saveStart);
      setEndText(saveEnd);
      setReadonlyClickTranslate(false);
    } else {
      // 翻訳結果がない場合、空行を設定
      setDisableTranslate(false);
      setReadonlyClickTranslate(false);
    }

    judgeData();
  };

  // byte数のチェック処理
  const checkByte = (text: string) => {
    let byte = 0;
    let saveText = '';
    for (const checkText of text) {
      checkText.match(/[ -~]/) ? (byte += 1) : (byte += 2);

      if (byte > limitByte) {
        // Byte制限を超えた場合、メッセージ表示
        showFlashMessage(t('editor.textLimitWarnMessage', { value: 300, byteValue: 600 }), 3000);
        return true;
      } else {
        saveText = saveText.concat(checkText);
      }
    }

    return false;
  };

  return {
    t,
    translateFromText,
    translateToText,
    startText,
    endText,
    disableTranslate,
    readonlyClickTranslate,
    translateData,
    onChange,
    clickTranslateButton,
    onBlur,
    onBlurTranslateFrom,
  };
};
