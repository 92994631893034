import Tooltip from '@mui/material/Tooltip';

// ツールチップProps
export type ToolTipProps = {
  open?: boolean;
  text: string;
  element?: any;
  placement?: 'bottom' | 'top' | 'left';
  children: any;
  defaultMode?: true | false;
};

export const ToolTip = ({
  open = false,
  text,
  element,
  placement = 'bottom',
  children,
  defaultMode = true,
}: ToolTipProps) => {
  // textが空白の場合
  if (!text) {
    return (
      <>
        <Tooltip open={open} arrow={true} title={element} placement={placement} onClose={() => true}>
          {children}
        </Tooltip>
      </>
    );
  }

  const breakedText = text.split('¥n').map((line, key) => (
    <span key={key}>
      {line}
      <br />
    </span>
  ));

  return defaultMode ? (
    <>
      <Tooltip title={breakedText} placement={placement}>
        {children}
      </Tooltip>
    </>
  ) : (
    <>
      <Tooltip open={open} arrow={true} title={breakedText} placement={placement} onClose={() => true}>
        {children}
      </Tooltip>
    </>
  );
};
