import { TextEllipsis, Image } from '@/components/Elements';
import { useAccountMenuDialog } from '@/components/Form';
import { LicensesDialog } from '@/components/Form/LicensesDialog';
import { FAQ_URL, FEEDBACK_URL } from '@/constants';
import accountCircle from '@/img/account_circle.svg';
import accountCircleAlt from '@/img/account_circle_alt.svg';
import feedback from '@/img/feedback.svg';
import help from '@/img/help_outline.svg';
import logoutIcon from '@/img/logout.svg';
import manageAccount from '@/img/manage_account.svg';
import { PRODUCT_CODE } from '@/utils/constants';
import { formatSec, formatDate } from '@/utils/formatUtil';

import './AccountMenuDialog.css';

// アカウントメニュー型定義
type AccountMenuDialogProps = {
  onClose: () => void;
  userMail: string;
  detailsRef?: React.MutableRefObject<HTMLDetailsElement | null>;
};

export const AccountMenuDialog = ({ onClose, userMail, detailsRef }: AccountMenuDialogProps): JSX.Element => {
  const {
    PYT_0130_URL,
    locale,
    t,
    remainValue,
    shortExpiration,
    centerId,
    onClickLogout,
    onDialogOpen,
    isOpen,
    onDialogClose,
    AGREEMENT_URL_BY_LANG,
    PRIVACY_POLICY_URL_BY_LANG,
  } = useAccountMenuDialog();

  return (
    <div className="account-menu-dialog">
      {
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
        <details ref={detailsRef}>
          <summary>
            <div className="account-menu-button">
              <Image src={accountCircleAlt as string} />
            </div>
          </summary>

          <div className="account-menu">
            <div className="account-menu-header">
              <Image src={accountCircle as string} />
              <div className="account-info-wrap">
                <div className="account-menu-id">{userMail && <TextEllipsis text={userMail} />}</div>
                <div className="account-menu-plan-label"></div>
                {/* プラン名表示を非表示 */}
                {/* <div className="account-menu-plan">
                  {t('common.planLabel')}
                  {planName}
                </div> */}
              </div>
            </div>
            <div className="account-menu-content">
              {remainValue ? (
                <div className="account-menu-ticket">
                  <div className="account-menu-remain">
                    <div className="account-menu-remain-label">{t('common.ticketRemain')}</div>
                    <div className="account-menu-remain-ticket">{formatSec(remainValue)}</div>
                  </div>
                  <div className="account-menu-expiration-ticket">
                    <div className="account-menu-expiration-ticket-label">
                      {t('common.untilValid', {
                        date: formatDate(new Date(shortExpiration.date)),
                        remain: formatSec(shortExpiration.remain),
                      })}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="account-menu-inner-wrap">
                <a
                  href={`${PYT_0130_URL}?productCode=${PRODUCT_CODE.POCKETALK_VIDEO_TRANSLATOR}&language=${locale}`}
                  target="_blank"
                  className="account-menu-item"
                  rel="noreferrer"
                  onClick={() => {
                    onClose();
                  }}
                >
                  <div className="mvs-menu-profile">
                    {/* ポケトークIDの管理 */}
                    <div className="account-menu-icon">
                      <Image src={manageAccount as string} />
                    </div>
                    <div className="account-menu-text">{t('common.manageYourPocketalkAccount')}</div>
                  </div>
                </a>

                <a
                  href={FAQ_URL}
                  target="_blank"
                  className="account-menu-item"
                  onClick={() => {
                    onClose();
                  }}
                  rel="noreferrer"
                >
                  <div className="mvs-menu-help">
                    {/* ヘルプ */}
                    <div className="account-menu-icon">
                      <Image src={help as string} />
                    </div>
                    <div className="account-menu-text">{t('common.help')}</div>
                  </div>
                </a>

                <a
                  href={`${FEEDBACK_URL}?entry.1141878578=${centerId}`}
                  target="_blank"
                  className="account-menu-item"
                  onClick={() => {
                    onClose();
                  }}
                  rel="noreferrer"
                >
                  <div className="mvs-menu-feedback">
                    {/* フィードバック */}
                    <div className="account-menu-icon">
                      <Image src={feedback as string} />
                    </div>
                    <div className="account-menu-text">{t('common.feedback')}</div>
                  </div>
                </a>

                <button onClick={() => onClickLogout(onClose)} className="account-menu-item mod-end">
                  <div className="mvs-menu-logout">
                    {/* ログアウト */}
                    <div className="account-menu-icon">
                      <Image src={logoutIcon as string} />
                    </div>
                    <div className="account-menu-text">{t('common.logout')}</div>
                  </div>
                </button>
              </div>
              <div className="menu-footer-wrap">
                <div className="l-horizontal">
                  <div className="menu-footerlink">
                    <a href={AGREEMENT_URL_BY_LANG} target="_blank" rel="noopener noreferrer">
                      {t('common.termsOfService')}
                    </a>
                  </div>
                  <div className="menu-footerlink">
                    <a href={PRIVACY_POLICY_URL_BY_LANG} target="_blank" rel="noopener noreferrer">
                      {t('common.privacyPolicy')}
                    </a>
                  </div>
                </div>
                <div className="menu-footerlink">
                  <button
                    type="button"
                    onClick={() => {
                      onDialogOpen();
                      onClose();
                    }}
                  >
                    {t('common.legalInformation')}
                  </button>
                  {isOpen && <LicensesDialog open={isOpen} onClose={onDialogClose} />}
                </div>
              </div>
            </div>
          </div>
        </details>
      }
    </div>
  );
};
