import { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { VIDEO_TRANSLATOR, COOKIE_KEY_NAME } from '@/constants';
import { SCREEN_HINT_SIGNUP, authorize } from '@/features/api';
import { setupCookie } from '@/utils/cookie';

/**
 * プロパティ
 */
export type Props = {
  // stateに指定するリダイレクト先の画面パス
  redirectPath?: string;
};

/**
 * stateが複数あった場合の接続用文字列
 */
const STATE_CONNECT_STR = '#' as string;

/**
 * ポケトーク認証ログイン(Auth) カスタムフック
 *
 * @param param0
 */
export const useLoginAuth = ({ redirectPath }: Props) => {
  /**
   * PTID認証API URL
   */
  const ptidApiUrl = useMemo(() => `${VIDEO_TRANSLATOR.DOMAIN}${VIDEO_TRANSLATOR.API_URL.PTID}`, []);

  /**
   * stateに指定する値を作成
   */
  const createState = useCallback((uuid: string, path?: string) => {
    let value = uuid;
    if (path) {
      // プロパティにリダイレクト先の画面パスが指定されている場合は「#」でつなぐ
      const pathUrl = `${process.env.REACT_APP_API_DOMAIN}${path}`;
      value = `${value}${STATE_CONNECT_STR}${pathUrl}`;
    }

    return value;
  }, []);

  /**
   * ログイン認証(Auth0)
   *
   * @param isSignUp サインアップ画面を表示するかどうか
   */
  const loginAuth = useCallback(
    (isSignUp?: boolean) => {
      // UUIDを生成
      const uuid = uuidv4();
      // CookieにUUIDを保存
      setupCookie(COOKIE_KEY_NAME.PTBR_STATE, uuid);
      // state
      const state = createState(uuid, redirectPath);

      // ログイン認証実行
      if (isSignUp) {
        // サインアップ
        authorize(
          {
            redirectUri: ptidApiUrl,
            state,
            screenHint: SCREEN_HINT_SIGNUP,
          },
          false
        );
      } else {
        authorize(
          {
            redirectUri: ptidApiUrl,
            state,
          },
          false
        );
      }
    },
    [createState, ptidApiUrl, redirectPath]
  );

  return {
    loginAuth,
  };
};
