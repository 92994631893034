/**
 * Cookieのキー名定義
 */
export const COOKIE_KEY_NAME = {
  // サーバで発行されたトークン
  PTBR_TOKEN: 'PTBR_TOKEN',
  // トークン種別
  PTBR_TYPE: 'PTBR_TYPE',
  // PTID認証時に指定するstateパラメータ(リダイレクト先のURLを除いた値を保持)
  PTBR_STATE: 'PTBR_STATE',
  // Firebase用トークン
  GCP_TOKEN: 'GCP_TOKEN',
} as const;
