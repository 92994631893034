import { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { Outlet, Navigate, BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import { Spinner } from '@/components/Elements/Spinner';
import { MainLayout } from '@/components/Layout';
import i18n from '@/config/i18n';
import { PAGE_ROUTE_PATH } from '@/constants';
import { useAuth } from '@/features/auth';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import store from '@/states/store';
import { lazyImport } from '@/utils/lazyImport';

const { Root } = lazyImport(() => import('@/features/root'), 'Root');
const { AuthError } = lazyImport(() => import('@/features/error'), 'AuthError');
const { Welcome } = lazyImport(() => import('@/features/welcome'), 'Welcome');
const { SignUp } = lazyImport(() => import('@/features/signup'), 'SignUp');
const { SelectFiles } = lazyImport(() => import('@/features/selectFiles'), 'SelectFiles');
const { Settings } = lazyImport(() => import('@/features/settings'), 'Settings');
const { Upload } = lazyImport(() => import('@/features/upload'), 'Upload');
const { NotFound } = lazyImport(() => import('@/features/error'), 'NotFound');
const { Editor } = lazyImport(() => import('@/features/editor'), 'Editor');
const { Translation } = lazyImport(() => import('@/features/translation'), 'Translation');

export type FileCountTypes = {
  count: number;
};

const App = () => {
  const location = useLocation();

  const { userId } = useAuth();

  const { language } = useBrowserUserSetting();
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  // 基本5画面でURL直打ちの場合、ルーティング制御
  if (
    location.pathname === PAGE_ROUTE_PATH.SELECTFILES ||
    location.pathname === PAGE_ROUTE_PATH.SETTINGS ||
    location.pathname === PAGE_ROUTE_PATH.UPLOAD ||
    location.pathname === PAGE_ROUTE_PATH.TRANSLATION ||
    location.pathname === PAGE_ROUTE_PATH.EDITOR
  ) {
    if ((location.state && !location.state.history) || !userId || (!location.state && !history.state.history)) {
      // リロードの場合は同画面に留まる
      location.pathname = '/';
    }
  }
  // ルーティング制御処理
  if (location.pathname === '/') {
    location.pathname = PAGE_ROUTE_PATH.WELCOME;
    return <Navigate to={`${PAGE_ROUTE_PATH.WELCOME}?lang=${language}`} replace />;
  }

  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="flex h-screen w-screen items-center justify-center">
            <Spinner size="lg" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const AppRoutes = () => (
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Navigate to={PAGE_ROUTE_PATH.WELCOME} replace />} />
          {/* Auth0認証画面 */}
          <Route path={PAGE_ROUTE_PATH.WELCOME} element={<Welcome />} />
          {/* ようこそ画面(サインアップ用) */}
          <Route path={PAGE_ROUTE_PATH.SIGNUP} element={<SignUp />} />
          {/* 利用規約画面 */}
          <Route path={PAGE_ROUTE_PATH.EULA} element={<Root />} />
          {/* 動画アップロード画面 */}
          <Route path={PAGE_ROUTE_PATH.SELECTFILES} element={<SelectFiles />} />
          {/* 翻訳設定画面 */}
          <Route path={PAGE_ROUTE_PATH.SETTINGS} element={<Settings />} />
          {/* アップロードファイル一覧画面 */}
          <Route path={PAGE_ROUTE_PATH.UPLOAD} element={<Upload />} />
          {/* 翻訳ファイル一覧画面 */}
          <Route path={PAGE_ROUTE_PATH.TRANSLATION} element={<Translation />} />
          {/* 字幕編集画面 */}
          <Route path={PAGE_ROUTE_PATH.EDITOR} element={<Editor />} />
          {/* 認証エラー画面 */}
          <Route path={PAGE_ROUTE_PATH.AUTH_ERROR} element={<AuthError />} />
          {/* 404エラー画面 */}
          <Route path={PAGE_ROUTE_PATH.NOTFOUND} element={<NotFound />} />
        </Route>
      </Routes>
    </Provider>
  </BrowserRouter>
);
