import * as React from 'react';

import { FlashMessageProvider } from '@/providers/flashMessage';
import { MessageProvider } from '@/providers/message';

import { Header } from '../Header';
type ContentLayoutProps = {
  children: React.ReactNode;
};

export const ContentLayout = ({ children }: ContentLayoutProps) => {
  return (
    <>
      <div data-testid="content-layout-children" className="app">
        <FlashMessageProvider>
          <MessageProvider />
          <Header />
          {children}
        </FlashMessageProvider>
      </div>
    </>
  );
};
