import { onSnapshot, Unsubscribe } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConvertLangCodeToLocale } from '@/config';
import { PAY, URL_PARAMS_VALUE, URL_PARAMS_KEY, AGREEMENT_URL, PRIVACY_POLICY_URL } from '@/constants';
import { useAuth } from '@/features/auth';
import { useLogout } from '@/features/logout';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import useOpen from '@/hooks/useOpen';
import { useUrlParameter } from '@/hooks/useUrlParameter';
import useUserPlanName from '@/hooks/useUserPlanName';
import { queryContracts, queryCharges, ChargeData } from '@/services';
import { ShortExpirationTicket } from '@/types/commonTypes';
import { removeBeforeunloadEvent } from '@/utils/beforeunload';
import { PLAN_CODE } from '@/utils/constants';

let contractsListen: Unsubscribe = () => {};

let chargeRemain: number;
let shortExpiration: ShortExpirationTicket;

// chargesコレクション監視用関数
let chargesListen: Unsubscribe = () => {};

/**
 * アカウントメニューダイアログ hooks
 *
 * @returns
 */
export const useAccountMenuDialog = () => {
  const { t } = useTranslation();
  const { doLogout } = useLogout({
    onSuccessFun: removeBeforeunloadEvent, // beforeunloadイベントによるwindows.alert削除
  });
  const { isOpen, onDialogOpen, onDialogClose } = useOpen();

  const { language } = useBrowserUserSetting();
  const locale = ConvertLangCodeToLocale(language);

  const { userId } = useAuth();
  const centerId = userId.substring(userId.lastIndexOf('|') + 1);

  // チケット残り時間
  const [remainValue, setRemainValue] = useState(0);

  // プラン取得方法を修正するまでコメントアウト
  const [contractPlan, setContractPlan] = useState<string>(PLAN_CODE.SHOT_PLAN);

  const { planName, planCode, subscriptionType, getPlanName } = useUserPlanName();

  const { findParams } = useUrlParameter();
  const lang = findParams(URL_PARAMS_KEY.lang);

  let AGREEMENT_URL_BY_LANG: string;
  let PRIVACY_POLICY_URL_BY_LANG: string;
  // 画面の表示言語が日本語以外の場合、利用規約、プライバシーポリシー設定
  if (lang != 'ja') {
    AGREEMENT_URL_BY_LANG = `${AGREEMENT_URL}/en-us` as string;
    PRIVACY_POLICY_URL_BY_LANG = `${PRIVACY_POLICY_URL}/en-us` as string;
  } else {
    AGREEMENT_URL_BY_LANG = `${AGREEMENT_URL}` as string;
    PRIVACY_POLICY_URL_BY_LANG = `${PRIVACY_POLICY_URL}` as string;
  }
  // 本日日付取得
  const workDate = new Date();
  const year = workDate.getFullYear();
  const month = workDate.getMonth();
  const day = workDate.getDate();
  const currentDate = new Date(year, month, day);

  contractsListen = onSnapshot(queryContracts(userId), async (plans) => {
    getPlanName(plans);
    setContractPlan(planCode);
  });

  const subscribeUploads = async () => {
    // Firestoreの値（各チケット残量の合計）をセットし監視開始
    chargesListen = onSnapshot(queryCharges(userId), async (charges) => {
      // 初期データ
      chargeRemain = 0;
      shortExpiration = { code: '', date: 0, remain: 0 };
      // chargesに変更があった場合、チケット残り時間を再取得
      if (charges.docChanges().length > 0) {
        await Promise.all(
          charges.docs.map(async (charge) => {
            const chargeData: ChargeData = charge.data();

            // チケットの開始日
            const startDate = chargeData.charge_appli_start.toDate();
            if (
              // 開始日 <= 現在日時 <= 終了日の場合
              currentDate.getTime() >=
                new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()).getTime() &&
              currentDate.getTime() <= new Date(chargeData.charge_appli_end.toDate()).getTime()
            ) {
              // チケット残量をセットする
              chargeRemain += chargeData.charge_remain;
              // 最短有効チケットを取得
              if (!shortExpiration.code) {
                shortExpiration = {
                  code: chargeData.charge_ticket_code,
                  date: new Date(chargeData.charge_appli_end.toDate()).getTime(),
                  remain: chargeData.charge_remain,
                };
              } else {
                if (shortExpiration.date > new Date(chargeData.charge_appli_end.toDate()).getTime()) {
                  shortExpiration = {
                    code: chargeData.charge_ticket_code,
                    date: new Date(chargeData.charge_appli_end.toDate()).getTime(),
                    remain: chargeData.charge_remain,
                  };
                }
              }
            }
          })
        );
        setRemainValue(chargeRemain);
      }
    });
  };
  /**
   * プラン詳細エンドポイント
   */
  const PLM_0100_URL = `${PAY.DOMAIN}${PAY.API_URL.PLM_0100}` as string;
  /**
   * ポケトークセンターエンドポイント
   */
  const PYT_0130_URL = `${PAY.DOMAIN}${PAY.API_URL.PYT_0130}` as string;

  /*
   * Function
   */

  useEffect(() => {
    subscribeUploads();
    return () => {
      contractsListen();

      chargesListen();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * [ログアウト]メニューをクリックした場合の処理
   */
  const onClickLogout = useCallback(
    (onClose: () => void) => {
      // メニュー閉じる
      onClose();

      // ログアウトAPI呼び出し
      doLogout(URL_PARAMS_VALUE.LOGOUT_MODE.NORMAL);
    },
    [doLogout]
  );

  return {
    PLM_0100_URL,
    PYT_0130_URL,
    locale,
    t,
    planName,
    planCode,
    subscriptionType,
    remainValue,
    shortExpiration,
    contractPlan,
    centerId,
    onClickLogout,
    onDialogOpen,
    isOpen,
    onDialogClose,
    AGREEMENT_URL_BY_LANG,
    PRIVACY_POLICY_URL_BY_LANG,
  };
};
