export const API_URL = process.env.REACT_APP_API_URL as string;
export const PASSWORD_REMINDER_URL = process.env.REACT_APP_PASSWORD_REMINDER_URL as string;
export const ACCOUNT_CREATE_URL = process.env.REACT_APP_ACCOUNT_CREATE_URL as string;

export const LOGIN_API_URL = '/clientuserlogin';
export const GET_SERIAL_API_URL = '/serial';
export const SIGNEDURL_UPLOAD_API_URL = '/private/signedurl/upload';
export const GENERATE_TRANSLATION_ZIP_API_URL = '/private/zipfile/create';
export const LANG_SETTING_API_URL = '/private/usedlang/setting';
export const CALL_WORKFLOWS_API_URL = '/private/encode';
export const TRNASLATE_API_URL = '/private/translate';

export { default as i18n } from './i18n';
export * from './i18n';
