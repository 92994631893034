// import { useTranslation } from 'react-i18next';

// TODO：Ver1.0の対象外（音声選択）箇所は一旦コメントアウト中

import './VoiceTypeSettingsRow.css';

export type VoiceTypeSettingsRowProps = {
  langCheck: boolean; //言語チェックボックス値制御
  langKey: string; // 言語ID
  langValue: string; //言語名
  // voiceType: VOICE_TYPES; //音声タイプ
  // defaultVoiceType: VOICE_TYPES; //音声タイプデフォルト値
  // enableFemale: DISABLED_TYPES; // ラジオボタン（女性）活性制御用 活性1/非活性0
  // enableMale: DISABLED_TYPES; //ラジオボタン（男性）活性制御用 活性1/非活性0
  onLangChecked: (e: React.ChangeEvent<HTMLInputElement>) => void; // チェックボックスチェック時の処理
  // onVoiceChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // ラジオボタン選択時の処理
};

export const VOICE_NAMES = {
  VOICE_FEMALE: 'female', // 女性
  VOICE_MALE: 'male', // 男性
  VOICE_NONE: '', // 吹き替えなし
};

export type VOICE_NAMES = (typeof VOICE_NAMES)[keyof typeof VOICE_NAMES];

// export const DISABLED_TYPES = {
//   DISABLED: '0', // 非活性
//   ENABLED: '1', // 活性
// };

// export type DISABLED_TYPES = (typeof DISABLED_TYPES)[keyof typeof DISABLED_TYPES];

export const VoiceTypeSettingsRow = ({
  langCheck,
  langKey,
  langValue,
  // voiceType,
  // defaultVoiceType,
  // enableFemale,
  // enableMale,
  onLangChecked,
}: // onVoiceChange,
VoiceTypeSettingsRowProps) => {
  // const { t } = useTranslation();
  //音声設定デフォルト値算出用関数
  // const defaultChecked = (gender: VOICE_TYPES) => {
  //   // 保存した音声タイプ、またはデフォルト値と一致する場合にチェックをつける
  //   if (voiceType === gender || (voiceType === VOICE_TYPES.VOICE_NONE && defaultVoiceType === gender)) {
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <div className="voiceTypeSettingsRow" data-testid="voiceType">
      {/* <div className="checkbox-style"> */}
      <label>
        <input type="checkbox" id={langKey} checked={langCheck} onChange={(e) => onLangChecked(e)} />
        <span className="checkbox-label">{langValue}</span>
      </label>
      {/* </div> */}
      {/* <div>
        <label key="female">
          &emsp;
          <input
            type="radio"
            value={VOICE_TYPES.VOICE_FEMALE}
            name={langName}
            defaultChecked={defaultChecked(VOICE_TYPES.VOICE_FEMALE)}
            disabled={!langCheck || enableFemale === DISABLED_TYPES.DISABLED}
            onChange={(e) => onVoiceChange(e)}
          />
          <span className="radio-label">{t('settings.female')}</span>
        </label>
        <label key="male">
          &emsp;
          <input
            type="radio"
            value={VOICE_TYPES.VOICE_MALE}
            name={langName}
            defaultChecked={defaultChecked(VOICE_TYPES.VOICE_MALE)}
            disabled={!langCheck || enableMale === DISABLED_TYPES.DISABLED}
            onChange={(e) => onVoiceChange(e)}
          />
          <span className="radio-label">{t('settings.male')}</span>
        </label>
      </div> */}
    </div>
  );
};
