import { TFunction } from 'i18next';
import { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { COMMON_DIALOG_TYPES } from '@/components/Form/';
import { PAGE_ROUTE_PATH, URL_PARAMS_KEY } from '@/constants';
import { langSettingApi } from '@/features/api';
import { useAuth } from '@/features/auth';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useUrlParameter } from '@/hooks/useUrlParameter';
import { updateUser, Users } from '@/services';

// Headerで使用するダイアログリスト
export const HEADER_DIALOG_TYPES = {
  DIALOG_ALERT_ERROR: '1', // 不明なエラーアラート
};
export type HEADER_DIALOG_TYPES = (typeof HEADER_DIALOG_TYPES)[keyof typeof HEADER_DIALOG_TYPES];

// 共通ダイアロググローバルprops
const globalCommonDialogProps = {
  title: '',
  message: '',
  buttonLabel: '',
  dialogType: COMMON_DIALOG_TYPES.DIALOG_ALERT,
  headerDialogType: HEADER_DIALOG_TYPES.DIALOG_ALERT_ERROR,
};

/**
 * ヘッダ hooks
 *
 * @returns
 */
export const useHeader = (t: TFunction) => {
  const { language, setLanguage } = useBrowserUserSetting();

  const { findParams } = useUrlParameter();
  const uiLang = findParams(URL_PARAMS_KEY.lang);

  useEffect(() => {
    setLanguage(uiLang);
  }, [setLanguage, uiLang]);

  const navigate = useNavigate();
  const { userId, userMail } = useAuth();
  const { i18n } = useTranslation();
  const { state, pathname } = useLocation();

  // 共通ダイアログ開閉制御state
  const [commonDialogOpen, setCommonDialogOpen] = useState(false);

  // 共通ダイアログオープン処理
  const openCommonDialog = (
    commonDialogTitle: string,
    commonDialogMessage: string,
    commonDialogButtonLabel: string,
    commonDialogType: COMMON_DIALOG_TYPES,
    headerDialogType: HEADER_DIALOG_TYPES
  ) => {
    // グローバル変数に各項目をセット
    globalCommonDialogProps.title = commonDialogTitle;

    globalCommonDialogProps.message = commonDialogMessage;

    globalCommonDialogProps.buttonLabel = commonDialogButtonLabel;

    globalCommonDialogProps.dialogType = commonDialogType;
    globalCommonDialogProps.headerDialogType = headerDialogType;
    // オープン
    setCommonDialogOpen(true);
    return true;
  };

  useEffect(() => {
    // htmlの言語設定
    const html = document.getElementById('html');
    if (html) {
      html.lang = language;
    }
    //表示言語に合わせて<head>内の<title>を変える
    document.title = t('common.titleLang');
  }, [i18n, language, t]);

  // logoクリック時
  const displayAccount = (): boolean => {
    // パスをチェック
    if (
      pathname === PAGE_ROUTE_PATH.SELECTFILES ||
      pathname === PAGE_ROUTE_PATH.SETTINGS ||
      pathname === PAGE_ROUTE_PATH.UPLOAD ||
      pathname === PAGE_ROUTE_PATH.TRANSLATION ||
      pathname === PAGE_ROUTE_PATH.EDITOR
    ) {
      return true;
    }

    return false;
  };

  // アカウントメニュー表示判定
  const clickLogo = () => {
    // rootページへ遷移
    navigate(`${PAGE_ROUTE_PATH.EULA}?lang=${language}`);
  };

  // 共通ダイアログクローズ時
  const handleCommonDialogClose = useCallback(
    (isOk?: boolean) => {
      // ダイアログを閉じる
      setCommonDialogOpen(false);
      // OK押下時の処理
      if (isOk) {
        switch (globalCommonDialogProps.headerDialogType) {
          // ファイル情報の取得失敗アラートの場合
          case HEADER_DIALOG_TYPES.DIALOG_ALERT_ERROR:
            break;
        }
      }
    },
    [setCommonDialogOpen]
  );

  const changeLanguage = useCallback(
    (selectedLanguage: string) => {
      // 言語変更時にstateがnullとなり遷移判定ができないため、state.historyも追加
      navigate(`${pathname}?lang=${selectedLanguage}`, {
        state: {
          history: state ? state.history : pathname,
        },
      });
      // ログインユーザーのFirestore上の表示言語設定を変更する
      (async () => {
        if (userId) {
          const fieldUsers: Users = {
            select_lang: selectedLanguage,
          };
          try {
            await updateUser(userId, fieldUsers);
          } catch (error) {
            // Firestoreエラーの場合、アラート表示
            openCommonDialog(
              '',
              t('common.alertImportantErrorMessage'),
              '',
              COMMON_DIALOG_TYPES.DIALOG_ALERT,
              HEADER_DIALOG_TYPES.DIALOG_ALERT_ERROR
            );
          }
          // 表示言語設定APIを実行
          const langSetting = await langSettingApi({
            user_id: userId,
            used_lang: selectedLanguage,
          });

          if (langSetting.resultCode !== 'OK') {
            // ステータスコードがOK以外の場合、アラート表示
            openCommonDialog(
              '',
              t('common.alertImportantErrorMessage'),
              '',
              COMMON_DIALOG_TYPES.DIALOG_ALERT,
              HEADER_DIALOG_TYPES.DIALOG_ALERT_ERROR
            );
          }
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n, pathname, navigate]
  );

  return {
    displayAccount,
    userMail,
    commonDialogOpen,
    language,
    changeLanguage,
    handleCommonDialogClose,
    globalCommonDialogProps,
    clickLogo,
  };
};
